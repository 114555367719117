/* ----------  Datatables  ---------- */

.dataTables_wrapper
{
	.dataTables_length
	{
		float: none;
		clear: both;
		text-align: center;
		margin-bottom: 10px;

		label
		{
			@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-02);

			select
			{
				border: 0;
				padding: 7px;
				cursor: pointer;
				vertical-align: middle;
				border-bottom: 1px solid $brand-gray-03;

				@include font-p($font-sm, $weight-regular, 1.5, $brand-gray-02);
			}
		}
	}

	.dataTables_info
	{
		float: none;
		clear: both;
		text-align: center;

		@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-02);
	}

	.dataTables_paginate
	{
		float: none;
		clear: both;
		padding-top: 10px;
		text-align: center;

		.paginate_button
		{
			border: 0;
			margin: 0 3px;
			min-width: auto;
			padding: 5px 7px;
			background: none;
			padding-bottom: 4px;

			@include transition;
			@include box-shadow(none);
			@include border-radius(4px);
			@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-02);

			&.disabled
			{
				border: 0;
				cursor: not-allowed;

				@include opacity(0.3);

				&:hover, &:active, &:focus
				{
					border: 0;
					cursor: not-allowed;
				}
			}

			&.current
			{
				border: 0;
				background: none;

				@include box-shadow(none);
				@include text-color($white-color);
				@include bg-color($brand-primary);

				&:hover, &:active, &:focus
				{
					border: 0;
					background: none;

					@include box-shadow(none);
					@include text-color($white-color);
					@include bg-color($brand-primary);
				}
			}

			&:last-child
			{
				margin: 0;
			}

			&:hover:not(.current), &:active:not(.current), &:focus:not(.current)
			{
				border: 0;
				background: none;

				@include box-shadow(none);
				@include text-color($color-01);
				@include bg-color($brand-gray-01);
			}
		}

		span
		{
			&.ellipsis
			{
				padding: 0 5px;
				color: $brand-gray-03;
			}
		}
	}
}