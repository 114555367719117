/* ----------  Global Search  ---------- */

.global-search-container
{
	@include position(relative, 222);

	.search-inner
	{
		@include position;
	}

	.search-form
	{
		@include position;
		@include clearfix;

		.form-control
		{
			width: 40px;
			float: right;
			height: 40px;
			padding: 0 20px;
			background-color: transparent;
			border: 1px solid $brand-gray-03;

			@include box-shadow(none);
			@include border-radius(25px);
			@include transform(translateY(-50%));
			@include placeholder($brand-gray-03, 0);
			@include position(absolute, 11, $top: 50%, $right: -1px);
			@include font-p($font-sm, $weight-regular, 1.5, $color-01);

			&:focus
			{
				border-color: $brand-primary;
			}

			@media (max-width: $screen-sm-max)
			{
				width: 150px;

				@include reset-position;
				@include placeholder($brand-gray-03, 1);
			}
		}

		.btn-submit
		{
			display: block;
			border: 0;
			padding: 0;
			width: 40px;
			height: 40px;
			background: none;

			@include box-shadow(none);
			@include border-radius(99px);
			@include position(relative, 5);
			@include font($font-md, $weight-regular, 1, $color-01);

			.btn-icon
			{
				display: block;
				width: 30px;
				height: 30px;
				background: $brand-gray-01;
				
				@include absolute-center;
				@include border-radius(99px);
				@include transition(background 0.3s ease-in-out);

				@media (max-width: $screen-sm-max)
				{
					width: 100%;
					height: 100%;
				}
			}

			i
			{
				@include absolute-center;
				@include font(inherit, inherit, inherit, inherit);
			}

			@media (max-width: $screen-sm-max)
			{
				width: 32px;
				height: 32px;

				@include transform(translateY(-50%));
				@include position(absolute, 22, $top: 50%, $right: 5px);
			}
		}
	}

	.search-filter
	{
		display: none;
		width: 500px;
		overflow: hidden;
		margin-top: 10px;
		background: $white-color;

		@include md-default-shadow;
		@include border-radius(10px);
		@include position(absolute, 99, $top: 100%, $right: 0);

		.filter-row
		{
			padding: 10px 20px;

			label
			{
				@include font-p($font-sm, $weight-regular, 1.2, $color-01);
			}

			@media (max-width: $screen-xs-max)
			{
				padding: 10px 15px;
			}
		}

		.toggle-row
		{
			padding-top: 15px;
			padding-bottom: 15px;
			background: $brand-gray-01;

			@include inline-flex;

			label
			{
				font-size: $font-base;
			}

			.icheck-inline
			{
				display: block;
				margin: 0;
				padding: 0;
				flex: 1;

				label
				{
					margin: 0;
				}

				@media (max-width: $screen-xs-max)
				{
					margin-bottom: 10px;
				}
			}

			@media (max-width: $screen-xs-max)
			{
				padding-bottom: 5px;
			}
		}

		.lang-row, .category-row
		{
			/* label
			{
				display: block;
				margin: 0;
				margin-top: 15px;
			
				@media (max-width: $screen-xs-max)
				{
					margin: 0;
					margin-bottom: 10px;
				}
			}
			
			.selectize-control
			{
				.selectize-input
				{
					height: 100%;
					min-height: -10px;
			
					.item
					{
						margin: 0;
						border: 0;
						padding: 0;
					}
				}
			} */
		}

		.filter-row
		{
			label
			{
				margin: 0;
				margin-bottom: 10px;
			}
		}

		.filter-atn
		{
			text-align: center;

			a
			{
				display: block;
				padding: 12px;
				overflow: hidden;

				@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-02);
			}
		}

		@media (max-width: $screen-sm-max)
		{
			width: 100%;
			min-width: 290px;
		}
	}

	@media (max-width: $screen-sm-max)
	{
		width: calc(100% - 20px);
	}
}

.search-backdrop
{
	width: 100%;
	height: 100%;

	@include position(fixed, -1, 0, 0);
}

.search-open
{
	.site-wrapper, #header_main, #sidebar_main, #page_content, 
	{
		@include pointer-events(none);
	}

	.global-search-container
	{
		@include pointer-events(all);

		.search-form
		{
			.form-control
			{
				z-index: 5;
				width: 500px;
				background: $brand-gray-06;

				// @include border-radius(99px);
				@include placeholder($brand-gray-03, 1);

				@media (max-width: $screen-sm-max)
				{
					width: 100%;
				}
			}

			.btn-submit
			{
				z-index: 11;
				color: $brand-primary;
			}
		}

		&.valid
		{
			.search-form
			{
				.btn-submit
				{
					color: $white-color;

					.btn-icon
					{
						background: $brand-primary;
					}
				}
			}
		}
	}
}