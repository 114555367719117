/* ----------  Text Truncate  ---------- */

@mixin text-truncate($value: 100%, $flex: 0)
{
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	@if $flex > 0 {
		flex: $flex;
	} @else {
		width: $value;
	}
}
