/* ----------  Pagination  ---------- */

.pagination-container
{
	margin-bottom: 20px;

	li, a
	{
		@include font-p($font-base, $weight-regular, 1.2, $brand-gray-02);

		.fa
		{
			&:first-child
			{
				margin-right: 5px;
			}

			&:last-child
			{
				margin-left: 5px;
			}
		}
	}

	ul
	{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		li
		{
			margin-right: 15px;

			a
			{
				color: $brand-primary;
			}

			&:last-child
			{
				margin: 0;
			}
		}
	}
}

/*----------  Bootstrap Pagination  ----------*/

.pagination.bs-pagination
{
	@include inline-flex(center, center);

	li
	{
		display: block;
		margin: 0 3px;

		a
		{
			display: block;
			margin: 0;
			padding: 0;
			width: 30px;
			height: 30px;

			@include transition(all 0.1s linear);
			@include inline-flex(center, center);
			@include font-p($font-sm, $weight-regular, 1, $brand-gray-09);

			i
			{
				@include font-inherit;
			}
		}
	}

	.previous, .next
	{
		a
		{
			@include flex(column, center, center);
		}

		&.disabled
		{
			cursor: not-allowed;

			a
			{
				@include pointer-events(none);
				@include text-color($brand-gray-04);
			}
		}
	}

	.page-item
	{
		> a
		{
			&:hover, &:active, &:focus
			{
				background: none;
			}
		}

		&.active
		{
			> a
			{
				color: $white-color;
				background: $brand-primary;
			}
		}

		&:hover:not(.active), &:active:not(.active), &:focus:not(.active)
		{
			> a
			{
				background: $brand-gray-10;
			}
		}
	}
}