/* ----------  MD Graphic Panel Card  ---------- */

.md-graphic-panel-card
{
	height: calc(100vh - 48px);

	.md-card-content
	{
		height: 100%;

		@include position;
		// @include transition;

		[data-player]
		{
			display: none;
		}

		.leg-player-container
		{
			height: 100%;
			background: $white-color;
			
			@include flex;
			@include position(relative, 5);

			&.leg-video-player
			{
				width: 100%;

				@include position(absolute, 11, 0, 0);

				&.footer-player
				{
					z-index: 2;
				}
			}
		}

		.mCSB_container
		{
			@include flex;
			@include position;

			@media (max-width: $screen-sm-max-alt)
			{
				// display: block;
			}
		}

		.btn-close
		{
			padding: 5px 8px;
			padding-left: 6px;
			background: $white-color;

			@include inline-flex;
			@include border-radius(5px);
			@include position(absolute, 99, $top: 5px, $right: 5px);
			@include font($font-xs, $weight-regular, 1.1, $color-01);

			i
			{
				@include font($font-base, inherit, inherit, inherit);
			}
		}

		.google-streetview-container
		{
			flex: 1;
			margin: 0;
			width: 100%;
			background: $brand-gray-10;

			.streetview-inner
			{
				height: 100%;

				@include flex(column, normal, center);

				.streetview, .streetview-canvas
				{
					height: 100%;
					
					@media (max-width: $screen-sm-max-alt)
					{
						height: auto;
						min-height: 400px;
					}
				}
			}
		}

		.leg-content-container
		{
			width: 100%;
		}

		.panel-actions
		{
			display: none;
			width: 100%;
			padding: 0 5px;

			@include opacity(0);
			@include transition;
			@include faded-background($black-color, 0.5);
			@include position(absolute, 111, $bottom: 0, $left: 0);
			
			.actions-inner
			{
				@include inline-flex
				{
					-webkit-justify-content: space-between;
					-moz-justify-content: space-between;
					-ms-justify-content: space-between;
					-o-justify-content: space-between;
					justify-content: space-between;
				}
			}

			ul
			{
				list-style-type: none;

				@include inline-flex;

				li
				{
					a
					{
						display: block;
						padding: 10px 5px;

						@include position;
						@include font(rem-calc(25), $weight-regular, 1, $white-color);

						.btn-icon
						{
							width: auto;
							height: auto;

							@include font-inherit;

							&:not(:first-child)
							{
								@include absolute-center;
							}

							&.icon-primary
							{
								@include opacity(1);
							}

							&.icon-secondary
							{
								@include opacity(0);
							}
						}
					}
				}
			}

			@media (max-width: $screen-sm-max-alt)
			{
				@include opacity(1);
				@include reset-position;
			}
		}

		@media (max-width: $screen-sm-max-alt)
		{
			display: block;
			height: auto;
			overflow-y: auto;
			max-height: 100%;
			overflow-x: hidden;
			background: $white-color;

			@include md-default-shadow;
			@include pointer-events(none);
		}
	}

	&.invalid
	{
		.md-card-content
		{
			@include opacity(0);
			@include pointer-events(none);

			@media (max-width: $screen-sm-max-alt)
			{
				@include opacity(1);
			}
		}
	}

	// &.disabled
	// {
	// 	.md-card-content
	// 	{
	// 		.google-streetview-container
	// 		{
	// 			// @include opacity(0.7);
	// 			// @include pointer-events(none);
	// 		}
	// 	}
	// }

	&.panel-shrinked
	{
		.md-card-content
		{
			.btn-close
			{
				display: none;
			}

			.leg-content-container
			{
				width: 100%;
				background: $white-color;

				@include opacity(0);
				@include transition;
				@include pointer-events(none);
				@include position(absolute, 99, $bottom: 0, $left: 0);
			}

			.panel-actions
			{
				display: block;
			}
		}

		&.panel-content-active
		{
			.md-card-content
			{
				.leg-content-container
				{
					bottom: 45px;

					@include opacity(1);
					@include pointer-events(auto);
				}

				.panel-actions
				{
					@include opacity(1);

					ul
					{
						li
						{
							a
							{
								&.btn-toggle
								{
									.btn-icon
									{
										&.icon-primary
										{
											@include opacity(0);
										}

										&.icon-secondary
										{
											@include opacity(1);
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&:hover, &:active, &:focus
		{
			.md-card-content
			{
				.panel-actions
				{
					@include opacity(1);
				}
			}
		}
	}

	&:before
	{
		content: '';
		display: none;
		background: $black-color;

		@include opacity(0.3);
		@include position(absolute, 1, 0, 0, 0, 0);
	}

	@media (max-width: $screen-sm-max-alt)
	{
		height: auto;
		padding: 16px;
		padding-top: 72px;
		background: transparent;

		@include opacity(0);
		@include box-shadow(none);
		@include pointer-events(none);
		@include position(fixed, 111, 0, 0, 0, 0);

		*
		{
			@include pointer-events(none !important);
		}

		&:before
		{
			display: block;
		}
	}
}

body.tour-enabled
{
	.md-graphic-panel-card
	{
		.md-card-content
		{
			@media (max-width: $screen-sm-max-alt)
			{
				@include pointer-events(auto);
			}
		}

		@media (max-width: $screen-sm-max-alt)
		{
			@include opacity(1);
			@include pointer-events(auto);

			*
			{
				@include pointer-events(auto !important);
			}
		}
	}

	@media (max-width: $screen-sm-max-alt)
	{
		overflow: hidden !important;
	}
}

/* ----------  Tour Banner Section  ---------- */

.tour-banner-container
{
	display: none;
	overflow: hidden;

	@include position;

	.banner-image
	{
		img
		{
			display: block;
			width: 100%;
		}
	}

	.banner-content
	{
		width: 100%;

		@include opacity(0);
		@include transition;
		@include transform(translateY(100%));
		@include faded-background($black-color, 0.7);
		@include position(absolute, 5, $bottom: 0, $left: 0);

		.content-inner
		{
			padding: 12px 20px;
		}

		h3
		{
			@include font-p($font-lg, $weight-bold, 1.3, $white-color);
		}

		p
		{
			@include font-p($font-sm, $weight-regular, 1.4, $white-color);
		}

		.banner-title
		{
			margin-bottom: 5px;
		}
	}

	&:hover, &:active, &:focus
	{
		.banner-content
		{
			@include opacity(1);
			@include transform(translateY(0));
		}
	}
}

/* ----------  Leg Content Container  ---------- */

.leg-content-container
{
	.leg-content-inner
	{
		@include inline-flex(normal);

		@media (max-width: $screen-md-max)
		{
			display: block;
		}

		@media (max-width: $screen-sm-max)
		{
			display: flex;
		}

		@media (max-width: $screen-xxs-max)
		{
			display: block;
		}
	}

	.leg-actions-details
	{
		@include flex {
			flex-wrap: initial;
		};
	}

	.leg-actions-details, .leg-map
	{
		flex: 0 0 50%;
		max-width: 50%;

		@media (max-width: $screen-md-max)
		{
			max-width: 100%;
		}

		@media (max-width: $screen-sm-max)
		{
			max-width: 50%;
		}

		@media (max-width: $screen-xxs-max)
		{
			max-width: 100%;
		}
	}

	.leg-actions
	{
		flex: 1;

		@include position;
		@include flex(column, normal, center);
	}

	.leg-controls
	{
		min-height: 80px;
		padding: 15px 10px;

		@include flex(column, normal, center);

		ul
		{
			list-style-type: none;
			
			@include inline-flex(center, center)
			{
				flex-wrap: nowrap;
			}

			li
			{
				margin: 0 4px;
				overflow: hidden;

				@include position;
				@include border-radius(99px);

				a
				{
					display: block;
					width: 50px;
					height: 50px;
					overflow: hidden;
					// border: 3px solid $brand-gray-03;

					@include position;
					@include border-radius(99px);
					@include font(rem-calc(30), $weight-regular, 1, $brand-primary);

					i
					{
						color: inherit;
						font-size: inherit;

						@include absolute-center;
					}

					&.atn-icon-sm
					{
						font-size: $font-lg;
					}

					&.atn-sm
					{
						width: 40px;
						height: 40px;
						font-size: rem-calc(22);
					}

					@media (max-width: $screen-xl-max)
					{
						width: 28px;
						height: 28px;
					}
				}

				&.atn-record
				{
					> a
					{
						@include text-color($white-color);
						@include bg-color($brand-primary);
					}

					&.recording
					{
						> a
						{
							@include bg-color($brand-danger);
						}
					}
				}

				&.active
				{
					> .btn-play
					{
						background: $brand-danger;
						border-color: $brand-danger;
					}
				}

				&.paused
				{
					> .btn-play
					{
						color: $white-color;
						background: $brand-warning;
						border-color: $brand-warning;
					}
				}

				&.valid
				{
					> a
					{
						color: $brand-primary;
						border-color: $brand-primary;
					}
				}

				&.disabled
				{
					cursor: not-allowed;

					> a
					{
						@include opacity(0.5);
						@include pointer-events(none);
					}
				}

				&.active
				{
					> a
					{
						background: $brand-primary;
						border-color: $brand-primary;

						@include text-color($white-color);
					}
				}

				&.inactive
				{
					> a
					{
						@include opacity(0.8);
						@include filter(grayscale(100%));
					}
				}

				&:hover:not(.active):not(.paused), &:active:not(.active):not(.paused), &:focus:not(.active):not(.paused)
				{
					> a
					{
						background: $brand-gray-01;
						/* border-width: 2px;
						color: $brand-gray-02;
						border-color: $brand-gray-02; */
					}
				}

				@media (max-width: $screen-xl-max)
				{
					padding: 0 5px;
				}
			}
		}

		&.mixer-controls
		{
			ul
			{
				li
				{
					a
					{
						width: 35px;
						height: 35px;
						font-size: rem-calc(22);

						&.atn-sm
						{
							width: 28px;
							height: 28px;
							font-size: $font-md;
						}
					}
				}
			}
		}
	}

	.leg-details
	{
		flex: 1;
		min-height: 100px;

		li, span, a
		{
			@include font-p($font-base, $weight-regular, 1.4, $color-01);
		}

		.list-hierarchy
		{
			li
			{
				&:after
				{
					content: '\f105';
					margin: 0 7px;

					@include font-fa(inherit, $weight-bold, inherit, inherit);
				}

				&:last-child
				{
					&:after
					{
						display: none;
					}
				}
			}
		}

		> ul
		{
			display: block;
			margin: 0;
			padding: 15px;
			list-style-type: none;

			> li
			{
				@include inline-flex
				{
					flex-wrap: initial;
				}

				span
				{
					display: block;
				}

				a
				{
					@include text-color($brand-primary, true);
				}

				.item-title
				{
					padding-right: 7px;
					font-weight: $weight-bold;
				}

				.item-text
				{
					@include text-truncate(false, 1);
				}

				&:last-child
				{
					margin: 0;
				}
			}
		}
	}

	.leg-map
	{
		height: 250px;
		background: $brand-gray-01;

		@include flex(column, normal, center);
		
		.map-canvas
		{
			height: 100%;
		}
	}
}

/* ----------  Mini Player  ---------- */

.md-graphic-panel-card.md-mini-player
{
	.btn-close
	{
		span
		{
			display: none;
		}
	}

	.leg-content-container
	{
		width: 100%;

		@include opacity(0);
		@include transition;
		@include pointer-events(none);
		@include position(absolute, 11, $bottom: 0, $left: 0);

		.leg-actions-details
		{
			flex: 1;
			width: 100%;
			max-width: none;
		}

		.leg-controls
		{
			min-height: auto;

			ul
			{
				li
				{
					> a
					{
						width: 35px;
						height: 35px;
						font-size: $font-base;

						@include pointer-events(all);
					}

					&:hover:not(.active):not(.paused), &:active:not(.active):not(.paused), &:focus:not(.active):not(.paused)
					{
						> a
						{
							background: none;
						}
					}
				}
			}
		}
	}

	&:hover, &:active, &:focus
	{
		.leg-content-container
		{
			@include opacity(1);
		}
	}
}

/* ----------  JS Tree Renamers  ---------- */

.jstree-renamers-container
{
	@include position(fixed, 999, 0, 0);

	.jstree-renamers-inner
	{
		@include inline-flex;
	}

	.form-control
	{
		height: 30px;
		padding: 0 10px;
	}
}
