/* ----------  Font Variables  ---------- */

$font-primary				: 'Roboto', sans-serif;
$font-secondary				: 'Montserrat', serif;
// $sub-font-01				: sans-serif;
// $sub-font-02				: sans-serif;

/* ----------  Icon Fonts  ---------- */
$font-fontawesome			: 'FontAwesome';

/* ----------  Color Variables  ---------- */

/*-----  Default Variables  -----*/

$white-color				: #fff !default;
$black-color				: #000 !default;

/*-----  Unsorted Variables  -----*/

$color-01					: #212121;
// $color-02					: #;

/*-----  Brands Variables  -----*/

$brand-info					: #03a9f4;
$brand-warning				: #ff9800;
$brand-danger				: #ff5722;
$brand-dark					: #444444;
$brand-light				: #fafafa;

/* Brand Primary */
// $brand-primary-01			: #;
// $brand-primary-02			: #;

/* Brand Info */
// $brand-info-01				: #;
// $brand-info-02				: #;

/* Brand Warning */
$brand-warning-01			: #fdd835;
$brand-warning-02			: #c6842b;

/* Brand Success */
$brand-success-01			: #00665b;
// $brand-success-02			: #;

/* Brand Danger */
// $brand-danger-01				: #;
// $brand-danger-02				: #;

/* Brand Gray */
$brand-gray-01				: #f2f2f2;
$brand-gray-02				: #aaa;
$brand-gray-03				: darken($brand-gray-01, 15%);
$brand-gray-04				: #cacec1;
$brand-gray-05				: #ebede3;
$brand-gray-06				: #fafafa;
$brand-gray-07				: #999;
$brand-gray-08				: #adbcc3;
$brand-gray-09				: #516671;
$brand-gray-10				: #ebeef1;
$brand-gray-11				: #e9e9e9;
$brand-gray-12				: #4b636f;
$brand-gray-13				: #6f6f6f;
$brand-gray-14				: #ccc;
$brand-gray-15				: #727272;

/* Brand Socials */
$brand-facebook				: #3b5998;
$brand-twitter				: #1da1f2;
$brand-google-plus			: #dd4b39;
$brand-linkedin				: #0077b5;
$brand-pinterest			: #bd081c;

/* ----------  Font Weight Variables  ---------- */

$weight-hairline 			: 100;
$weight-light	 			: 300;
$weight-regular 			: 400;
$weight-medium				: 500;
$weight-semibold 			: 600;
$weight-bold	 			: 700;
$weight-extrabold	 		: 900;

/* ----------  Font Size Variables  ---------- */

$font-base					: rem-calc(16);					// 16px
$font-xs					: $font-base - rem-calc(4);		// 12px
$font-sm					: $font-base - rem-calc(2);		// 14px
$font-md					: $font-base + rem-calc(2);		// 18px
$font-lg					: $font-base + rem-calc(4);		// 20px
$font-xl					: $font-base + rem-calc(24);	// 40px
$font-xxl					: $font-base + rem-calc(44);	// 60px

/* ----------  Breakpoints  ---------- */

$screen-xl-max				: 1399px;
$screen-md-max				: 1199px;
$screen-sm-max				: 991px;
$screen-sm-max-alt			: 959px;
$screen-xs-max				: 767px;
$screen-xxs-max				: 540px;
