/* ----------  Display Classes  ---------- */

.d-block
{
	display: block !important;
}

.d-inline-block
{
	display: inline-block !important;
}

.d-none, .hide
{
	display: none !important;
}