/* ----------  UK Dropdown  ---------- */

.uk-dropdown
{
	.uk-nav
	{
		padding: 7px 0;

		li
		{
			a
			{
				padding: 10px 15px;
			}

			&.uk-nav-divider
			{
				border-top: 1px solid rgba($black-color, 0.12);
			}
		}
	}

	&.uk-dropdown-minimal
	{
		padding: 0;

		@include box-shadow(none);

		.md-card
		{
			min-height: 110px;

			@include flex;
			@include position;

			.md-card-toolbar
			{}

			.md-card-content
			{
				flex: 1;
				padding-left: 0;
				padding-right: 0;
				overflow: hidden;
				max-height: 300px;
			}

			.md-list
			{
				li
				{
					.md-list-addon-avatar
					{
						max-height: 100%;
					}

					.md-atn-remove
					{
						left: auto;
						right: -15px;
						height: 100%;
						background: $white-color;

						@include opacity(0);
						@include transition;
					}

					&:hover, &:active, &:focus
					{
						.md-atn-remove
						{
							@include opacity(1);
						}
					}
				}
			}

			&.md-card-conversation-messages
			{
				height: 400px;
				overflow: hidden;

				.md-card-content
				{
					max-height: 100%;
					overflow: hidden;
					padding-bottom: 0;
				}
			}
		}
	}

	&.uk-dropdown-checklist
	{
		padding: 12px 0;

		ul
		{
			@include reset-list;

			li
			{
				label
				{
					display: block;
					cursor: pointer;
					padding: 10px 15px;

					@include transition;
					@include font-p($font-sm, $weight-regular, 1.2, $color-01);

					span
					{
						@include font-p(inherit, inherit, inherit, inherit);
					}
				}

				&:hover, &:active, &:focus
				{
					> label
					{
						background: $brand-gray-11;
					}
				}
			}
		}
	}
}

/*----------  Header Dropdown  ----------*/

#header_main
{
	.uk-navbar
	{
		.uk-navbar-nav
		{
			>li
			{
				.uk-dropdown
				{
					margin-top: 0;
				}
			}
		}
	}
}