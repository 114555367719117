/* ----------  Style Switcher  ---------- */

#style_switcher
{
	width: 280px;
	margin-right: -286px;

	&.switcher_active
	{
		margin-right: 0;
	}

	&.disabled, &[disabled]
	{
		user-select: none;

		@include pointer-events(none);

		#style_switcher_toggle
		{
			cursor: not-allowed;

			@include pointer-events(auto);

			.material-icons
			{
				color: $brand-gray-02;
			}
		}
	}
}