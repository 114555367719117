/* ----------  Images  ---------- */

.img-responsive
{
	display: inline-block;
	max-width: 100%;
	height: auto;
	width: auto;
}

.img-fullwidth
{
	display: block;
	width: 100%;
}