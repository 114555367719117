/* ----------  Tour Slick  ---------- */

.tour-slick
{
	margin: 0 -10px;
	transition-delay: 0.3s;

	@include position;
	@include transition;

	.slick-item
	{
		padding: 10px;

		.item
		{
			margin: 0;
		}
	}

	.slick-dots
	{ 
		text-align: left;
		padding: 5px 10px;
		
		li
		{
			display: inline-block;

			@include transition;
			@include opacity(0.3); 
			
			span
			{
				display: inline-block;
				width: 5px;
				height: 5px;
				margin: 3px 5px; 
				cursor: pointer;
				background-color: $black-color;

				@include border-radius(50%);
			}
			
			&.slick-active
			{ 
				@include opacity(1); 
			}
		}
	}

	&:not(.slick-visible)
	{
		overflow: hidden;
		max-height: 200px;
		transition-delay: 0.3s;

		@include opacity(0);
	}
}

/* ----------  Tour Slick Section  ---------- */

.tour-slick-container
{
	@include position;
}