/* ----------  Animation  ---------- */

@mixin animation($delay, $duration, $name, $iteration: infinite)
{
	-webkit-animation-delay: $delay;
	-o-animation-delay: $delay;
	animation-delay: $delay;

	-webkit-animation-duration: $duration;
	-o-animation-duration: $duration;
	animation-duration: $duration;

	-webkit-animation-name: $name;
	-o-animation-name: $name;
	animation-name: $name;

	-webkit-animation-iteration-count: $iteration;
	-o-animation-iteration-count: $iteration;
	animation-iteration-count: $iteration;

	-webkit-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-o-animation-timing-function: linear;
	animation-timing-function: linear;
}
