/* ----------  Section Preloader  ---------- */

.section-preloader
{
	width: 100%;
	height: 100%;
	
	@include position(absolute, 99, 0, 0);

	.preloader
	{
		@include absolute-center;
	}

	.fa
	{
		@include font-fa(rem-calc(30), $weight-regular, 1, $brand-gray-03);
	}
}