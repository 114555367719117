/* ----------  Sidebars  ---------- */

#sidebar_main, #sidebar_secondary
{
	z-index: 999;
}

/* ----------  Secondary Sidebar  ---------- */

#sidebar_secondary
{
	@include max-width(400px);
	@include transform(translate3d(408px, 0, 0));
}