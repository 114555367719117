/* ----------  Ion Range Slider  ---------- */

.irs.irs-input--
{
	height: 40px;

	.irs
	{
		width: 100%;
		height: 100%;
	}

	.irs-handle, .irs-slider
	{
		display: block;
		width: 12px;
		height: 12px;
		cursor: pointer;
		background: #009688;

		@include border-radius(50%);
		@include position(absolute, 1, 28px);
	}

	&.irs-sm
	{
		height: 100%;
		margin: auto;
		width: calc(100% - 15px);

		.irs
		{
			.irs-line
			{
				top: 18px;
				height: 1px;
				background: #777;
			}

			.irs-from, .irs-to, .irs-single
			{
				line-height: 1.1;
				font-size: rem-calc(8px);
			}
		}

		.irs-grid
		{
			display: none !important;
		}
		
		.irs-min, .irs-max
		{
			line-height: 1.1;
			font-size: rem-calc(8px);
		}

		.irs-bar
		{
			top: 18px;
			height: 1px;
		}

		.irs-slider, .irs-handle
		{
			top: 16px;
			width: 5px;
			height: 5px;
		}
	}
}

.ion-slider.disabled
{
	cursor: not-allowed;

	@include pointer-events(none);
	@include filter(grayscale(100%));

	*
	{
		cursor: not-allowed;

		@include pointer-events(none);
		@include filter(grayscale(100%));
	}
}
