.ripple, [data-ripple]
{
	overflow:hidden;
	
	@include position;
	@include transform(translate3d(0, 0, 0));
}

.ripple-effect
{
	// margin: 0;
	// padding: 0;
	// user-select: none;
	// background-color: rgba($white-color, 0.5);

	// @include opacity(0.6);
	// @include transition;
	@include border-radius(50%);
	@include position(absolute);
	// @include pointer-events(none);
	@include transform(translate3d(-50% ,-50% , 0));
}

.waves-effect
{
	> .waves-ripple
	{
		background: #e0e0e0;
	}
}