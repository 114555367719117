/*----------  Variables  ----------*/

$default:				#009688;

/*----------  Toggles  ----------*/

.toggle
{
	cursor: pointer;
	
	@include position;

	.toggle-input
	{
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;

		@include opacity(0);
		@include position(absolute, 99, 0, 0);
	}

	.toggle-icon
	{
		width: 18px;
		height: 18px;
		border: 2px solid rgba($black-color, 0.5);

		@include position;
		@include border-radius(2px);
		@include transition(all 0.2s ease-out);

		&:after
		{
			@include absolute-center;
			@include pointer-events(none);
		}
	}

	.toggle-input:checked + .toggle-icon
	{
		border-color: $default;
	}

	&.toggle-checkbox
	{
		.toggle-icon
		{
			&:after
			{
				display: none;
				content: '\e5ca';
				font-family: 'Material Icons';

				@include font($font-md, $weight-regular, 1, $white-color);
			}
		}

		.toggle-input:checked + .toggle-icon
		{
			background: $default;

			&:after
			{
				display: block;
			}
		}
	}

	&.toggle-radio
	{
		.toggle-icon
		{
			@include border-radius(99px);

			&:after
			{
				content: '';
				width: 10px;
				height: 10px;
				background: $default;

				@include opacity(0);
				@include absolute-center;
				@include border-radius(99px);
				@include pointer-events(none);
				@include transition(all 0.2s ease-out);
				@include transform(translate(-50%, -50%) scale(0.1));
			}
		}

		.toggle-input:checked + .toggle-icon
		{
			&:after
			{
				@include opacity(1);
				@include transform(translate(-50%, -50%) scale(1));
			}
		}
	}
}

/*----------  iCheck Toggle  ----------*/

.icheck-toggle
{
	display: block;
	margin: 0;
	padding: 0;
	cursor: pointer;
	min-height: 20px;
	padding-left: 25px;

	@include position;
	@include flex(column, center, center);

	.icheck-input
	{
		@include position(absolute, 5, 0, 0);
	}

	.toggle-text
	{
		display: block;
		padding-top: 2px;

		@include text-truncate;
	}
}