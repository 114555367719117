/* ----------  Forms  ---------- */

.global-form
{
	.form-label
	{
		display: block;
		padding-bottom: 4px;
		font-size: $font-sm;
		bottom: -7px;

		@include position;
		@include opacity(1);
	}

	.form-control
	{
		background-color: $white-color;
	}

	.form-rate-group
	{
		padding-bottom: 10px;
		border-bottom: 2px solid rgba($black-color, 0.08);
	}
}

/* ----------  Material Forms  ---------- */

.md-input-wrapper
{
	@include position;

	.md-input
	{
		height: auto !important;

		&:hover, &:active, &:focus
		{
			@include box-shadow(none);
		}
	}

	label
	{
		font-weight: $weight-regular;
	}

	textarea.md-input
	{
		height: 100px;
		max-height: 100px;
		overflow: auto !important;
	}

	.btn-clear
	{
		@include link;
		@include border-radius(99px);
		@include transform(translateY(-50%) rotate(-45deg));
		@include position(absolute, 3, $top: 50%, $right: 0);
		@include font(rem-calc(28), $weight-regular, 1.2, $brand-warning);

		i
		{
			@include font(inherit, inherit, inherit, inherit);
		}

		&.stay-invisible
		{
			@include opacity(0);
			@include pointer-events(none);
		}
	}

	&.has-autocomplete
	{
		z-index: 99;
	}
}

/* ----------  Kendo Resets  ---------- */

.k-widget.k-autocomplete
{
	display: block;
	width: 100%;
	border: 0 !important;
	background: none !important;

	@include box-shadow(none !important);

	.k-input
	{
		height: auto;
		background: none;
		padding: 12px 4px;

		@include placeholder(inherit, 0);
		@include box-shadow(inset 0 -1px 0 transparent);
		@include font-p(rem-calc(15), $weight-regular, 1.2, inherit);
	}
}

/*----------  Form Controls  ----------*/

.form-control
{
	@include box-shadow(none);

	&:hover, &:active, &:focus
	{
		@include box-shadow(none);
	}
}

/*----------  Select  ----------*/

select, select.form-control
{
	-webkit-appearance: unset;
	-moz-appearance: unset;
	appearance: unset;
}

/*----------  Form Check  ----------*/

.form-check
{
	.form-check-label
	{
		font-size: $font-xs;

		.icheck-input
		{
			margin-right: 5px;
		}

		.form-check-text
		{
			margin-top: 2px;
		}
	}
}

/*----------  Upload  ----------*/

.form-group-upload
{
	@include position;

	.btn-upload
	{
		@include position;
	}

	.input-upload
	{
		width: 100%;
		height: 100%;

		@include opacity(0);
		// @include pointer-events(none);
		@include position(absolute, 99, 0, 0);
	}
}

/* ----------  Stripe Form  ---------- */

.stripe-form
{
	.md-form-group
	{
		margin-bottom: 25px;
	}

	input, .form-control, .StripeElement
	{
		display: block;
		width: 100%;
		background: none;
		padding: 12px 4px;
		border-bottom: 2px solid rgba($black-color, 0.12);

		@include placeholder($brand-gray-15);
		@include box-shadow(inset 0 -1px 0 transparent);
		@include font-p(rem-calc(15), $weight-regular, 1.5, $black-color);

		&:hover, &:active, &:focus
		{
			border: 0;
			border-bottom: 2px solid rgba($black-color, 0.12);

			@include box-shadow(none);
		}

		&:focus
		{
			border-bottom: 2px solid rgba($black-color, 0.2);
		}
	}
}
