/* ----------  Cart  ---------- */

.md-shopping-cart
{
	padding: 0;
	height: auto;
	min-height: 10px !important;
	max-height: none !important;

	.md-card-content
	{
		padding: 0;
		
		.md-card-content-inner
		{
			height: 400px;

			@include position;
		}
	}

	.md-cart-header
	{
		height: 80px;
		padding: 10px;
		background: $brand-gray-08;

		@include position;
		@include flex(row, center, center);

		h3
		{
			letter-spacing: 3px;
			text-transform: uppercase;

			@include font-p($font-lg, $weight-bold, 1.1, $brand-gray-09);
		}

		.header-title
		{
			flex: 1;
			padding: 0 20px;
			text-align: center;
		}

		.header-atn
		{
			a
			{
				padding: 5px;

				@include font($font-lg, $weight-regular, 1, $brand-gray-09);

				i
				{
					@include font-inherit;
				}
			}
		}

		.header-action
		{
			@include position(absolute, 5, $bottom: 5px, $right: 5px);

			ul
			{
				@include reset-list;
				@include inline-flex;

				li
				{
					a
					{
						display: block;
						width: 30px;
						height: 30px;
						// background: $brand-gray-01;

						@include position;
						@include border-radius(99px);
						@include font($font-md, $weight-regular, 1, $brand-gray-09);

						i
						{
							@include absolute-center;
							@include font(inherit, inherit, inherit, inherit);
						}

						&:hover, &:active, &:focus
						{
							color: $brand-danger;
						}
					}
				}
			}
		}
	}

	.md-cart-footer
	{
		@include inline-flex;

		.btn-cart
		{
			display: block;
			flex: 1;
			height: 50px;
			letter-spacing: 1px;
			text-transform: uppercase;
			background: $brand-gray-08;

			@include flex(column, center, center);
			@include font-p($font-base, $weight-regular, 1.1, $brand-gray-09);

			&.disabled
			{
				background: $brand-gray-13;

				@include pointer-events(none);

				*
				{
					@include pointer-events(none);
				}
			}

			&:hover, &:active, &:focus
			{
				background: lighten($brand-gray-08, 10%);
			}
		}

		.btn-close
		{
			background: lighten($brand-gray-08, 10%);

			&:hover, &:active, &:focus
			{
				background: lighten($brand-gray-08, 5%);
			}
		}
	}

	.md-cart-area
	{
		height: 100%;
		padding-top: 90px;
		padding-bottom: 60px;
		background: $brand-gray-01;

		@include position(relative, 5);

		.md-cart-header
		{
			width: 100%;

			@include position(absolute, 11, 0, 0);
		}

		.md-cart-content
		{
			height: 100%;
			max-height: 100%;

			@include position;

			.state-content
			{
				width: 100%;
				padding: 20px;
				text-align: center;

				@include absolute-center;

				.state-icon
				{
					margin-bottom: 10px;

					i
					{
						@include font(rem-calc(80), $weight-regular, 1, $color-01);
					}
				}

				.state-message
				{
					p, a
					{
						@include font-p($font-sm, $weight-regular, 1.3, $brand-gray-09);

						span
						{
							display: block;
						}
					}

					a
					{
						color: lighten($brand-warning, 15%);
					}
				}
			}

			&.content-state-success, &.content-state-error, &.content-state-empty
			{
				display: none;
			}
		}

		.md-cart-footer
		{
			width: 100%;

			@include position(absolute, 11, $bottom: 0, $right: 0);
		}

		.area-mask
		{
			width: 100%;
			height: 100%;

			@include opacity(0);
			@include transition;
			@include pointer-events(none);
			@include position(absolute, 99, 0, 0);
			@include faded-background($white-color, 0.4);
		}

		.area-loader
		{
			display: none;
			width: 100%;
			height: 100%;
			background: $white-color;

			@include opacity(0.7);
			@include position(absolute, 99, 0, 0);

			.loader-icon
			{
				@include absolute-center;
				@include font-fa($font-xl, $weight-regular, 1, $brand-gray-07);
			}
		}
	}

	.md-cart-invoice
	{
		.md-cart-content
		{
			padding-bottom: 30px;

			@include position;

			.invoice-list
			{
				height: 100%;
				max-height: 100%;
				overflow: hidden;

				.invoice-list-items
				{
					@include reset-list;

					> li
					{
						padding: 7px 15px;

						@include position;
						@include inline-flex;
						@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-08);

						span, a
						{
							display: block;
						}

						.item-title
						{
							flex: 1;
							padding-right: 7px;
						}

						.item-rate
						{
							flex: 0 0 60px;
							text-align: right;
							color: $brand-gray-09;
						}

						.item-actions
						{
							width: 100%;
							height: 100%;
							background: $white-color;

							@include opacity(0);
							@include transition;
							@include position(absolute, 11, 0, 0);

							ul
							{
								height: 100%;

								@include reset-list;
								@include inline-flex;
								@include max-width(200px);

								li
								{
									flex: 1;
									margin: 0;
									height: 100%;
									padding: 0 10px;
									
									@include flex(column, center, center);

									a
									{
										display: block;
										flex: 1;
										width: 100%;
										text-align: center;

										@include flex(column, center, center);
										@include font-p($font-lg, $weight-regular, 1.2, $brand-gray-09);

										img
										{
											display: block;
											max-height: 20px;
										}

										i
										{
											display: block;

											@include font(inherit, inherit, inherit, inherit);
										}

										&.atn-delete
										{
											color: $brand-danger;
										}

										&:hover, &:active, &:focus
										{
											@include filter(drop-shadow(0 2px 5px rgba($black-color, 0.4)));
										}
									}
								}
							}
						}

						&:last-child
						{
							margin: 0;
						}

						&:hover, &:active, &:focus
						{
							> .item-actions
							{
								@include opacity(1);
							}
						}
					}
				}
			}

			.invoice-total
			{
				width: 100%;
				padding: 0 15px;

				@include inline-flex;
				@include position(absolute, 5, $bottom: 0, $left: 0);

				span
				{
					display: block;

					@include font-p($font-md, $weight-regular, 1.2, $brand-gray-08);
				}

				.total-text
				{
					flex: 1;
				}

				.total-bill
				{
					flex: 0 0 80px;
					text-align: right;
					color: $brand-gray-09;
					font-weight: $weight-bold;
				}
			}
		}
	}

	.md-cart-checkout
	{
		display: none;
		width: 100%;
		background: $white-color;

		@include md-default-shadow;
		@include position(absolute, 1, 0, 0);

		.md-cart-header
		{
			background: $brand-gray-10;
		}

		.md-cart-content
		{
			padding: 0 15px;

			.md-form-group
			{
				margin: 0;
				margin-bottom: 20px;

				.title-text, .title-icon
				{
					display: block;
				}

				.title-text
				{
					@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-08);
				}

				.title-icon
				{
					cursor: pointer;

					@include transition;
					@include font-p($font-md, $weight-regular, 1, $brand-gray-08);

					i
					{
						@include font(inherit, inherit, inherit, inherit);
					}

					&:hover, &:active, &:focus
					{
						color: darken($brand-gray-08, 5%);
					}
				}

				.group-title
				{
					@include inline-flex;

					.title-text
					{
						margin-right: 20px;

						&:last-child
						{
							margin: 0;
						}
					}
				}

				.group-field
				{
					@include inline-flex;

					.group-field-addon
					{
						margin: 0;
						padding-left: 10px;

						img
						{
							display: block;
							max-width: 100%;
							max-height: 20px;
						}
					}

					.md-input-wrapper
					{
						flex: 1;
						padding: 0;
					}

					.selectize-control
					{
						flex: 1;

						.selectize-dropdown
						{
							.selectize-dropdown-content
							{
								.md-selectize-item
								{
									@include inline-flex;

									.item-image
									{
										margin-right: 5px;

										img
										{
											max-height: 20px;
										}
									}

									.item-text
									{
										@include opacity(0);
										@include pointer-events(none);
										@include position(absolute, -1, 0, 0);
									}

									&.active
									{
										background: $brand-gray-01;
									}
								}
							}
						}
					}

					.k-widget
					{
						display: block;

						span
						{
							display: none !important;
						}
					}

					.field-value
					{
						display: block;
						margin-top: 5px;

						@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-09);
					}
				}

				&:last-child
				{
					margin: 0;
				}
			}
		}

		.md-cart-footer
		{
			.btn-order
			{
				color: $brand-gray-08;
				background: $brand-gray-09;
			}
		}
	}

	&.checkout-active
	{
		.md-cart-invoice
		{
			@include pointer-events(none);

			.area-mask
			{
				@include opacity(1);
				@include pointer-events(auto);
			}
		}

		.md-cart-checkout
		{
			@include pointer-events(auto);

			.area-mask
			{
				@include opacity(0);
				@include pointer-events(none);
			}
		}
	}

	&.invoice-active
	{
		.md-cart-invoice
		{
			@include pointer-events(auto);

			.area-mask
			{
				@include opacity(0);
				@include pointer-events(none);
			}
		}

		.md-cart-checkout
		{
			@include pointer-events(none);

			.area-mask
			{
				@include opacity(1);
				@include pointer-events(auto);
			}
		}
	}
}