/* ----------  Sidebar Results  ---------- */

.hero-section
{
	.results-wrapper
	{
		.sidebar-actions-container
		{
			display: none;
			background: $white-color;

			@include transition;

			@media (max-width: $screen-sm-max)
			{
				display: block;
			}
		}

		.results
		{
			.section-btn-close
			{
				display: none;
				text-align: center;

				a
				{
					display: block;
					padding: 5px 10px;
					text-align: center;
					background: $brand-primary;

					@include font-p($font-xl, $weight-regular, 1.1, $white-color);

					&:hover, &:active, &:focus
					{
						background: darken($brand-primary, 5%);
					}
				}

				@media (max-width: $screen-sm-max)
				{
					display: block;
				}
			}

			.result-item
			{
				.controls-more
				{
					@include opacity(0);
					@include transition;
				}

				> a
				{
					.result-item-detail
					{
						padding: 10px 0;

						@include inline-flex(stretch)
						{
							flex-wrap: nowrap;
						}

						.image
						{
							float: none;
							clear: both;
						}

						.result-media
						{
							width: 135px;
							height: 110px;
							overflow: hidden;
							text-align: center;

							@include position;

							@media (max-width: $screen-md-max)
							{
								width: 100%;
								margin-bottom: 15px;
							}
						}

						.result-image
						{
							width: 100%;
							height: 100%;

							@include transition;
							@include background-defaults;
							@include position(absolute, 3, 0, 0);
							@include box-shadow(0 1px 2px rgba($black-color, 0.2));

							&:before
							{
								content: '';
								height: 100%;
								width: 100%;

								background-color: rgba($black-color, 0.2);
								background: -moz-linear-gradient(top, transparent 0%, $black-color 100%);
								background: -webkit-linear-gradient(top, transparent 0%, $black-color 100%);
								background: linear-gradient(to bottom, transparent 0%, $black-color 100%);
								filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000',GradientType=0 );

								@include opacity(0.7);
								@include position(absolute, 1, $bottom: 0, $left: 0);
							}
						}

						.result-additional-info
						{
							width: 100%;
							padding: 5px;

							@include position(absolute, 11, $bottom: 5px, $left: 0);

							p
							{
								@include opacity(1);
								@include font-p($font-xs, $weight-bold, 1.3, $white-color);
							}
						}

						.result-content
						{
							flex: 1;
							width: 100%;
							padding-top: 3px;
							padding-left: 10px;

							@include flex;

							p
							{
								margin: 0;
								font-size: 12px;
								line-height: 1.4;
								max-height: 32px;
								overflow: hidden;
							}

							.result-title
							{
								display: block;
								width: 100%;

								@media (max-width: $screen-md-max)
								{
									display: none;
								}
							}

							@media (max-width: $screen-md-max)
							{
								padding: 0;
							}
						}

						.result-title
						{
							display: none;
							width: 100%;
							margin-bottom: 5px;

							h3
							{
								margin: 0;
							}

							@media (max-width: $screen-md-max)
							{
								display: block;
							}
						}

						.result-description
						{
							flex: 1;
							width: 100%;
							margin-bottom: 7px;
						}

						.result-meta
						{
							width: 100%;

							@include inline-flex(center, space-between);
						}

						.result-label
						{
							margin-right: 10px;

							.label
							{
								display: block;
								padding: 4px 7px;
								padding-bottom: 5px;
							}
						}

						.result-ratings
						{
							
						}

						&:hover, &:active, &:focus
						{
							.result-image
							{
								@include transform(scale(1.1, 1.1));
							}
						}

						@media (max-width: $screen-md-max)
						{
							flex-direction: column;
						}

						@media (max-width: $screen-sm-max)
						{
							display: block;
						}
					}
				}

				&:hover, &:active, &:focus
				{
					.controls-more
					{
						@include opacity(1);
					}
				}
			}

			@media (max-width: $screen-sm-max)
			{
				padding-bottom: 70px;
			}
		}

		.related-results
		{
			width: 100%;
			height: 100%;
			background: $white-color;
			transform-style: preserve-3d;

			@include transform(translateX(100%));
			@include position(absolute, 22, 0, 0);
			@include transition(all 0.7s ease, false);

			.related-tours-content
			{
				height: 100%;
				padding-top: 65px;

				@include position;
			}

			.section-btn-close
			{
				a
				{
					display: block;
					padding: 5px 10px;
					text-align: center;
					background: $brand-gray-02;

					@include font-p($font-xl, $weight-regular, 1.1, $white-color);

					&:hover, &:active, &:focus
					{
						background: darken($brand-gray-02, 5%);
					}
				}
			}

			.section-title
			{
				width: 100%;
				padding: 10px 20px;
				
				@include inline-flex;
				@include position(absolute, 99, 0, 0);

				h2
				{
					display: block;
					flex: 1;
					margin: 0;
				}

				.btn-close
				{
					display: block;

					@include font-p($font-xl, $weight-regular, 1.1, $brand-gray-09);
				}
			}

			.related-results-content
			{
				.content-block
				{
					.block-title
					{
						padding: 10px;
						text-align: center;
						background: $brand-primary;

						h3
						{
							margin: 0;

							@include font-p($font-md, $weight-bold, 1.2, $white-color);
						}
					}

					.block-carousel
					{
						padding: 10px 35px;
						background: $brand-gray-01;

						@include position;

						.block-slick
						{
							@include position;

							.rel-item
							{
								padding: 15px 5px;

								.item-inner
								{
									background: $white-color;

									@include position;
									@include transition;
								}
								
								a
								{
									display: block;
								}

								h4, a
								{
									margin: 0;
									font-size: $font-sm;
								}

								.item-media
								{
									overflow: hidden;
								}

								.item-image
								{
									width: 100%;
									height: 100px;

									@include transition;
									@include background-defaults;
								}

								.item-special-label
								{
									padding: 3px 5px;
									background: $white-color;

									@include border-radius(0 0 4px 0);
									@include position(absolute, 5, 0, 0);
									@include font-p($font-xs, $weight-regular, 1.2, $brand-primary);
								}

								.item-content
								{
									padding: 10px;
								}

								.item-title
								{
									margin-bottom: 3px;
								}

								.item-label
								{
									margin-bottom: 0;
								}

								.item-marker
								{
									span
									{
										display: block;
										cursor: pointer;
									}
								}

								.item-meta
								{
									@include inline-flex;

									.item-ratings
									{
										flex: 1;
									}
								}

								.item-footer
								{
									padding: 10px;
									padding-top: 0;
								}

								&:hover, &:active, &:focus
								{
									.item-inner
									{
										@include md-default-shadow;
									}

									.item-image
									{
										@include transform(scale(1.1, 1.1));
									}
								}
							}
						}

						.block-controls
						{
							.slick-arrow
							{
								width: 35px;
								height: 100%;

								@include link;
								@include opacity(0);
								@include position(absolute, 11, 0);
								@include font-fa(rem-calc(24), $weight-regular, 1, $color-01);

								i, .fa, .fas
								{
									@include absolute-center;
								}

								&.slick-prev
								{
									left: 0;
								}

								&.slick-next
								{
									right: 0;
								}

								&:hover, &:active, &:focus
								{
									color: $white-color;
									background: $brand-gray-02;
								}

								&.slick-disabled
								{
									@include pointer-events(none);
									@include text-color($brand-gray-04);
								}
							}
						}

						&:hover, &:active, &:focus
						{
							.block-controls
							{
								.slick-arrow
								{
									@include opacity(1);
								}
							}
						}
					}
				}
			}
		}

		.related-result-detail
		{
			z-index: 33;

			@include transform(translateX(100%));

			.actions-container
			{
				.atn-btn
				{
					@media (min-width: 992px)
					{
						&:hover, &:active, &:focus
						{
							.atn-inner
							{
								width: 50%;
							}
						}
					}
				}
			}
		}

		.controls-more
		{
			ul
			{
				li
				{
					&.disabled
					{
						a
						{
							color: $brand-gray-14;

							@include pointer-events(none);
						}
					}
				}
			}
		}

		&.show-detail
		{
			.results
			{
				@include transform(translateX(-100%));
			}
		}

		&.show-related
		{
			.main-sidebar-detail
			{
				// @include transform(translateX(-100%));
			}

			.related-results
			{
				@include transform(translateX(0));
			}
		}

		&.show-related-detail
		{
			.related-results
			{
				@include transform(translateX(-100%));
			}

			.related-result-detail
			{
				@include transform(translateX(0));
			}
		}

		&.show-detail, &.show-related, &.show-related-detail
		{
			.sidebar-actions-container
			{
				@include opacity(0);
				@include pointer-events(none);
			}
		}

		@media (max-width: $screen-sm-max)
		{
			margin: 0 !important;
			height: 100vh !important;
		}

		@each $class, $brand in $categories
		{
			.result-item.item-category-#{$class}
			{
				> a
				{
					&:before
					{
						@include bg-color($brand);
					}
				}
			}
		}
	}
}

/* ----------  Result Footer  ---------- */

.results-footer
{
	padding: 15px 0;

	.results-length
	{
		margin-bottom: 10px;

		@include inline-flex(center, center);

		label
		{
			margin-right: 10px;
		}

		select
		{
			width: 70px;
			padding: 8px 10px;
			background-color: $brand-gray-01;
		}
	}

	.results-pagination
	{
		.pagination
		{
			margin: 0;
		}
	}
}
