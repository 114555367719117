/* ----------  Market Item  ---------- */

.market-item
{
	margin: 0;

	@include position;
	@include box-shadow(none);

	.item-inner
	{
		padding: 2px;
		background: $white-color;

		@include position;
	}

	h5
	{
		@include font-p($font-sm, $weight-bold, 1.3, $color-01);
	}

	p
	{
		@include font-p($font-sm, $weight-regular, 1.2, darken($brand-gray-08, 20%));
	}

	.item-loader
	{
		width: 100%;
		height: 100%;
		background: $white-color;

		@include transition;
		@include position(absolute, 99, 0, 0);

		.loader-icon
		{
			@include absolute-center;
			@include font-fa($font-lg, $weight-regular, 1, $brand-gray-02);
		}
	}

	.item-content
	{
		padding: 10px;
		padding-bottom: 0;
	}

	.item-footer
	{
		padding: 7px 10px;

		@include transition;
		@include inline-flex;
	}

	.item-title
	{
		margin-bottom: 10px;
		padding-right: 10px;
	}

	.item-options
	{
		@include opacity(0);
		@include transition;
		@include position(absolute, 5, $top: 5px, $right: 8px);

		a
		{
			@include font($font-md, $weight-regular, 1, $brand-gray-09);

			i
			{
				@include font-inherit;
			}
		}
	}

	.item-meta
	{
		margin-bottom: 3px;

		@include clearfix;

		.item-language
		{
			float: left;
		}

		.item-time
		{
			float: right;
		}
	}

	.item-description
	{
		// max-height: 0;

		// @include opacity(0);
		// @include transition;
	}

	.item-price
	{
		margin-right: 10px;

		p
		{
			font-size: $font-base;
			color: $brand-gray-09;
		}
	}

	.item-actions
	{
		@include opacity(0);
		@include transition;

		ul
		{
			@include reset-list;
			@include inline-flex;

			li
			{
				margin-right: 5px;

				a
				{
					display: block;

					@include opacity(0.6);
					@include font-p($font-md, $weight-regular, 1.2, $color-01);

					.atn-icon
					{
						display: block;

						@include font-inherit;
					}

					img
					{
						max-height: 20px;
					}

					&.atn-has-states
					{
						.atn-icon
						{
							display: none;
						}

						.atn-icon.icon-static
						{
							display: block;
						}
					}
				}

				&:last-child
				{
					margin: 0;
				}

				&.active
				{
					a
					{
						&.atn-has-states
						{
							.atn-icon
							{
								display: none;
							}

							.atn-icon.icon-active
							{
								display: block;
							}
						}

						&.atn-favourite
						{
							color: $brand-danger;
						}
					}
				}

				&.active, &:hover
				{
					> a
					{
						@include opacity(1);
					}
				}
			}
		}
	}

	&:before
	{
		content: '';
		width: 3px;
		height: 100%;
		background: $brand-primary;

		@include opacity(0);
		@include transition;
		@include position(absolute, 5, 0, 0);
	}

	@each $class, $brand in $categories
	{
		&.item-category-#{$class}
		{
			&:before
			{
				@include bg-color($brand);
			}
		}
	}

	&.item-loaded
	{
		.item-loader
		{
			z-index: -1;

			@include opacity(0);
			@include pointer-events(none);
		}
	}

	&.hover-state, &.active
	{
		&:before
		{
			@include opacity(1);
		}
	}

	&.active
	{
		.item-description
		{
			// padding: 5px 0;
			// max-height: 200px;

			// @include opacity(1);
		}
	}

	&:hover
	{
		.item-options, .item-actions
		{
			@include opacity(1);
		}

		.item-footer
		{
			background: darken($white-color, 3%);
		}
	}
}