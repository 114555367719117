/* ----------  JS Tree  ---------- */

.md-tree-container
{
	min-height: 150px;

	@include position;

	.md-tree
	{
		min-height: 150px;

		.jstree-children
		{
			padding: 0;
			// padding-left: 25px;

			.jstree-children
			{
				padding: 0;
			}
		}

		&.md-tree-loading
		{
			@include opacity(0.7);
			@include pointer-events(none !important);
		}
	}

	&.jstree-ready
	{
		.md-tree
		{
			min-height: none;

			@include opacity(1);
			@include transition(none);
			@include pointer-events(auto);
		}
	}
}

.md-tree.jstree
{
	height: 100%;
	overflow: hidden;

	@include transition;

	.jstree-children
	{
		display: block;
		width: 100%;
		height: 100%;
		min-width: 10px;

		&:empty
		{
			display: none;
		}

		.jstree-children
		{
			.jstree-node
			{
				padding-left: 20px;
			}
		}

		.jstree-node
		{
			margin: 0;
			width: 100%;
			background: none;

			@include position;

			.jstree-icon
			{
				display: none;
			}

			.jstree-wholerow
			{
				display: none;
				width: 100%;
				height: 100%;

				@include transition;
				@include position(absolute, -1, 0, 0);

				&.jstree-wholerow-hovered
				{
					background: $brand-gray-01;
				}
			}

			.jstree-anchor
			{
				display: block;
				height: auto;
				padding: 5px 0;
				background: none;
				padding-left: 60px;
				padding-right: 45px;
				margin-bottom: 3px;
				white-space: normal;

				@include position;
				@include transition;
				@include inline-flex
				{
					flex-wrap: nowrap;
				}

				&.jstree-search
				{
					font-style: normal;
					font-weight: $weight-regular;

					.node-icon, .node-title
					{
						color: $brand-warning;
					}
				}

				&:before
				{
					content: '';
					width: 3px;
					height: 100%;
					background: $brand-success;

					@include opacity(0);
					@include pointer-events(none);
					@include position(absolute, 5, 0, 0);
				}

				&.jstree-clicked, &.jstree-hovered
				{
					@include box-shadow(none);
				}

				&.jstree-hovered
				{
					background: $brand-gray-01;

					@include box-shadow(none);

					> .node-actions
					{
						@include opacity(1);
						@include pointer-events(auto);
					}
				}
			}

			.node-expander
			{
				// display: none !important;
				height: 100%;
				cursor: pointer;
				padding-left: 5px;
				padding-right: 5px;
				// background: $brand-primary;

				@include pointer-events(auto);
				@include position(absolute, 5, 0, 5px);
				@include flex(column, center, center);
				@include font-fa($font-base, $weight-regular, 1, $brand-gray-02);

				span
				{
					margin: 0;

					@include transform(rotate(0));
				}
			}

			.node-notif
			{
				display: none;

				@include position(absolute, 2, 2px, 5px);
				@include font(rem-calc(5), $weight-regular, 1, $brand-warning);
			}

			.node-meta
			{
				display: none;
				height: 0;
				width: 0;

				@include position(absolute, 0, 0, 0);
			}

			.node-avatar
			{
				width: 22px;
				height: 22px;
				overflow: hidden;

				@include border-radius(99px);

				img
				{
					display: block;
				}
			}

			.node-title
			{
				display: block;

				@include text-truncate;
				@include font-p($font-base, $weight-regular, 1.2, $color-01);

				@media (max-width: $screen-xs-max)
				{
					padding-left: 0;
					padding-right: 30px;
					font-size: $font-base;
				}
			}

			.node-loader
			{
				display: block;
				width: 35px;
				height: 100%;

				// @include transition;
				@include opacity(0);
				@include pointer-events(none);
				@include position(absolute, 5, 0, 20px);
				@include font-fa($font-md, $weight-regular, 1, $brand-gray-02);

				.loader-inner
				{
					@include absolute-center;
				}
			}

			.node-icon
			{
				display: block;
				width: 35px;
				height: 100%;
				color: lighten($black-color, 40%);

				@include position(absolute, 5, 0, 20px);

				i, span
				{
					display: block;
					color: inherit;
					font-size: rem-calc(22);
					
					@include transition;
					@include absolute-center;

					@media (max-width: $screen-xs-max)
					{
						font-size: rem-calc(20);
					}
				}

				&.node-icon-img
				{
					img
					{
						max-width: 17px;

						@include absolute-center;
					}
				}

				&.has-states
				{
					.icon-static
					{
						@include opacity(1);
					}

					.icon-active
					{
						@include opacity(0);
					}
				}

				@media (max-width: $screen-xs-max)
				{
					width: 40px;
				}
			}

			.node-icon
			{
				&.atn-publish
				{
					@include link;

					&.published
					{
						color: $brand-success !important;

						.icon-static
						{
							@include opacity(0);
						}

						.icon-active
						{
							@include opacity(1);
						}
					}

					&:hover:not(.published), &:active:not(.published), &:focus:not(.published)
					{
						color: lighten($black-color, 25%);
					}
				}
			}

			.node-actions
			{
				display: flex;
				flex-direction: row;
				align-items: center;

				@include opacity(0);
				// @include transition;
				@include pointer-events(none);
				@include transform(translateY(-50%));
				@include position(absolute, 5, $top: 50%, $right: 0);

				.atn
				{
					display: block;
					height: 100%;

					@include position;
					@include border-radius(99px);
					
					i, span
					{
						display: block;
						line-height: 1;
						font-size: rem-calc(22);

						@media (max-width: $screen-xs-max)
						{
							font-size: rem-calc(20);
						}
					}

					&:last-child
					{
						margin: 0;
					}
				}
			}

			&:before
			{
				content: '';
				width: 3px;
				height: 80%;
				background: $brand-success;

				@include opacity(0);
				@include transition;
				@include absolute-center(y);
			}

			&.jstree-item-processing
			{
				@include opacity(0.6);
				@include pointer-events(none);
			}

			&[data-node-type="leg"]
			{
				&.jstree-recording
				{
					> .jstree-anchor
					{
						@include opacity(0.4);
						@include pointer-events(none);
					}
				}
			}

			&[data-node-tourgroup="ungrouped"]
			{
				margin-top: 10px;
				padding-top: 10px;
				margin-bottom: 10px;
				padding-bottom: 10px;
				border-top: 1px solid $brand-gray-02;
				border-bottom: 1px solid $brand-gray-02;

				> .jstree-anchor
				{
					margin: 0;
				}

				&:first-child
				{
					margin-top: 0;
					border-top: 0;
					padding-top: 0;
				}

				&:last-child
				{
					margin-bottom: 0;
					border-bottom: 0;
					padding-bottom: 0;
				}
			}

			&.jstree-node-selected, &[viaurl="true"]
			{
				> .jstree-anchor
				{
					&:before
					{
						@include opacity(1);
					}
				}
			}

			&.ctx-active
			{
				> .jstree-anchor
				{
					background: $brand-gray-01;
				}
			}

			&.jstree-has-notif
			{
				> .jstree-anchor
				{
					.node-notif
					{
						display: block;
					}
				}
			}

			&.jstree-node-playing
			{
				&:before
				{
					@include opacity(1);
				}

				/* > .jstree-anchor
				{
					&:before
					{
						@include bg-color($brand-success);
						@include animation(0s, 2s, playing);
					}
				} */
			}

			&.jstree-opening, &.jstree-node-loading
			{
				> .jstree-anchor
				{
					> .node-loader
					{
						@include opacity(1);
					}

					> .node-icon
					{
						@include opacity(0);
					}
				}
			}

			&.jstree-node-has-child
			{
				> .jstree-anchor
				{
					> .node-expander
					{
						display: block !important;
					}
				}
			}

			&.jstree-open
			{
				> .jstree-anchor
				{
					> .node-expander
					{
						span
						{
							margin-top: 2px;

							@include transform(rotate(45deg));
						}
					}

					> .node-icon
					{
						&.has-states
						{
							.icon-static
							{
								@include opacity(0);
							}

							.icon-active
							{
								@include opacity(1);
							}
						}
					}
				}
			}

			&.jstree-keep-active
			{
				> .jstree-anchor
				{
					&:before
					{
						@include opacity(1);
					}

					> .node-actions
					{
						@include opacity(1);
						@include pointer-events(auto);
					}
				}
			}

			&.jstree-dnd-parent
			{
				.jstree-node
				{
					> .jstree-anchor
					{
						background: $brand-gray-01;

						&:before
						{
							display: none;
						}
					}
				}
			}

			&[aria-selected="true"]
			{
				> .jstree-anchor
				{
					background: $brand-gray-01;
				}
			}
		}
	}

	&:before
	{
		content: '';
		width: 100%;
		height: 100%;

		@include opacity(0);
		@include transition;
		@include pointer-events(none);
		@include transition-delay(0.2s);
		@include position(absolute, 999, 0, 0);
		@include faded-background($black-color, 0.3);
	}

	&.jstree-dnd-active
	{
		.jstree-children
		{
			.jstree-node:not([type="20"])
			{
				@include pointer-events(none);
			}
		}
	}

	&.tours-tree
	{
		.jstree-children
		{
			.jstree-node[data-node-type="folder"]
			{
				> .jstree-anchor
				{
					> .node-actions
					{
						> .atn-add
						{
							display: none;
						}
					}
				}
			}

			.jstree-node.jstree-node-tour
			{
				> .jstree-anchor
				{
					> .atn-publish
					{
						display: none;
					}
				}
			}
		}
	}

	&.dnd-disabled
	{
		*
		{
			@include pointer-events(none !important);
		}

		&:before
		{
			@include opacity(1);
			@include transition-delay(0s);
		}
	}

	&.publish-tree
	{
		.jstree-children
		{
			.jstree-node
			{
				> .jstree-anchor
				{
					padding-left: 70px;

					.node-icon:not(.atn-publish)
					{
						left: 35px;

						&.node-icon-ungrouped
						{
							display: none;
						}

						&.node-icon-grouped
						{
							display: block;
						}
					}

					> .atn-publish
					{
						display: block;
						z-index: 3;
					}
				}

				&[data-node-default="true"]
				{
					> .jstree-anchor
					{
						.node-icon:not(.atn-publish)
						{
							&.node-icon-ungrouped
							{
								display: block;
							}

							&.node-icon-grouped
							{
								display: none;
							}
						}
					}
				}
			}
		}
	}

	&.jstree-dnd-enabled
	{
		.jstree-children
		{
			.jstree-node
			{
				.jstree-anchor
				{
					&:before
					{
						display: none;
					}
				}
			}
		}
	}

	&.action-panel-tree
	{
		.jstree-children
		{
			.jstree-node
			{
				.node-actions
				{
					display: none;
				}
			}
		}
	}
}

// Hiding data while drag n drop

#vakata-dnd
{
	.node-avatar, .node-icon, .node-actions, .jstree-copy, .node-expander, .node-loader, .node-notif,
	.anchor-avatar, .anchor-icon, .anchor-actions, .jstree-copy, .anchor-expander, .anchor-loader, .anchor-notif
	{
		display: none !important;
	}

	.jstree-icon
	{
		// display: none !important;
		font-style: normal;
		background: none !important;

		&:before
		{
			font-family: 'Font Awesome 5 Free';

			@include font($font-base, 900, 1, inherit);
		}

		&.jstree-er
		{
			color: $brand-danger;

			&:before
			{
				content: "\f00d";
			}
		}

		&.jstree-ok
		{
			color: $brand-success;

			&:before
			{
				content: "\f00c";
			}
		}
	}
}

/* ----------  Tour Filter Tree  ---------- */

.filter-tree
{
	overflow: hidden;
	min-height: 30px;

	@include position;
	@include transition;

	.jstree-children
	{
		margin: 0;
	}

	.jstree-node
	{
		margin: 0;
		background: none;

		span, i
		{
			display: block;
		}

		.jstree-children
		{
			padding-left: 10px;
		}

		.jstree-icon, .jstree-wholerow, .node-loader
		{
			display: none !important;
		}

		.jstree-anchor
		{
			height: auto;
			padding: 8px;
			padding-left: 0;
			background: none;

			@include position;
			@include inline-flex;
			@include transition(none);
			@include font-p(rem-calc(14), $weight-regular, 1.2, $color-01);

			&:before
			{
				// content: '';
				width: 100vw;
				height: 100%;

				@include opacity(0);
				@include absolute-center(xy, -1);
				@include faded-background($black-color, 0.085);
			}

			&.jstree-clicked, &.jstree-hovered
			{
				@include box-shadow(none);
			}

			&:hover
			{
				background: none;

				@include box-shadow(none);

				&:before
				{
					@include opacity(1);
				}
			}
		}

		// .node-expander
		// {
		// 	display: none;
		// 	cursor: pointer;
		// 	padding-left: 8px;
		// 	padding-right: 5px;

		// 	@include pointer-events(auto);
		// 	@include font-fa($font-base, $weight-regular, 1, $brand-gray-02);

		// 	span
		// 	{
		// 		margin: 0;

		// 		@include transform(rotate(0));
		// 	}
		// }

		.node-icon
		{
			margin-right: 7px;

			@include position;
			@include font($font-md, $weight-regular, 1, $brand-gray-02);

			i
			{
				@include font(inherit, inherit, inherit, inherit);
			}

			.icon-active
			{
				@include absolute-center;
			}

			&.atn-publish
			{
				display: none;
			}

			&.node-icon-img
			{
				img
				{
					max-height: 17px;
				}
			}

			&.has-states
			{
				i
				{
					@include transition;
				}

				.icon-active
				{
					@include opacity(0);
				}
			}
		}

		.node-actions
		{
			display: none;
		}

		&:not(.jstree-node-has-child)
		{
			> .jstree-anchor
			{
				padding-left: 20px;
			}
		}

		// &.jstree-node-has-child
		// {
		// 	> .jstree-anchor
		// 	{
		// 		> .node-expander
		// 		{
		// 			display: block !important;
		// 		}
		// 	}
		// }
		
		&.jstree-open
		{
			> .jstree-anchor
			{
				// > .node-expander
				// {
				// 	span
				// 	{
				// 		margin-top: 2px;

				// 		@include transform(rotate(45deg));
				// 	}
				// }

				> .node-icon
				{
					&.has-states
					{
						.icon-static
						{
							@include opacity(0);
						}

						.icon-active
						{
							@include opacity(1);
						}
					}
				}
			}
		}
	}


	&:after
	{
		content: 'Loading...';
		padding: 8px;

		@include transition;
		@include absolute-center(y);
		@include pointer-events(none);
		@include font-p($font-sm, $weight-regular, 1.2, $color-01);
	}

	&.jstree-ready
	{
		&:after
		{
			@include opacity(0);
		}
	}
}

/* ----------  Keyframes  ---------- */

@include keyframe(playing)
{
	0%
	{
		@include opacity(0.2);
	}

	50%
	{
		@include opacity(0.4);
	}

	100%
	{
		@include opacity(0.2);
	}
}

#jstree-marker
{
	// display: none !important;
	border: 0;
	height: 2px;
	width: 150px;
	z-index: 1111;
	background: $brand-gray-02;

	@include transform(translateX(6px));
}
