/* ----------  Marketplace  ---------- */

.marketplace-container
{
	background: $white-color;

	@include md-default-shadow;

	.marketplace-inner
	{
		height: 100%;
		max-height: 100%;
		overflow-y: auto;
	}

	.panel
	{
		margin: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		@include position;
		@include flex(column);

		.panel-search
		{
			@include transition;
			@include position(absolute, 11, $top: 6px, $right: 290px);

			.global-search-container
			{
				width: 100%;

				form
				{
					.filter-row
					{
						label
						{
							display: block;
						}

						.icheck-inline
						{
							label
							{
								display: inline-block;
							}
						}
					}
				}

				.search-form 
				{
					.form-control
					{
						width: 30px;
						height: 30px;
						padding: 0 15px;

						@include transform(translateY(-50%));
						@include position(absolute, 33, $top: 50%, $right: -1px);
					}

					.btn-submit
					{
						width: 30px;
						height: 30px;

						@include reset-position;

						.btn-icon
						{
							background: $white-color;
						}
					}
				}

				.search-filter
				{
					width: 280px;
					min-width: 100px;

					.toggle-row
					{
						flex-direction: column;
						align-items: flex-start;

						.icheck-inline
						{
							display: block;
							margin-bottom: 10px;

							&:last-child
							{
								margin: 0;
							}
						}
					}
				}

				&.valid
				{
					.search-form
					{
						.btn-submit
						{
							.btn-icon
							{
								background: $brand-primary;
							}
						}
					}
				}
			}

			@media (max-width: 768px)
			{
				right: 0;
			}
		}

		.panel-header
		{
			width: 100%;
			padding: 10px;
			background: $brand-success-01;

			@include position;
			@include inline-flex;

			.header-title
			{
				flex: 1;

				h1
				{
					@include font-p($font-lg, $weight-bold, 1.2, $white-color);
				}
			}

			.header-view-selector
			{
				form
				{
					@include inline-flex;

					.md-form-group
					{
						margin: 0;
						margin-right: 10px;

						.iradio_md
						{
							border-color: $white-color;

							&.checked
							{
								border-color: $white-color !important;

								&:after
								{
									background: $white-color;
								}
							}
						}

						label
						{
							color: $white-color;
						}

						&:last-child
						{
							margin: 0;
						}
					}
				}
			}
		}

		.panel-body
		{
			flex: 1;
			padding: 0;
			width: 100%;
			// height: 100%;

			@include position;
			@include flex(column, flex-start, flex-start)
			{
				flex-wrap: nowrap;
			}
		}

		.panel-map-view
		{
			flex: 1;
			width: 100%;
			height: 100%;

			@include position;
			// @include transition;

			.map-options
			{
				background: $white-color;

				@include md-default-shadow;
				@include border-radius(3px);
				@include position(absolute, 99, $top: 5px, $right: 5px);

				ul
				{
					@include reset-list;

					li
					{
						a
						{
							display: block;
							padding: 5px;

							@include font($font-base, $weight-regular, 1, $brand-gray-07);

							i
							{
								@include font-inherit;
							}
						}
					}
				}
			}

			.map-results-toggler
			{
				display: none;

				@include transition;
				@include position(absolute, 111, $top: -38px, $right: 10px);

				a
				{
					display: block;
					width: 30px;
					height: 30px;
					background: $brand-gray-01;

					@include position;
					@include border-radius(99px);
					@include font($font-lg, $weight-regular, 1, $color-01);

					i
					{
						@include font-inherit;
						@include absolute-center;
					}

					.icon-list
					{
						display: block;
					}

					.icon-close
					{
						display: none;
					}
				}
			}

			.map-wrapper
			{
				@include position;
			}

			.map-canvas
			{
				height: 100%;
			}

			.licensing-map-canvas
			{
			}
		}

		.panel-content-view
		{
			width: 100%;
			overflow: hidden;
			background: $brand-gray-01;
			// max-height: calc(750px - 500px);

			@media (max-width: $screen-sm-max-alt)
			{
				max-height: none;
			}
		}

		.panel-section
		{
			.section-header
			{
				padding: 8px;
				border-left: 3px solid $brand-gray-09;
				background: darken($brand-gray-01, 3%);

				h4
				{
					@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-09);
				}
			}

			.section-content
			{
				margin: 0 -2px;
				min-height: 100px;

				@include position;
				@include opacity(0);
				@include transition;
				@include pointer-events(none);

				.content-placeholder
				{
					font-style: italic;

					@include absolute-center;
					@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-02);
				}

				.content-slick
				{
					.slick-track
					{
						margin: 0;
					}

					.market-item
					{
						padding: 0 2px;

						&:before
						{
							left: 2px;
						}
					}
				}

				.content-slick-nav
				{
					.slick-arrow
					{
						padding: 10px;
						cursor: pointer;

						@include absolute-center(y);
						@include faded-background($white-color, 0.6);
						@include font-fa($font-xl, $weight-regular, 1, $color-01);

						&.slick-next
						{
							right: 0;
							left: auto;
						}
					}
				}

				&.load
				{
					@include opacity(1);
					@include pointer-events(auto);
				}
			}
		}

		.panel-list-view
		{
			width: 100%;
			background: $white-color;

			@include position;
			// @include transition;

			.list-actions
			{
				@include position(absolute, 11, $top: 0, $right: 0);

				> ul
				{
					@include reset-list;
					@include inline-flex;

					> li
					{
						> a
						{
							height: 36px;
							min-width: 40px;

							@include position;
							@include inline-flex(center, center);
							@include font-p($font-sm, $weight-regular, 1, $white-color);

							i
							{
								display: block;

								@include font($font-md, $weight-regular, 1, $white-color);
							}
						}
					}
				}
			}

			.list-filters
			{
				@include position(absolute, 11, $top: 0, $right: 0);

				.filters-atn
				{
					display: block;
					height: 36px;
					width: 40px;
					cursor: pointer;

					@include position;
					@include font($font-md, $weight-regular, 1, $white-color);

					i
					{
						@include font-inherit;
						@include absolute-center;
					}
				}

				&.filters-hidden
				{
					display: none;
				}
			}

			.list-table
			{
				width: 100%;
				height: 100%;
				max-height: 100%;
				overflow-y: auto;
				padding-bottom: 20px;

				.dataTables_scrollHead
				{
					overflow: visible !important;

					.dataTables_scrollHeadInner
					{
						padding: 0 !important;
						width: 100% !important;
					}
				}

				.dataTables_scrollBody
				{
					border-color: $brand-gray-03;
				}

				table.marketplace-table
				{
					border: 0;
					margin: 0;
					padding: 0;
					width: 100% !important;

					thead
					{
						tr
						{
							th
							{
								border: 0;
								padding: 0;
								// padding-bottom: 5px;
								color: $white-color;
								background: lighten($brand-success-01, 7%);


								&:after
								{
									display: none;
								}

								span
								{
									display: block;

									&.inline-label
									{
										display: inline-block !important;
									}
								}

								.th-content
								{
									padding: 0;
									height: 100%;
									padding-left: 5px;
									padding-right: 10px;

									@include position;
									@include inline-flex;
								}

								.th-sort
								{
									cursor: pointer;
									margin-right: 10px;

									@include position;

									&:after
									{
										content: '\e164';
										font-family: 'Material Icons';

										@include font($font-lg, $weight-regular, 1, $white-color);
									}
								}

								.th-publish
								{
									cursor: pointer;
									margin-right: 10px;

									@include font($font-lg, $weight-regular, 1, $white-color);

									i
									{
										@include font(inherit, inherit, inherit, inherit);
									}
								}

								.th-title
								{
									flex: 1;
									padding: 10px 0;

									@include user-select(none);
								}
								
								.th-filter
								{
									background: $white-color;
									width: calc(100% - 15px);
									height: calc(100% - 15px);

									@include opacity(0);
									@include inline-flex;
									@include absolute-center;
									@include pointer-events(none);

									input
									{
										flex: 1;
										border: 0;
										width: 100%;
										height: 100%;
										padding: 0 7px;

										@include box-shadow(none);
										@include font-p($font-sm, $weight-regular, 1.4, $color-01);
									}

									.irs.irs-sm
									{
										width: calc(100% - 40px);
									}

									.atn-clear
									{
										padding: 3px;
										cursor: pointer;
									}
								}

								&.filter-open
								{
									.th-sort, .th-publish, .th-title
									{
										@include opacity(0);
										@include pointer-events(none);
									}

									.th-filter
									{
										@include opacity(1);
										@include pointer-events(auto);
									}
								}

								&.th-fixed
								{
									width: 50px;
								}

								&.th-publish
								{
									padding-left: 6px;

									ul
									{
										@include reset-list;

										li
										{
											a
											{
												@include font-inherit;
											}
										}
									}
								}

								&.sorting_asc
								{
									.th-sort
									{
										&:after
										{
											content: '\e316';
										}
									}
								}

								&.sorting_desc
								{
									.th-sort
									{
										&:after
										{
											content: '\e313';
										}
									}
								}

								&.sorting_asc, &.sorting_desc
								{
									cursor: default;
									padding-left: 0;
									padding-right: 0;
								}
							}
						}
					}

					td
					{
						font-size: $font-sm;

						span
						{
							display: block;
						}

						i
						{
							@include font(inherit, inherit, inherit, inherit);
						}

						.atn-publish
						{
							display: block;
							cursor: pointer;

							@include font($font-md, $weight-regular, 1, $brand-gray-02);

							&.published
							{
								color: $brand-success-01;
							}
						}

						.th-actions
						{
							@include opacity(0);
							@include inline-flex;

							.atn
							{
								display: block;
								cursor: pointer;
								margin-right: 5px;

								@include font($font-base, $weight-regular, 1, $color-01);

								img
								{
									display: block;
									max-height: 16px;
								}

								i
								{
									display: block;

									@include font(inherit, inherit, inherit, inherit);
								}

								&:last-child
								{
									margin: 0;
								}
							}
						}

						&[class*="sorting"]
						{
							background: none;
							background-image: none !important;
						}
					}

					tbody
					{
						tr
						{
							td
							{
								border-left: 3px solid transparent;
							}

							&.tr-highlight
							{
								td
								{
									&:first-child
									{
										border-left-color: $brand-primary;
									}
								}

								@each $class, $brand in $categories
								{
									&.tr-#{$class}
									{
										td
										{
											&:first-child
											{
												border-left-color: $brand !important;
											}
										}
									}
								}
							}

							&.active, &.tr-hovered, &:hover, &:active, &:focus
							{
								td
								{
									background: $brand-gray-11;
									
									.th-actions
									{
										@include opacity(1);
									}
								}
							}
						}
					}
				}
			}

			&.mcs-parent
			{
				.list-actions
				{
					display: none;
				}

				&.scroll-top
				{
					.list-actions
					{
						display: block;
					}
				}
			}
		}
	}

	&.default-marketplace
	{
		.panel
		{
			height: auto;

			.panel-header
			{
				background: $brand-gray-12;
			}

			.panel-body
			{
				.panel-map-view
				{
					height: 450px;

					@include clearfix;

					.map-wrapper
					{
						float: left;
						height: 100%;
						width: calc(100% - 280px);

						@media (max-width: 768px)
						{
							float: none;
							clear: both;
							width: 100%;
						}
					}

					.results-wrapper
					{
						width: 280px;
						float: right;
						margin-top: -44px;
						background: $white-color;
						height: calc(100% + 44px);

						@include position;
						@include transition;

						.results
						{
							height: 100%;
							max-height: 100%;

							@include flex;

							.section-title
							{
								margin: 0;
								width: 100%;
								padding: 10px;
								padding-left: 20px;
								background: lighten($brand-gray-12, 15%);

								h2
								{
									@include font-p($font-lg, $weight-bold, 1.2, $white-color);
								}
							}

							.results-content-wrapper
							{
								flex: 1;
								width: 100%;
								max-height: 100%;

								@include position;

								.results-content
								{
									min-height: 100%;

									@include position;
								}
							}

							.results-content
							{
								.market-item
								{
									&.state-hover, &:hover
									{
										&:before
										{
											@include opacity(1);
										}
									}
								}
							}

							.results-footer
							{
								width: 100%;
								padding-top: 10px;
								padding-bottom: 5px;
								
								.results-pagination
								{
									text-align: center;
								}

								.pagination
								{
									li
									{
										a
										{
											padding: 4px 7px;
											font-size: $font-xs;

											@include transition(none);
											@include border-radius(99px);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.licensed-marketplace
	{
		.panel
		{
			.panel-header
			{
				background: $brand-warning-02;
			}

			.panel-list-view
			{
				.list-table
				{
					table.marketplace-table
					{
						thead
						{
							tr
							{
								th
								{
									background: lighten($brand-warning-02, 7%);
								}
							}
						}
					}

					.react-table
					{
						.rt-table
						{
							.rt-thead
							{
								.rt-tr
								{
									.rt-th
									{
										background: lighten($brand-warning-02, 7%);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.licensing-marketplace
	{
		.panel
		{
			.panel-header
			{
				background: $brand-success-01;
			}

			.panel-list-view
			{
				.list-table
				{
					table.marketplace-table
					{
						thead
						{
							tr
							{
								th
								{
									background: lighten($brand-success-01, 7%);
								}
							}
						}
					}

					.react-table
					{
						.rt-table
						{
							.rt-thead
							{
								.rt-tr
								{
									background: lighten($brand-success-01, 7%);
								}
							}
						}
					}
				}
			}
		}
	}
}

/* ----------  Search  ---------- */

body.search-open
{
	.marketplace-container
	{
		.panel
		{
			.panel-search
			{
				.global-search-container
				{
					.search-form
					{
						.form-control
						{
							z-index: 5;
							width: 280px;

							@include border-radius(30px);
						}
					}
				}
			}
		}

		&.minimize-results
		{
			.panel
			{
				.panel-search
				{
					right: 10px;
				}

				.panel-body
				{
					.map-results-toggler
					{
						@include opacity(0);
						@include pointer-events(none);
					}
				}
			}
		}
	}
}

/* ----------  Minimized Marketplace Result  ---------- */

.marketplace-container.default-marketplace
{
	&.minimize-results
	{
		.panel 
		{
			.panel-search
			{
				right: 50px;
			}

			.panel-body 
			{
				.panel-map-view 
				{
					.map-results-toggler
					{
						display: block;
					}

					.map-wrapper
					{
						float: none;
						clear: both;
						width: 100%;
					}

					.results-wrapper
					{
						margin: 0;
						float: none;
						clear: both;

						@include transform(translateX(100%));
						@include position(absolute, 99, $top: -44px, $right: 0);
					}
				}
			}
		}

		&.results-open
		{
			.panel 
			{
				.panel-search
				{
					@include opacity(0);
					@include pointer-events(none);
				}

				.panel-body 
				{
					.panel-map-view 
					{
						.map-results-toggler
						{
							a
							{
								.icon-list
								{
									display: none;
								}

								.icon-close
								{
									display: block;
								}
							}
						}

						.results-wrapper
						{
							@include transform(translateX(0));
						}
					}
				}
			}
		}
	}
}