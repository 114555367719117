/* ----------  Sign In  ---------- */

.auth-modal
{
	hr
	{
		margin: 20px 0;
	}

	.socials-container
	{
		display: flex;
		width: 100%;
		text-align: center;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		ul
		{
			display: block;
			width: 85%;
			
			li
			{
				width: 33.33%;
				padding: 0 7px;

				a
				{
					display: block;
					padding: 8px 5px;
					background: $brand-primary;

					@include border-radius(3px);
					@include box-shadow(0 3px 15px rgba($black-color, 0.2));
					@include font-p($font-md, $weight-regular, 1, $white-color);
				}
			}
		}
	}
}