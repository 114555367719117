/* ----------  Validation  ---------- */

.validation-error
{
	display: flex;
	margin: 10px 0;
	width: 100%;
	flex-direction: row;
	align-items: center;

	label
	{
		padding-left: 17px;

		@include position;
		@include font-p($font-sm, $weight-semibold, 1.3, $brand-danger);

		&:before
		{
			content: "\f06a";
			margin-top: -1px;

			@include absolute-center(y);
			@include font-fa($font-base, inherit, 1, inherit);
		}
	}
}