/* ----------  Hero Section  ---------- */

.hero-section
{
	// min-height: 600px;

	.map-wrapper
	{
		overflow: hidden;

		@media (max-width: $screen-sm-max)
		{
			height: 100vh !important;
		}
	}

	.map-resize-containment
	{
		background: $black-color;

		@include opacity(0);
		@include pointer-events(none);
		@include position(absolute, 999, $top: 300px, $bottom: 180px, $left: 0, $right: 0);
	}

	.map-resize-handle
	{
		display: none;
	}

	.map-resize-overlay
	{
		display: none;
		width: 100%;
		height: 100%;

		// @include absolute-center(xy, 33);
		@include position(absolute, 33, $bottom: 0, $left: 0);
	}

	.map
	{
		@include position;
	}

	.streetview-players-container
	{
		height: 100%;
		width: 100%;

		@include position(relative, 999);
	}

	.streetview
	{
		width: 100%;
		height: 100%;
		background: $brand-gray-01;

		@include opacity(0);
		@include transition;
		@include pointer-events(none);
		@include transform(translateY(-20px));
		@include position(absolute, 5, 0, 0);

		.streetview-pano
		{
			width: 100%;
			height: 100%;

			@include position(absolute, 5, 0, 0);

			.streetview-pano-preloader
			{
				width: 100%;
				height: 100%;

				@include position(absolute, 111, 0, 0);
				@include faded-background($black-color, 0.7);

				.preloader
				{
					@include absolute-center;
				}
			}

			.streetview-pano-canvas
			{
				width: 100%;
				height: 100%;

				// &.paused
				// {
				// 	// @include pointer-events(none);
				// }
			}

			.streetview-pano-image
			{
				width: 100%;
				height: 100%;
				overflow: hidden;

				@include background-defaults;
				@include position(absolute, 11, 0, 0);
			}

			.leg-progress-components
			{
				display: block;
				bottom: 70px;

				@include opacity(0);
				@include transition;
			}

			.streetview-pano-image:visible + .leg-progress-components
			{
				display: none;
			}
		}

		.streetview-pano-controls
		{
			ul
			{
				@include reset-list;
				@include transition;
				@include opacity(0);
				@include inline-flex;
				@include pointer-events(none);

				li
				{
					a
					{
						display: block;
						width: 50px;
						height: 50px;
						overflow: hidden;

						@include position;
						@include border-radius(99px);

						i
						{
							@include absolute-center;
							@include font(rem-calc(30), $weight-regular, 1, $white-color);
						}

						img
						{
							width: auto;
							height: 22px;

							@include absolute-center;

							@media (max-width: $screen-xs-max)
							{
								height: 18px;
							}
						}

						&.lg
						{
							width: 65px;
							height: 65px;

							img
							{
								height: 35px;

								@media (max-width: $screen-xs-max)
								{
									height: 26px;
								}
							}

							@media (max-width: $screen-xs-max)
							{
								width: 55px;
								height: 55px;
							}
						}

						@media (max-width: $screen-xs-max)
						{
							width: 40px;
							height: 40px;
						}
					}

					&.inactive
					{
						> a
						{
							@include opacity(0.7);
						}
					}
				}

				&.controls-sm
				{
					li
					{
						a
						{
							width: 30px;
							height: 30px;

							.material-icons
							{
								font-size: $font-md;
							}

							img
							{
								height: 16px;
							}
						}
					}
				}
			}

			.primary-controls
			{
				@include transform(translateX(-50%));
				@include position(absolute, 99, $bottom: 0, $left: 50%);
			}

			.secondary-controls
			{
				@include position(absolute, 99, $bottom: 7px, $right: 15px);

				@media (max-width: 1299px)
				{
					top: 15px;
					bottom: auto;
				}
			}

			.floating-controls
			{
				flex-direction: column;
				background: $black-color;
				border: 1px solid $white-color;

				@include position(absolute, 99, $bottom: 70px, $right: 15px);

				li
				{
					border-bottom: 1px solid $white-color;

					&:last-child
					{
						border: 0;
					}
				}
			}
		}

		.controls-container
		{
			width: 100%;
			height: 65px;

			@include position(absolute, 9, $bottom: 0, $left: 0);

			&:before, &:after
			{
				content: '';
				width: 100%;
				height: 100px;

				background: -moz-linear-gradient(top, rgba($black-color, 0) 0%, rgba($black-color, 0.8) 100%);
				background: -webkit-linear-gradient(top, rgba($black-color, 0) 0%, rgba($black-color, 0.8) 100%);
				background: linear-gradient(to bottom, rgba($black-color, 0) 0%, rgba($black-color, 0.8) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$black-color, endColorstr=$black-color,GradientType=0);

				@include transition;
				@include opacity(0);
				@include pointer-events(none);
				@include position(absolute, 2);
			}

			&:before
			{
				display: none;
				top: 0;

				@include transform(scaleY(-1));
			}

			&:after
			{
				bottom: 0;
			}

			// @media (max-width: 1299px)
			// {
			// 	&:before
			// 	{
			// 		// display: block;
			// 	}
			// }

			&:hover, &:active, &:focus
			{
				.streetview-pano-controls
				{
					ul
					{
						@include opacity(1);
						@include pointer-events(auto);
					}
				}

				.streetview-map-expander
				{
					@include opacity(1);
				}

				&:before, &:after
				{
					@include opacity(1);
				}
			}
		}

		.streetview-map-expander
		{
			display: block;

			@include opacity(0);
			@include transition;
			@include position(absolute, 11, $bottom: 12px, $left: 15px);

			a
			{
				@include font($font-xl, $weight-regular, 1, $white-color);

				i
				{
					@include font(inherit, inherit, inherit, inherit);
				}
			}
		}

		.streetview-map
		{
			display: none;
			width: 300px;
			height: 180px;
			overflow: hidden;

			@include position(absolute, 11, $bottom: 20px, $left: 15px);

			.streetview-map-actions
			{
				@include position(absolute, 11, $top: 10px, $right: 10px);

				ul
				{
					li
					{
						margin-right: 10px;

						a
						{
							display: block;
							width: 30px;
							height: 30px;
							background: $white-color;

							@include position;
							@include md-default-shadow;
							@include border-radius(4px);
							@include font($font-lg, $weight-regular, 1, $color-01);

							i
							{
								@include absolute-center;
								@include font(inherit, inherit, inherit, inherit);
							}

							.icon-contract
							{
								display: block;
							}

							.icon-expand
							{
								display: none;
							}
						}

						&.item-shrink
						{
							display: none;

							a
							{
								i
								{
									@include transform(translate(-50%, -50%) rotate(-45deg));
								}
							}
						}

						&:last-child
						{
							margin: 0;
						}
					}
				}
			}

			.streetview-map-canvas
			{
				width: 100%;
				height: 100%;
				min-height: 300px;

				@include absolute-center;

				*
				{
					-webkit-box-sizing: initial;
					-moz-box-sizing: initial;
					box-sizing: initial;
				}
			}

			&.enable-transition
			{
				@include transition;
			}

			&.minimize
			{
				/* left: 10px;
				bottom: 10px;
				width: 300px;
				height: 180px; */

				@include md-default-shadow;

				.streetview-map-actions
				{
					ul
					{
						li
						{
							&.item-resize
							{
								a
								{
									.icon-contract
									{
										display: none;
									}

									.icon-expand
									{
										display: block;
									}
								}
							}

							&.item-shrink
							{
								display: block;
							}
						}
					}
				}
			}
		}

		&.video-player
		{
			z-index: 11;

			&.footer-player
			{
				z-index: 3;
			}
		}

		&.active
		{
			@include opacity(1);
			@include pointer-events(auto);
			@include transform(translateY(0));
		}

		&.map-open
		{
			.streetview-map-expander
			{
				display: none;
			}
		}

		&:hover, &:active, &:focus
		{
			.streetview-pano
			{
				.leg-progress-components
				{
					@include opacity(1);
				}

				.leg-captions-container
				{
					bottom: 100px;
				}
			}
		}
	}

	.mini-map
	{
		width: 350px;
		height: 200px;
		background: $brand-gray-01;
		border: 2px solid darken($brand-gray-01, 10%);

		@include transition;
		@include opacity(0);
		@include transform(translateX(-100%));
		@include position(absolute, 22, $bottom: 0, $left: 0);

		&.open
		{
			@include opacity(1);
			@include transform(translateX(0));
		}
		
		@media (max-width: $screen-xs-max)
		{
			width: 100%;
		}
	}

	.map-navigation
	{
		@include position(absolute, 11, $top: 0, $right: 0);

		ul
		{
			@include inline-flex;

			li
			{
				.atn-navigation
				{
					display: block;
					padding: 7px;

					@include link;
					@include font($font-lg, $weight-regular, 1, $color-01);

					.atn-icon
					{
						@include font-inherit;
					}

					&.disabled
					{
						color: $brand-gray-02;
					}
				}
			}
		}
	}

	.results-wrapper
	{
		.results
		{
			@include flex;

			.section-title, .results-content, .results-footer
			{
				width: 100%;
			}

			.results-content
			{
				flex: 1;
			}
		}
	}

	&.has-sidebar
	{
		.map-wrapper
		{
			width: calc(100% - 410px);

			@media (max-width: $screen-md-max)
			{
				width: calc(100% - 350px);
			}

			@media (max-width: $screen-sm-max)
			{
				width: 100%;
			}
		}

		.results-wrapper
		{
			width: 400px;
			overflow-x: hidden !important;

			@media (max-width: $screen-md-max)
			{
				width: 340px;
			}

			@media (max-width: $screen-sm-max)
			{
				height: 100vh;

				@include max-width(320px);
				@include transform(translateX(100%));
				@include position(fixed, 1111, $top: 0, $right: 0);
			}
		}
	}

	@media (max-width: $screen-sm-max)
	{
		height: auto !important;
	}
}

body.dragging
{
	*
	{
		@include pointer-events(none);
	}

	.map-resize-handle
	{
		.resize-handle
		{
			@include pointer-events(auto !important);
		}
	}

	.hero-section
	{
		.streetview, .map
		{
			@include transition(none !important);
		}
	}
}

body.results-open
{
	.hero-section
	{
		&.has-sidebar
		{
			.results-wrapper
			{
				@include transform(translateX(0));
			}
		}
	}
}

body.streetview-open
{
	.hero-section
	{
		.map
		{
			@include pointer-events(none);
		}

		// .map-resize-handle
		// {
		// }

		&.has-sidebar
		{
			.results-wrapper
			{
				@media (max-width: $screen-sm-max)
				{
					@include opacity(0);
					@include pointer-events(none);
				}
			}
		}
	}
}

/* ----------  Result Content  ---------- */

.hero-section 
{
	.results-wrapper
	{
		.results-content
		{
			.result-item
			{
				display: block;
				height: auto;

				.controls-more
				{
					top: 10px;
					bottom: auto;
				}
			}
		}
	}
}

/* ----------  Block  ---------- */

.block
{
	.item.has-hover
	{
		background: $brand-gray-05;

		@include position;

		> a, .additional-info
		{
			@include transition;
		}

		.item-hover
		{
			width: 100%;
			padding: 0 15px;
			margin-top: 10px;
			text-align: center;

			@include opacity(0);
			@include transition;
			@include absolute-center;

			h4
			{
				margin-bottom: 5px;
				color: $brand-primary;
			}

			p
			{
				@include font-p($font-sm, $weight-regular, 1.4, $black-color);
			}
		}

		&:hover, &:active, &:focus
		{
			> a, .additional-info
			{
				@include opacity(0);
			}

			.item-hover
			{
				margin-top: 0;

				@include opacity(1);
			}
		}
	}
}

/* ----------  Page Layout  ---------- */

.app-root
{
	.site-wrapper
	{
		min-height: 100vh;

		@include flex;

		.app-page-content
		{
			flex: 1;

			@include flex;

			.hero-section
			{
				flex: 1;

				@include flex(row, normal, stretch);

				.map, .map-wrapper
				{
					height: auto;
				}

				.map-wrapper
				{
					flex: 1;

					@include flex;

					.google-map
					{
						flex: 1;

						@include flex;
					}
				}

				.results-wrapper
				{
					display: block;
					float: none;
					height: auto;
				}
			}
		}
	}
}
