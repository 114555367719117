/*----------  Preloader  ----------*/

.preloader
{
	@include opacity(0);
	@include transition;
	@include pointer-events(none);
	@include position(absolute, 99, 0, 0, 0, 0);

	.preloader-icon
	{
		padding: 3px;
		background: $white-color;

		@include transition;
		@include border-radius(50%);
		@include position(absolute, 99, 20px, 50%);
		@include transform(translateX(-50%) scale(0));
		@include box-shadow(0 8px 17px 2px rgba($black-color, 0.10), 0 3px 14px 2px rgba($black-color, 0.10), 0 5px 5px -3px rgba($black-color, 0.12));
	}

	&.center
	{
		.preloader-icon
		{
			top: 50%;

			@include transform(translate(-50%, -50%) scale(0));
		}

		&.loading
		{
			.preloader-icon
			{
				@include transform(translate(-50%, -50%) scale(1));
			}
		}
	}

	&.minimal
	{
		.preloader-icon
		{
			padding: 0;
			background: none;

			@include box-shadow(none);
		}
	}

	&.loading
	{
		@include opacity(1);
		@include pointer-events(auto);

		.preloader-icon
		{
			@include transform(translateX(-50%) scale(1));
		}
	}

	&.relative
	{
		@include position(relative, 99, auto, auto, auto);

		.preloader-icon
		{
			@include position(relative, 99, auto, auto, auto);
		}

		&.loading
		{
			.preloader-icon
			{
				@include transform(translateX(0) scale(1));
			}
		}
	}
}