/* ----------  Vector Map  ---------- */

.vector-map-container
{
	min-height: 475px;

	.uk-map-grid
	{
		-ms-align-items: center;
		align-items: center;
	}

	.map-canvas-container
	{
		padding: 40px 0;

		@include position;
	}

	.map-reset-btn
	{
		display: none;
		width: 100%;

		@include position(absolute, 5, $bottom: 0, $left: 0);
	}

	.map-canvas
	{
		.map
		{
			@include position;

			.btn-zoom
			{
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				-o-user-select: none;
				user-select: none;

				@include link;
				@include border-radius(3px);
				@include position(absolute, 11, $bottom: 10px, $left: 10px);
				@include font-p($font-lg, $weight-bold, 1, $brand-gray-07);

				i
				{
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					-o-user-select: none;
					user-select: none;

					@include font(inherit, inherit, inherit, inherit);
				}

				&.zoom-in
				{
					bottom: 30px;
				}
			}

			svg
			{
				path.area
				{
					&.active:not(.disabled)
					{
						-webkit-fill-opacity: 1 !important;
						-moz-fill-opacity: 1 !important;
						-ms-fill-opacity: 1 !important;
						-o-fill-opacity: 1 !important;
						fill-opacity: 1 !important;
					}
				}
			}
		}
	}

	.map-details
	{
		table.map-details-table
		{
			border: 0;

			thead
			{
				tr
				{
					th
					{
						background-image: none;
						border-color: $brand-gray-03;
					}
				}
			}

			tbody
			{
				tr
				{
					td
					{
						border-color: $brand-gray-03;

						@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-07);

						&[class^="sorting"]
						{
							background: none;
						}

						.item
						{
							@include inline-flex;
						}

						span
						{
							font-family: inherit;

							@include font(inherit, inherit, inherit, inherit);
						}

						.item-flag
						{
							margin-right: 7px;
						}
					}

					&.active, &:hover, &:active, &:focus
					{
						td
						{
							background: $brand-gray-01;
						}
					}
				}
			}
		}
	}
}