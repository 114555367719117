/* ----------  Tour Filter Container  ---------- */

.tour-filter-container
{
	.filter-dropdown-container
	{
		@include position;

		.dropdown-toggler
		{
			.md-btn
			{
				border-bottom: 1px solid rgba($black-color, 0.12);

				@include position;

				.btn-icon
				{
					margin: 0;
					margin-top: -2px;

					@include absolute-center(y)
					{
						right: 0;
						left: auto;
					}
				}
			}
		}

		.dropdown-list
		{
			display: none;
			width: 100%;
			background: $white-color;

			@include opacity(0);
			@include pointer-events(none);
			@include position(absolute, 5, 0, 0);
			@include transform(translateY(10px));
			@include transition(all 0.15s ease-in-out);

			ul
			{
				margin: 0;
				padding: 0;
				list-style-type: none;
				background: $white-color;

				@include box-shadow(0 3px 6px rgba($black-color, 0.16), 0 3px 6px rgba($black-color, 0.23));

				li
				{
					@include position;

					a
					{
						display: block;
						color: #212121;
						padding: 5px 15px;
						padding-top: 11px;
						padding-bottom: 10px;
						font-size: 15px;
					}

					ul
					{
						display: none;
						width: 100%;

						@include opacity(0);
						@include pointer-events(none);
						@include position(absolute, 5, 0, 100%);
						@include transition(all 0.15s ease-in-out);

						&.open
						{
							max-height: 1000px;

							@include opacity(1);
							@include pointer-events(auto);
						}

						@media (max-width: $screen-xs-max)
						{
							max-height: 0;

							@include reset-position;
							@include box-shadow(none);
						}
					}

					&.selected
					{
						> a
						{
							background: rgba($black-color, 0.085);
						}
					}

					&.subnav-open, &:hover:not(.selected), &:active:not(.selected), &:focus:not(.selected)
					{
						> a
						{
							background: rgba($black-color, 0.04);
						}
					}
				}
			}

			&.open
			{
				@include opacity(1);
				@include pointer-events(auto);
				@include transform(translateY(0));
			}
		}
	}
}