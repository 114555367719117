.app-tabs-container
{
	.nav-tabs.nav-tabs-justified
	{
		li
		{
			flex: 1;
		}
	}

	.nav-border
	{
		height: 2px;
		width: 100%;
		background: $brand-gray-03;

		@include pointer-events(none);
		@include position(absolute, 2);
	}

	.nav-tabs
	{
		border: 0;

		@include inline-flex
		{
			flex-wrap: nowrap;
		}

		li
		{
			display: block;
			border: 0;
			margin: 0;
			float: none;
			clear: both;

			a
			{
				display: block;
				margin: 0;
				border: 0;
				text-align: center;

				@include font-p($font-base, $weight-bold, 1.2, $brand-primary);

				span
				{
					display: block;
				}

				.item-icon
				{
					font-size: rem-calc(25);

					@media (max-width: $screen-xs-max)
					{
						font-size: $font-lg;
					}
				}

				&:after
				{
					content: '';
					height: 2px;
					width: 100%;
					background: $brand-primary;

					@include opacity(0);
					@include transition;
					@include position(absolute, 5, $bottom: 0, $left: 0);
				}

				&:hover, &:active, &:focus
				{
					@include faded-background($brand-primary, 0.05);
				}

				@media (max-width: $screen-xs-max)
				{
					padding: 10px;
				}
			}

			&.active
			{
				> a
				{
					@include opacity(1);

					&:after
					{
						@include opacity(1);
					}

					&:hover, &:active, &:focus
					{
						border: 0;
					}
				}
			}

			&:hover, &:active, &:focus
			{
				> a
				{
					border: 0;
				}
			}
			
			&:hover:not(.active), &:active:not(.active), &:focus:not(.active)
			{
				> a
				{
				}
			}
		}
	}

	.tab-togglers-fixed
	{
		.nav-tabs
		{
			@include inline-flex;

			li
			{
				display: block;
				flex: 1;
				min-width: 60px;
			}
		}

		&.mCustomScrollbar
		{
			.mCSB_scrollTools
			{
				bottom: -12px !important;

				@include opacity(0 !important);
			}
		}
	}

	.tab-content
	{
		.tab-pane
		{
			padding: 0;
			padding-top: 10px;
			min-height: 100px;

			@include position;

			.rating-form
			{
				.form-action
				{
					@include inline-flex;

					.btn
					{
						margin-right: 10px;
					}
				}
			}
		}
	}
}

/* ----------  UK Tabs  ---------- */

.uk-tab
{
	> li
	{
		&.uk-active
		{
			> a
			{
				border-bottom-color: $brand-primary !important;
			}
		}
	}
}

/* ----------  Tabs Contextuals  ---------- */

$tabsContextuals: (
	('default', $brand-primary),
	('about', $black-color),
	('tag', $brand-warning-01),
	('reviews', $brand-danger),
	('comments', $brand-info),
	('viewed', $brand-success),
	('chats', $brand-warning),
	('link', $brand-info),
);

@each $class, $brand in $tabsContextuals
{
	.nav-tabs
	{
		li
		{
			&.tab-#{$class}
			{
				> a
				{
					@include text-color($brand);

					&:hover, &:active, &:focus
					{
						@include faded-background($brand, 0.15);
					}
				}

				&.active, &:hover, &:active, &:focus
				{
					> a
					{
						&:after
						{
							@include bg-color($brand);
						}
					}
				}
			}
		}
	}
}