/* ----------  Map Markers  ---------- */

.map
{
	.marker
	{
		.title
		{
			left: auto;
			right: 50px;

			&:before, &:after
			{
				left: auto;
				right: -6px;

				@include transform(scale(-1));
			}
		}

		.atn-publish
		{
			@include position(absolute, 5, -20px, -10px);
			@include font(rem-calc(22), $weight-regular, 1, $brand-gray-07);

			i
			{
				@include font(inherit, inherit, inherit, inherit);
			}
		}

		.marker-price
		{
			@include absolute-center(x, 5)
			{
				top: auto;
				bottom: -12px;
			}

			span
			{
				display: block;

				@include font-p($font-sm, $weight-bold, 1.2, $color-01);
			}
		}

		.marker-wrapper
		{
			transform-style: flat;

			.tag
			{
				z-index: 11;
			}

			.pin
			{
				z-index: 2;

				@include transition($bfv: false);

				&:before
				{
					top: -3px;
				}
			}

			.marker-subscription
			{
				display: none;

				@include transform(translateX(-50%));
				@include position(absolute, 5, -20px, 50%);
			}

			.lang-flag
			{
				overflow: hidden;
				width: 35px;
				height: 35px;
				transition-delay: 0.3s;
				border: 2px solid $brand-primary;

				@include opacity(0);
				@include border-radius(99px);
				@include transition(all 0.1s ease-in-out);
				@include position(absolute, 8, -3px, 1px);

				span
				{
					display: block;
					width: 35px;
					height: 35px;
					margin-top: -2px;
					background-position: unset;

					@include absolute-center;
				}
			}

			.marker-actions
			{
				width: 120px;
				height: 38px;
				padding-right: 5px;
				padding-left: 35px;
				text-align: center;
				transition-delay: 0.3s;
				background: $white-color;
				border: 1px solid $brand-gray-03;

				@include opacity(0);
				@include transition;
				@include border-radius(99px);
				@include pointer-events(none);
				@include position(absolute, 5, -5px, 5px);

				ul
				{
					display: flex;
					margin: 0;
					padding: 0;
					height: 100%;
					overflow: hidden;
					flex-direction: row;
					align-items: center;
					list-style-type: none;

					li
					{
						flex: 1;
						height: 100%;

						a
						{
							display: flex;
							height: 100%;
							flex: 1;
							overflow: hidden;
							align-items: center;
							justify-content: center;
							
							@include font-fa($font-sm, $weight-regular, 1, $brand-gray-03);

							img
							{
								max-height: 15px;
							}

							&.atn-cart
							{
								@include font($font-md, $weight-regular, 1, $color-01);

								.atn-icon
								{
									display: none;
								}

								.atn-icon.icon-static
								{
									display: block;
								}
							}
						}

						&.active
						{
							> a
							{
								&.atn-cart
								{
									@include font($font-md, $weight-regular, 1, $color-01);

									.atn-icon
									{
										display: none;
									}

									.atn-icon.icon-active
									{
										display: block;
									}
								}
							}
						}

						&:hover, &:active, &:focus
						{
							> a
							{
								color: $brand-primary;

								@include filter(drop-shadow(0 2px 5px rgba($black-color, 0.4)));
							}
						}
					}
				}
				
				&:hover, &:active, &:focus
				{
					border-color: $brand-primary;
				}
			}

			&:before
			{
				top: -4px;

				@include opacity(0);
				@include transition;
			}

			&:after
			{
				display: none;
			}
		}

		&:hover, &:active, &:focus
		{
			.marker-wrapper
			{
				.marker-actions
				{
					left: 7px;
					transition-delay: 0.3s;
				
					@include opacity(1);
					@include pointer-events(auto);
				}
			}
		}

		&.marker-published
		{
			.atn-publish
			{
				color: $brand-primary;
			}
		}

		&.marker-licensing
		{
			.marker-actions
			{
				width: 75px;
			}
		}

		&.marker-marketplace
		{
			.marker-actions
			{
				width: 100px;
			}

			&.active
			{
				.marker-wrapper
				{
					/* .marker-actions
					{
						left: 7px;
						transition-delay: 0.3s;
					
						@include opacity(1);
						@include pointer-events(auto);
					} */
				}
			}
		}

		&.marker-subscribed
		{
			.marker-wrapper
			{
				.marker-subscription
				{
					display: block;
				}
			}
		}

		&.active, &.hover-state, &:hover, &:active, &:focus
		{
			.title
			{
				right: 50px;
				left: auto;
			}

			.marker-wrapper
			{
				@include transform(none);

				.pin
				{
					@include transform(rotateY(180deg) translateY(-4px));
				}

				.lang-flag
				{
					transition-delay: 0.1s;

					@include opacity(1);
				}
			}
		}

		&.active
		{
			.marker-wrapper
			{
				&:before
				{
					@include opacity(1);
					@include animation(0s, 1.5s, pulse);
				}
			}
		}
	}
}

/* ----------  Map Info Contextuals  ---------- */

.map
{
	@each $class, $brand in $categories
	{
		.marker.marker-#{$class}
		{
			.marker-wrapper
			{
				.tag
				{
					@include bg-color($brand);
				}

				.pin
				{
					border-color: $brand;

					@include bg-color($brand);

					.image
					{
						&:after
						{
							border-color: $brand transparent transparent transparent;
						}
					}

					&:before
					{
						border-color: $brand;

						@include bg-color($brand);
					}
				}

				.lang-flag
				{
					border-color: $brand;
				}

				.marker-price
				{
					span
					{
						color: $brand;
					}
				}

				.marker-actions
				{
					ul
					{
						li
						{
							a
							{
								&.waves-effect
								{
									.waves-ripple
									{
										@include bg-color($brand);
									}
								}
							}

							&:hover, &:active, &:focus
							{
								> a
								{
									@include text-color($brand);
								}
							}
						}
					}

					&:hover, &:active, &:focus
					{
						border-color: $brand;
					}
				}

				&:before
				{
					@include bg-color($brand);
				}
			}

			&.marker-published
			{
				.atn-publish
				{
					color: $brand;
				}
			}
		}
	}
}

/* ----------  Key Frames  ---------- */

@include keyframe(pulse) {
	0% {
		@include opacity(0);
		@include transform(scale(0.8));
	}
	10% {
		@include opacity(1);
	}
	100% {
		@include opacity(0);
		@include transform(scale(1.8));
	}
}

/* ----------  Global Markers  ---------- */

.global-marker
{
	width: 15px;
	height: 15px;
	background: $brand-primary;

	@include position;
	@include border-radius(99px);

	&:after
	{
		content: '';
		width: 0;
		height: 0;
		
		margin: auto;
		border-style: solid;
		border-width: 4px 2px 0 2px;
		border-color: $brand-primary transparent transparent transparent;
		
		@include position(absolute, 5, $bottom: -4px, $left: 0, $right: 0);
	}

	&.sm
	{
		width: 12px;
		height: 12px;
	}
}