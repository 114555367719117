/* ----------  Body  ---------- */

body
{
	margin: 0;
	min-height: 100vh;
	
	@include position;
	@include font-p($font-base, $weight-regular, 1.2, $black-color);

	&.body-app
	{
		padding: 0;
		height: 100vh;
		overflow: hidden;
		min-height: 100vh;
	}

	&.uk-modal-open
	{
		#sidebar_main
		{
			z-index: 10 !important;
		}
		
		#header_main
		{
			z-index: 9 !important;
		}
	}
}

/* ----------  Shadows  ---------- */

.no-shadow
{
	@include box-shadow(none !important);
	@include text-shadow(none !important);
}

/* ----------  Containers  ---------- */

.container-fluid
{
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}

/* ----------  Video List  ---------- */

.video-lists
{
	li, .video-item
	{
		margin-bottom: 20px;

		a
		{
			display: block;
			text-align: center;
			letter-spacing: 0.5px;

			@include position;
			@include font-p($font-base, $weight-bold, 1.2, $black-color);

			span
			{
				display: block;
			}

			.video-overlay
			{
				width: 100%;
				height: 100%;

				@include position(absolute, 5, 0, 0);
				@include faded-background($white-color, 0.6);

				.overlay-inner
				{
					width: 100%;

					@include absolute-center;
				}
			}

			.video-btn
			{
				width: 70px;
				height: 70px;
				margin: auto;

				@include position;
				@include border-radius(999px);
				@include faded-background($black-color, 0.4);
				@include font-fa(rem-calc(30), $weight-regular, 1, $white-color);

				.fa
				{
					margin-left: 5px;

					@include absolute-center;
				}
			}

			.video-text
			{
				margin-top: 7px;
			}
		}

		&:last-child
		{
			margin: 0;
		}
	}
}

/* ----------  Lightbox  ---------- */

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg
{
	@include opacity(0);
	@include transition;
}

.mfp-with-zoom.mfp-ready .mfp-container
{
	@include opacity(1);
}

.mfp-with-zoom.mfp-ready.mfp-bg
{
	@include opacity(0.8);
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg
{
	@include opacity(0);
}

/* ----------  Search Section  ---------- */

.search-container
{
	form
	{
		// width: 75%;

		@include position;

		@media (max-width: $screen-sm-max)
		{
			width: 100%;
		}
	}

	.form-group
	{
		margin: 0;
	}

	.form-control
	{
		background: $brand-gray-01;
		border-bottom: 2px solid darken($brand-gray-01, 7%);

		@include box-shadow(none);

		&:hover, &:active, &:focus
		{
			@include box-shadow(none);
		}
	}

	.btn-search
	{
		width: 50px;
		height: 100%;
		background: none;
		font-size: $font-md;
		color: $brand-primary;
		
		@include box-shadow(none);
		@include position(absolute, 11, $top: 0, $right: 0);

		.fa
		{
			@include absolute-center;
		}
	}
}

/* ----------  Selectize Dropdown  ---------- */

.selectize-dropdown
{
	z-index: 1999;
}

/* ----------  MD Language  ---------- */

.md-language-wrapper
{
	display: flex;
	flex-direction: column;

	.selectize-control
	{
		margin: 0;

		.selectize-input
		{
			border: 0;
			width: 48px;
			padding: 8px 0;
		}
	}
}

/* ----------  Page Content  ---------- */

#page_content
{
	padding-left: 80px;

	@include transition;
	@include position(relative, 11);

	#page_content_inner
	{
		padding: 0;

		@include position;

		@media (max-width: $screen-sm-max)
		{
			padding: 0;
		}
	}

	&:before
	{
		z-index: -1;
	}

	@media (max-width: $screen-sm-max)
	{
		padding-left: 0;
	}
}

.sidebar_main_active
{
	#page_content
	{
		&:before
		{
			z-index: 999;
		}
	}
}

/* ----------  Main Sidebar  ---------- */

#sidebar_main
{
	z-index: 1001;
}

/* ----------  Ratings  ---------- */

.ratings
{
	.rating-list
	{
		@include inline-flex;

		li
		{
			margin-right: 3px;

			@include font-p($font-xs, $weight-regular, 1, $brand-warning);

			&.rate-count
			{
				margin-left: 3px;
			}

			&:last-child
			{
				margin-right: 0;
			}
		}
	}
}

/* ----------  Notify  ---------- */

.uk-notify
{
	z-index: 9999;
}

/* ----------  Leg Player  ---------- */

/* [data-graphic-panel]
{
	&:hover, &:active, &:focus
	{
		.leg-progress.ui-slider
		{
			.ui-slider-handle
			{
				@include opacity(1);
			}
		}
	}
} */

.leg-sliders-controls
{
	.tippy-popper
	{
		max-width: 100px;
		max-height: 70px;
		padding: 0 !important;
		
		.tippy-content
		{
			padding: 5px;
			min-width: 100px;
			min-height: 70px;

			@include flex(column, center, center);
		}
	}

	.leg-zoom-controls
	{
		@include position(absolute, 5, $top: -10px, $right: 20px);

		ul
		{
			li
			{
				a
				{
					display: block;
					padding: 0 3px;
				}
			}
		}
	}

	&.mixer-siders-controls
	{
		padding: 0 20px;

		@include position;
		@include inline-flex(normal);

		.leg-progress-components
		{
			flex: 1;
			width: auto;
			margin-top: 15px;

			@include flex(column, normal, space-between);
		}
	}
}

.leg-volume-controls
{
	margin-right: 7px;

	@include flex(column, normal, flex-end);

	.volume-control
	{
		margin-bottom: 0;

		.control-title
		{
			margin-bottom: 5px;

			@include font-p($font-xs, $weight-regular, 1.2, $color-01);
		}

		.control-slider
		{
			.volume-slider.ui-slider
			{
				bottom: 0;
				height: 4px;
				background: $brand-primary;

				.ui-slider-handle
				{
					border: 0;
					margin: 0;
					top: -3px;
					width: 7px;
					height: 7px;
					cursor: pointer;
					background: $brand-primary;

					@include border-radius(50%);
					@include transform(translateX(-50%));
				}

				.ui-slider-range
				{
					display: none;
				}

				&.disabled
				{
					@include opacity(0.5);
					@include pointer-events(none);
				}
			}
		}

		&.disabled
		{
			.control-title, .control-slider
			{
				@include pointer-events(none);
			}

			.control-slider
			{
				@include filter(grayscale(1));
			}
		}

		&:last-child
		{
			margin: 0;
		}
	}
}

.leg-progress-components
{
	height: 4px;
	width: calc(100% - 20px);
	background: $brand-gray-01;

	@include transition;
	@include pointer-events(none);
	@include transform(translateX(-50%));
	@include position(absolute, 99, $bottom: 0, $left: 50%);

	.components-overflow-handler
	{
		width: 100%;

		.ps__rail-x, .ps__rail-y
		{
			display: none !important;
		}
	}

	.leg-progress
	{
		margin: 0;
		border: 0;
		width: 100%;
		height: 100%;
		background: $brand-gray-01;

		@include transition;
		@include border-radius(0);
		@include position(absolute, 5, 0, 0);

		.ui-slider-range
		{
			border: 0;
			height: 100%;
			background: $brand-warning;

			@include border-radius(0);
		}

		.ui-slider-handle
		{
			display: none;
			margin: 0;
			border: 0;
			width: 20px;
			height: 20px;
			cursor: pointer;
			background: $brand-warning;
			border: 3px solid transparent;

			@include opacity(0);
			@include border-radius(99px);
			@include position(absolute, 5, 50%, 0);
			@include transform(translate(-50%, -50%));
			@include multi-transition(border 0.2s linear, opacity 0.2s linear);

			&.ui-state-hover
			{
				border-color: transparent;
			}

			&.ui-state-active
			{
				border-color: $brand-warning-01;
			}
		}

		&.progressing
		{
			@include pointer-events(auto);

			&:hover, &:active, &:focus
			{
				height: 7px;
			}
		}

		&.saving
		{
			@include pointer-events(none);

			.ui-slider-range
			{
				@include pointer-events(none);
				@include bg-color($brand-primary);
			}

			.ui-slider-handle
			{
				display: none !important;

				@include pointer-events(none);
			}
		}

		&.ui-slider
		{
			&:hover, &:active, &:focus
			{
				.ui-slider-handle
				{
					@include opacity(1);
				}
			}
		}

		&.infinite-progress
		{
			overflow: hidden;

			.line
			{
				width:150%;
				height:100%;

				@include opacity(0.4);
				@include position(absolute, 2);
			}

			.subline
			{
				height:100%; 

				@include position(absolute, 2);
			}

			.line, .subline
			{
				background: $brand-primary;
			}
			
			.inc
			{
				animation: increase 2s infinite;
			}
			
			.dec
			{
				animation: decrease 2s 0.5s infinite;
			}

			@keyframes increase
			{
				from { left: -5%; width: 5%; }
				to { left: 130%; width: 100%;}
			}
			
			@keyframes decrease
			{
				from { left: -80%; width: 80%;}
				to { left: 110%; width: 10%;}
			}

			&.saving
			{
				.line, .subline
				{
					background: $brand-success;
				}
			}
		}
	}

	.leg-markers
	{
		width: 100%;
		height: 100%;

		@include opacity(0);
		@include transition;
		@include pointer-events(none);
		@include position(absolute, 11, $bottom: 0, $left: 0);

		span
		{
			display: block;
			width: 4px;
			height: 100%;
			cursor: pointer;
			background: $brand-success;

			@include transition;
			@include pointer-events(auto);
			@include position(absolute, 5, 0, 0);
			// @include transform(translateX(-50%));

			&:hover, &:active, &:focus
			{
				background: $black-color;
			}
		}
	}

	.leg-overlays
	{
		width: 100%;
		height: 100%;

		@include pointer-events(none);
		@include position(absolute, 111, 0, 0);

		span
		{
			height: 100%;
			// width: 100px;
			background: $black-color;

			@include opacity(0.6);
			@include pointer-events(all);
			@include position(absolute, 2, 0, 0);
		}
	}

	.leg-progress-media-blocks
	{
		height: 10px;
		margin-bottom: 15px;
		background: $brand-gray-03;

		@include position;

		.media-block
		{
			height: 100%;
			background: $brand-primary;

			@include position(absolute, 1, 0, 0);
		}

		&:last-child
		{
			margin: 0;
		}
	}

	&.mixer-components
	{
		.leg-progress.ui-slider
		{
			z-index: 99;
			background: none;
			
			.ui-slider-range
			{
				display: none;
			}

			.ui-slider-handle
			{
				border: 0;
				width: 1px;
				height: calc(100% + 10px);

				@include opacity(1);
				@include border-radius(0);
			}

			&.progressing
			{
				&:hover, &:active, &:focus
				{
					height: 100%;
				}
			}
		}

		.leg-markers
		{
			height: 10px;
			background: $brand-gray-01;

			@include reset-position;
		}

		&.active
		{
			height: auto;
			background: none;

			@include reset-position;
			@include transition(none);

			&:hover, &:active, &:focus
			{
				height: auto;
			}
		}
	}

	&.active
	{
		@include pointer-events(auto);

		.leg-progress.ui-slider
		{
			cursor: pointer;

			.ui-slider-handle
			{
				display: block;
			}
		}

		.leg-markers
		{
			@include opacity(1);
		}

		&:hover, &:active, &:focus
		{
			height: 5px;
		}
	}

	&.global-volume-controls
	{
		top: 50%;
		left: 15px;
		width: auto;
		background: none;
		height: 70px !important;

		@include transform(translateY(-50%));

		.leg-progress
		{
			display: block;
			width: 3px;
			height: 100% !important;

			@include reset-position;

			.ui-slider-handle
			{
				bottom: 0;
				top: auto;
				width: 10px;
				left: -3.5px;
				height: 10px;
				border-width: 1px;

				@include opacity(1 !important);
				@include transform(translateY(5px));

				&.ui-state-active
				{
					border-color: darken($brand-primary, 5%);
				}
			}

			.ui-slider-handle, .ui-slider-range
			{
				background: $brand-primary;
			}
		}
	}
}

/* ----------  Waves  ---------- */

.waves-effect
{
	.waves-ripple
	{
		@include opacity(0.3);
	}
}

/* ----------  Malihu  ---------- */

.mCustomScrollbar
{
	.mCSB_scrollTools
	{
		z-index: 99;
	}
}

/* ----------  Resizer  ---------- */

.resizer
{
	display: none;
	height: 3px;
	width: 100%;
	background: $brand-gray-02;

	@include absolute-center(y, 99);

	.resize-handle
	{
		display: block;
		height: 20px;
		width: 100px;
		cursor: row-resize;
		text-align: center;
		background: $brand-gray-02;

		@include absolute-center;
		@include border-radius(5px);
		@include font-fa($font-sm, $weight-regular, 1, $white-color);

		.fa
		{
			@include absolute-center;
		}
	}

	&.resizer-active
	{
		display: block;
	}
}

/* ----------  Buttons  ---------- */

.btn, button
{
	&.disabled, &[disabled]
	{
		@include bg-color($brand-gray-04);
		@include text-color($black-color);
	}
}

/*----------  UK Switcher  ----------*/

.uk-switcher
{
	li
	{
		@include position;
	}
}

/*----------  Text  ----------*/

.text-truncate
{
	display: block;

	@include text-truncate;
}

/*----------  Containment  ----------*/

.panel-containment
{
	width: 100%;
	height: calc(100% - 300px);
	background: $brand-gray-01;

	@include opacity(0);
	@include absolute-center(y);
	@include pointer-events(none);
}

/*----------  Tour Uploader  ----------*/

.tour-uploader
{
	@include opacity(0);
	@include pointer-events(none);
	@include position(absolute, -1, 0, 0);
}

/*----------  Streetview Cursor  ----------*/

.streetview-cursor
{
	display: none;
	width: 15px;
	height: 15px;
	background: $brand-warning;
	border: 2px solid $white-color;

	@include opacity(0.7);
	@include border-radius(99px);
	@include position(absolute, 99, 0, 0);
	@include transform(translate(-50%, -50%));
}

/* ----------  Street View Images  ---------- */

.streetview-images-container
{
	width: 100%;
	height: 100%;

	@include pointer-events(none);
	@include position(absolute, 11, 0, 0);

	.stv-image
	{
		display: none;
		
		@include position(absolute, 5);
		@include transform(translate(-50%, -50%));

		&.image-center
		{
			@include transform(translate(-50%, -50%));
		}

		&.image-v-center
		{
			@include transform(translateY(-50%));
		}
		
		&.image-h-center
		{
			@include transform(translateX(-50%));
		}
	}
}

/*----------  Labels  ----------*/

.label
{
	color: $color-01;
	background: $brand-gray-01;
}

/*----------  Disabled  ----------*/

.disabled
{
	cursor: not-allowed !important;
}

/*----------  Icons  ----------*/

.fas
{
	font-weight: $weight-extrabold !important;
}

/* ----------  Misc  ---------- */

.uk-invisible
{
	@include opacity(0);
	@include pointer-events(none);
	@include position(absolute, 0, 0, 0);
}

/* ----------  STV Video Player  ---------- */

.stv-video-container
{
	height: 100%;

	.plyr
	{
		height: 100%;

		.plyr__video-wrapper
		{
			height: 100%;

			@include position;
			@include flex(column, center, center);

			.stv-player
			{
				padding: 0;
				width: 100%;
				margin: 0 auto;
				max-width: 100%;

				@include position(absolute, 1, 50%, 50%);
				@include transform(translate(-50%, -50%));
			}
		}
	}
}

/* ----------  Leg Captions  ---------- */

.leg-captions-container
{
	padding: 4px 8px;
	text-align: center;
	background: rgba($black-color, 0.8);

	@include transition;
	@include border-radius(4px);
	@include transform(translateX(-50%));
	@include position(absolute, 9999, $bottom: 20px, $left: 50%);

	span
	{
		@include font-p($font-sm, $weight-regular, 1.6, $white-color);
	}
}

/* ----------  Custom Alignment  ---------- */

.uk-custom-alignment-grids
{
	@include absolute-center(xy);
	@include pointer-events(none);

	.custom-alignment-grid
	{
		background: rgba($brand-gray-03, 0.4);

		@include position(absolute, 1, 0, 0);

		&.grid-x
		{
			height: 1px;
			width: 100%;
		}
		
		&.grid-y
		{
			width: 1px;
			height: 100%;
		}
	}
}

.uk-custom-alignment, .uk-custom-preview
{
	width: 500px;
	margin: auto;
	height: 200px;
	// overflow: hidden;
	background: $brand-gray-01;

	@include position(relative, 5);
	
	.alignment-crosshair
	{
		display: block;
		height: 15px;
		width: 15px;
		cursor: grab;

		@include position(absolute, 99, 0, 0);
		// @include transform(translate(-50%, -50%));

		*
		{
			@include pointer-events(none);
		}

		.crosshair-icon
		{
			width: 15px;
			height: 15px;
			background: $brand-gray-09;

			@include position;
			@include border-radius(50%);

			&:before
			{
				// content: '';
				width: 6px;
				height: 6px;
				background: $white-color;

				@include absolute-center;
				@include border-radius(50%);
			}
		}
	}

	&:before, &:after
	{
		content: '';
		background: $brand-gray-03;

		@include position(absolute);
	}

	&:before
	{
		left: 0;
		top: 50%;
		height: 1px;
		width: 100%;

		@include transform(translateY(-50%));
	}
	
	&:after
	{
		top: 0;
		left: 50%;
		width: 1px;
		height: 100%;

		@include transform(translateX(-50%));
	}
}

.custom-alignment-container
{
	.alignment-snap-guide
	{
		background: $brand-danger;

		@include opacity(0.4);
		@include position(absolute, 2, 0, 0);
		// @include transform(translate(-50%, -50%));

		&.guide-x
		{
			height: 1px;
			width: 100%;

			@include transform(translateY(-50%));
		}

		&.guide-y
		{
			width: 1px;
			height: 100%;

			@include transform(translateX(-50%));
		}
	}

	.uk-custom-alignment-toggle-groups
	{
		width: 100%;
		height: 100%;

		@include pointer-events(none);
		@include transform(translateX(-50%));
		@include position(absolute, 111, 0, 50%);

		.toggle-group
		{
			@include pointer-events(all);
			
			.crosshair-toggle
			{
				display: block;
				width: 15px;
				height: 15px;
		
				@include position;
				@include border-radius(50%);
		
				span
				{
					display: block;
					width: 5px;
					height: 5px;
					background: $brand-gray-09;
		
					@include absolute-center;
					@include border-radius(50%);
				}
			}
		}
	}
}

.uk-custom-preview
{
	overflow: hidden;
}

.uk-custom-snapboard, .uk-custom-alignment-ref
{
	cursor: grab;

	@include absolute-center(xy);
	@include pointer-events(none);
}

.uk-custom-snapboard
{
	.alignment-snap
	{
		width: 15px;
		height: 15px;

		@include border-radius(50%);
		@include position(absolute, 1);

		&:before
		{
			content: '';
			display: none;
			width: 16px;
			height: 16px;
			// background: $brand-danger;
			border: 1px solid $brand-danger;

			@include opacity(0.8);
			@include absolute-center;
			@include border-radius(50%);
		}

		&.snapped
		{
			&:before
			{
				display: block;
			}
		}
	}
}

/* ----------  Audio Visualizer  ---------- */

.audio-visualizer
{
	width: 100%;
	
	@include position;

	wave, canvas
	{
		max-width: none;
	}
}

/* ----------  No Touch  ---------- */

.ui-notouch
{
	@include pointer-events(none !important);
}
