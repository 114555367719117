/* ----------  Text Colors  ---------- */

@mixin text-color($color, $hover: false)
{
	color: $color !important;

	@if $hover == true
	{
		@include transition;

		&:hover, &:active, &:focus
		{
			color: darken($color, 10%) !important;
		}
	}
}