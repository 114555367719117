/* ----------  Scroll Buttons  ---------- */

.btn-section-scroll
{
	display: none;

	@include transition(box-shadow 0.3s linear);
	@include position(fixed, 999, $bottom: 20px, $right: 20px);
}

.btn-section-scroll.top
{
	top: 50px;
	bottom: auto;

	@media (max-width: $screen-xs-max)
	{
		top: 20px;
	}
}

/* ----------  Homepage Footer  ---------- */

.footer-container.footer-homepage
{
	background: $brand-light;
	border-top: 2px solid darken($brand-light, 5%);

	.footer-inner
	{
		padding: 15px 0;

		@include flex(row, center, space-between);
	}

	p, li, a
	{
		@include font-p($font-sm, $weight-regular, 1.5, $brand-dark);
	}

	ul.list-inline
	{
		li
		{
			a
			{
				font-weight: $weight-bold;

				&:hover, &:active, &:focus
				{
					color: $brand-primary;
				}
			}

			&:after
			{
				content: '|';
				margin: 0 6px;

				@include font-p(inherit, inherit, inherit, inherit);
			}

			&:last-child
			{
				&:after
				{
					display: none;
				}
			}
		}
	}
}
