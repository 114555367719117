/* ----------  Context Menu  ---------- */

.md-context-menu
{
	display: block;
	margin: 0;
	padding: 0;
	width: auto;
	padding: 5px 0;
	min-width: 180px;
	list-style-type: none;
	background: $white-color;

	@include border-radius(3px);
	@include position(fixed, 9999, auto, auto, auto, auto);
	@include box-shadow(0 3px 6px rgba($black-color, 0.16), 0 3px 6px rgba($black-color, 0.23));

	.menu-inner
	{
		// max-height: 300px;
	}

	li
	{
		display: block;
		width: 100%;

		@include position;

		a
		{
			display: flex;
			padding: 7px 10px;
			padding-left: 30px;
			flex-direction: row;
			align-items: center;
			text-decoration: none;

			@include transition(none);
			@include font-p($font-sm, $weight-regular, 1.1, $color-01);

			.item-icon
			{
				width: auto;
				height: auto;
				font-size: $font-md;
				color: $brand-gray-13;

				@include absolute-center(y)
				{
					left: 8px;
				}

				&.item-icon-fa
				{
					font-size: $font-sm;
				}

				&.item-icon-img
				{
					img
					{
						max-width: 15px;
					}
				}

				&.icon-rotate-0
				{
					@include transform(rotate(0deg) translateY(-50%));
				}
				
				&.icon-rotate-90
				{
					@include transform(rotate(90deg) translateX(-50%));
				}
				
				&.icon-rotate-180
				{
					@include transform(rotate(180deg) translateY(-50%));
				}
				
				&.icon-rotate-270
				{
					@include transform(rotate(270deg) translateX(50%));
				}
			}

			&:after
			{
				display: none;
				content: "\e5cc";
				font-size: $font-lg;
				font-family: 'Material Icons';

				@include transform(translateY(-50%));
				@include position(absolute, -1, $top: 50%, $right: 5px);
			}

			&:not(:only-child)
			{
				&:after
				{
					display: block;
				}
			}
		}

		.md-context-menu
		{
			@include position(absolute, 99, 0, calc(100% + 5px));

			@media (max-width: $screen-xs-max)
			{
				left: 70%;
				top: calc(100% + 10px);
			}
		}

		&.divider
		{
			margin: 6px 0;

			border-bottom: 1px solid $brand-gray-01;
			border-color: rgba($black-color, 0.16);
		}

		&.disabled
		{
			@include opacity(0.5);
			@include pointer-events(none);

			*
			{
				@include pointer-events(none);
			}
		}

		&:hover, &:active, &:focus
		{
			> a
			{
				text-decoration: none;
				background: $brand-gray-01;
			}
		}

		&:last-child
		{
			border: 0;
		}

		@media (max-width: $screen-xs-max)
		{
			@include position(static);
		}
	}

	&.md-context-menu-checklist
	{
		li
		{
			label
			{
				display: block;
				cursor: pointer;
				padding: 10px 15px;

				@include transition;
				@include font-p($font-sm, $weight-regular, 1.2, $color-01);

				span
				{
					@include font-p(inherit, inherit, inherit, inherit);
				}
			}

			&:hover, &:active, &:focus
			{
				> label
				{
					background: $brand-gray-11;
				}
			}
		}
	}

	&.position-left
	{
		ul
		{
			left: auto;
			right: calc(100% + 5px);

			@media (max-width: $screen-xs-max)
			{
				right: 70%;
			}
		}
	}
}
