/*----------  Perfect Scrollbar  ----------*/

.ps
{
	height: 100%;
	overflow: hidden;

	@include position;

	&.no-scroll-x
	{
		overflow-y: auto;
		overflow: visible;
		overflow-x: visible;
	}

	&.no-scroll-y
	{
		overflow-x: auto;
		overflow: visible;
		overflow-y: visible;
	}

	.ps__rail-x, .ps__rail-y
	{
		z-index: 99;

		.ps__thumb-x, .ps__thumb-y
		{
			cursor: pointer;
		}
	}
}
