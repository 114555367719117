/* ----------  Selectize Component  ---------- */

.md-select
{
	.selectize-control
	{
		.selectize-input
		{
			input
			{
				// @include placeholder($white-color);
			}

			.item
			{
				margin: 0;

				@include box-shadow(none);
			}

			&.input-active
			{
				@include pointer-events(none);
			}
		}

		.selectize-dropdown
		{
			margin: 0;
			top: calc(100% + 8px) !important;

			/* @include pointer-events(none);
			
			*
			{
				@include pointer-events(none);
			} */

			.selectize-dropdown-content
			{
				width: 100%;
				max-height: 500px;
				
				@include pointer-events(auto);
				@include flex(column, flex-start, flex-start);

				.md-selectize-item
				{
					order: 1;
					padding: 0;
					width: 100%;

					// @include pointer-events(none);

					.item-label
					{
						display: block;
						margin: 0;
						cursor: pointer;
						padding: 10px 8px;

						@include position;
						@include inline-flex;
						@include font-p($font-sm, $weight-regular, 1.1, $color-01);

						&:hover, &:active, &:focus
						{
							@include bg-color(rgba($black-color, 0.085));
						}
					}

					.item-input
					{
						padding: 0;
						margin-right: 10px;

						@include position;

						span
						{
							display: block;
						}

						.input-field
						{
							@include opacity(0);
							@include pointer-events(none);
							@include position(absolute, -1);
						}

						.input-icon
						{
							width: 18px;
							height: 18px;
							border: 2px solid rgba($black-color, 0.5);

							@include border-radius(2px);
							@include transition(all 0.2s ease-out);
						}

						.input-field:checked + .input-icon
						{
							background: $brand-primary;
						}
					}

					.item-text
					{
						flex: 1;
					}

					&.priority-low
					{
						order: 999;
					}

					&.item-licensed
					{
						.item-label
						{
							.item-input
							{
								.icheckbox_md
								{
									border-color: $brand-warning !important;

									&.checked, &.hover.checked
									{
										background: $brand-warning !important;
										border-color: $brand-warning !important;
									}
								}
							}
						}
					}

					&.item-has-indent
					{
						$baseIndent: 15px;

						@for $i from 0 through 10
						{
							$padding: $i * $baseIndent;

							&.indent-lvl-#{$i}
							{
								padding-left: $padding;
							}
						}
					}

					&.item-has-divider
					{
						&.divider-top
						{
							border-top: 1px solid $brand-gray-03;
						}
						
						&.divider-bottom
						{
							border-bottom: 1px solid $brand-gray-03;
						}

						&.divider-both
						{
							border-top: 1px solid $brand-gray-03;
							border-bottom: 1px solid $brand-gray-03;
						}
					}

					&.item-special
					{
						@include pointer-events(auto);

						*
						{
							@include pointer-events(auto);
						}
					}

					&.active, &.selected, &:hover, &:active, &:focus
					{
						background: none;
					}
				}
			}
		}

		&.single
		{
			margin: 0;
		}
	}
}

.md-selectize
{
	.selectize-control
	{
		.selectize-input
		{
			[data-value]
			{
			}

			.item
			{
				margin: 0;
				border: 0;
				height: auto;
				color: $color-01;

				@include transition;
				@include inline-flex;
				@include box-shadow(none);

				> a
				{
					display: block;
					height: auto;
					padding: 0 !important;
				}

				span
				{
					display: block;
				}

				.item-toggle
				{
					margin-right: 5px;

					.toggle-icon
					{
						&.icon-static
						{
							display: block;
						}

						&.icon-active
						{
							display: none;
						}
					}
				}

				.remove
				{
					width: 25px;
					color: $color-01;

					@include box-shadow(none);

					&:after
					{
						color: inherit;

						@include absolute-center;
					}

					&:hover, &:active, &:focus
					{
						background: rgba($black-color, 0.1);
					}
				}

				&.selected
				{
					color: $white-color;
					background: $brand-primary;

					.item-toggle
					{
						.toggle-icon
						{
							&.icon-static
							{
								display: none;
							}

							&.icon-active
							{
								display: block;
							}
						}
					}

					.remove
					{
						color: $white-color;
					}

					&:hover, &:active, &:focus
					{
						background: $brand-primary;
					}
				}
			}

			&:after
			{
				right: 20px;
			}
		}

		.selectize-dropdown
		{
			.selectize-dropdown-content
			{
				.option
				{
					padding-right: 8px !important;

					.item-icon
					{
						margin-right: 5px;
					}
				}
			}
		}

		&.single
		{
			margin: 0;
			height: auto;
			min-height: 40px;

			.selectize-input
			{
				padding-left: 0;
				padding-right: 0;
			}
		}

		&.multi
		{
			.selectize-input
			{
				min-height: 50px;
				line-height: normal;

				@include flex(row, center);

				.item
				{
					padding-top: 7px;
					margin-right: 3px;
					margin-bottom: 3px;
					padding-bottom: 7px;

					@include border-radius(5px);
				}
			}
		}
	}

	&.language-selectize
	{
		.selectize-control
		{
			min-height: auto;

			.selectize-input
			{
				border: 0;
				padding: 0;
				min-width: 50px;
				background: none;
				min-height: auto;

				> input
				{
					display: none !important;
				}

				&:after
				{
					margin-top: -14px !important;
				}
			}

			.selectize-dropdown
			{
				left: auto !important;
				right: -40px !important;
				min-width: 180px !important;
			}
		}
	}
}