/* ----------  Tours Container  ---------- */

.tours-container
{
	overflow: hidden;

	@include position;
	@include transition;

	.md-btn-cancel
	{
		// @include opacity(0);
		// @include transition;
		// @include pointer-events(none);
		@include absolute-center(xy, 22);

		a
		{
			display: block;

			@include font($font-xxl, $weight-regular, 1, lighten($color-01, 10%));

			i
			{
				width: auto;
				height: auto;
				color: inherit;
				font-size: inherit;
				line-height: inherit;
			}
		}
	}

	.md-overlay
	{
		display: none;
		width: 100%;
		height: 100%;
		cursor: not-allowed;

		// @include opacity(0);
		// @include transition;
		// @include pointer-events(none);
		@include position(absolute, 11, 0, 0);
		@include faded-background($brand-gray-01, 0.7);
	}

	.md-card
	{
		padding: 0;
		height: 100%;
		overflow: auto;

		@include position;

		.md-card-content
		{
			padding: 14px 16px;

			.card-content-inner
			{
				min-width: 700px;

				@include clearfix;

				@media (max-width: $screen-sm-max)
				{
					min-width: 840px;
				}
			}

			.tour-container
			{
				float: left;
				width: 50%;

				&:nth-child(1)
				{
					z-index: 11;
				}

				&:nth-child(2)
				{
					z-index: 5;
				}
			}
		}
	}

	.uk-tab-grid
	{
		margin: 0;
	}

	.tour-container
	{
		margin-bottom: 0;
		// margin-right: -1px;

		@include position;

		.ui-resizable-e
		{
			right: 0;
		}

		.mCustomScrollbar
		{
			height: 100%;
			max-height: 100%;
		}

		.tour-inner
		{
			height: 100%;
			overflow: hidden;
			
			border: 1px solid $brand-gray-03;
			border-top: 0;

			@include position;
		}

		.tour-header
		{
			padding: 15px 10px;
			padding-right: 55px;
			background: $brand-primary;

			@include position(relative, 99);

			h3
			{
				letter-spacing: 1px;
				text-transform: uppercase;
				
				@include font-p(rem-calc(25), $weight-bold, 1.2, $white-color);
			}

			.header-loader
			{
				@include absolute-center;

				.fa
				{
					@include font-fa($font-base, $weight-regular, 1, $white-color);
				}
			}

			.header-contents
			{
				@include inline-flex(stretch);

				.header-dropdown
				{
					flex: 1;
				}

				&.disabled
				{
					@include opacity(0.7);
					@include pointer-events(none !important);

					*
					{
						@include pointer-events(none !important);
					}
				}
			}

			.header-action
			{
				a
				{
					height: 100%;
					padding: 0 10px;

					@include flex(center, center);

					i
					{
						@include font($font-lg, $weight-regular, 1, $white-color);
					}
				}
			}

			.header-search
			{
				width: 100%;
				height: 100%;

				@include pointer-events(none);
				@include position(absolute, 11, 0, 0);

				form
				{
					height: 100%;
					padding: 0 10px;

					@include position;
					@include inline-flex;

					.search-btn
					{
						@include transition;
						@include position(relative, 11);

						.btn-search
						{
							display: block;
							border: 0;
							padding: 0;
							width: 35px;
							height: 35px;
							background: none;

							@include position;
							@include opacity(0);
							@include transition;
							@include border-radius(99px);
							@include pointer-events(auto);
							@include font(rem-calc(24), $weight-regular, 1, $white-color);

							i
							{
								@include absolute-center;
								@include font(inherit, inherit, inherit, inherit);
							}

							&:hover, &:active, &:focus
							{
								@include bg-color($white-color);
								@include text-color($brand-primary);
							}
						}
					}

					.search-input
					{
						flex: 1;
						margin-right: 10px;

						@include opacity(0);
						@include transition;
						@include pointer-events(none);
						@include position(relative, 11);
						@include transform(translateY(10px));

						.md-input-wrapper
						{
							label, .md-input
							{
								@include text-color($white-color);
							}

							.md-input
							{
								border-color: $white-color;
								border-color: rgba($white-color, 0.7);
							}

							.md-input-bar
							{
								&:before, &:after
								{
									background: $white-color;
								}
							}
						}
					}

					&.search-active
					{
						background: $brand-primary;

						.search-input
						{
							@include opacity(1);
							@include pointer-events(auto);
							@include transform(translateY(0));
						}

						.search-btn
						{
							.btn-search
							{
								@include opacity(1);
							}
						}
					}
				}
			}

			.header-title
			{
				text-align: center;
				margin-bottom: 15px;
			}

			.header-user
			{
				text-align: center;
				margin-bottom: 20px;

				@include position(absolute, 5, 15px, 15px);

				.user-inner
				{
					width: 100%;
					height: 100%;
					overflow: hidden;
					
					@include position;
				}

				.user-avatar
				{
					img
					{
						display: block;
						width: 60px;
						height: 60px;
						margin: auto;

						@include border-radius(99px);
					}
				}

				.user-action
				{
					width: 50px;
					height: 50px;

					@include opacity(0);
					@include transition;
					@include absolute-center;

					a
					{
						display: block;
						height: 100%;
						width: 100%;

						@include position;
						@include border-radius(99px);
						@include faded-background($brand-primary, 0.7);

						i
						{
							@include absolute-center;
							@include font($font-lg, $weight-regular, 1, $white-color);

							&.icon-lg
							{
								font-size: rem-calc(30);
							}
						}
					}
				}

				@media (max-width: $screen-xs-max)
				{
					@include reset-position;
				}
			}

			.header-dropdown
			{
				text-align: left;

				@include transition;
				@include position(relative, 11);

				.selectize-control
				{
					.selectize-input
					{
						background: none;
						color: $white-color;
						border-color: $white-color;

						@include text-truncate;

						input
						{
							@include placeholder($white-color);
						}

						&:after
						{
							color: $white-color;
						}
					}
				}

				.md-filter-tree
				{
					@include position(relative, 11);

					.filter-tree-input
					{
						a
						{
							height: 44px;
							padding: 8px;
							border-bottom: 1px solid $white-color;

							@include position;
							@include flex(column, flex-start, center);
							@include font-p(rem-calc(15), $weight-regular, 1.2, $white-color);

							&:after
							{
								content: '\e5c5';
								font-family: "Material Icons";

								@include font(rem-calc(28), $weight-regular, 1, $white-color);
								@include absolute-center(y)
								{
									right: 0;
									left: auto;
								}
							}
						}
					}

					.filter-tree-list
					{
						display: none;
						width: 100%;
						background: $white-color;

						@include position(absolute, 11, calc(100% + 5px), 0);
						@include box-shadow(0 3px 6px rgba($black-color, 0.16), 0 3px 6px rgba($black-color, 0.23));

						.filter-atn
						{
							a
							{
								padding: 10px 8px;
								border-bottom: 1px solid $brand-gray-02;

								@include inline-flex;
								@include transition(none);
								@include font-p(rem-calc(15), $weight-regular, 1.2, $color-01);

								&:hover, &:active, &:focus
								{
									background: rgba(0, 0, 0, 0.085);
								}
							}
						}

						.filter-tree
						{
							padding-top: 10px;
						}

						.filter-sorts
						{
							border-top: 1px solid $brand-gray-03;

							.sort-item
							{
								.item-label
								{
									display: block;
									cursor: pointer;
									padding: 10px 8px;

									@include position;
									@include inline-flex;

									&:hover, &:active, &:focus
									{
										@include bg-color(rgba(0, 0, 0, 0.085));
									}
								}

								.item-input
								{
									padding: 0;
									margin-right: 10px;
								}
							}
						}
					}
				}

				&.has-publisher
				{
					padding-left: 40px;
				}
			}

			.header-publisher
			{
				width: 50px;
				text-align: center;

				@include position(absolute, 11, $bottom: 15px, $left: 0);

				a
				{
					display: block;
					padding: 10px 0;
					text-align: center;
					color: $white-color;

					i
					{
						margin: auto;
					}

					.icon-static
					{
						display: block;
					}

					.icon-active
					{
						display: none;
					}

					&.published-all
					{
						.icon-static
						{
							display: none;
						}

						.icon-active
						{
							display: block;
						}
					}

					&:hover, &:active, &:focus
					{
						color: $brand-gray-01;
					}
				}

				i, span
				{
					color: inherit;
					font-size: rem-calc(30);
					
					@include transition;

					@media (max-width: $screen-xs-max)
					{
						font-size: rem-calc(24);
					}
				}
			}
		}

		.tour-content
		{
			overflow: hidden;
			height: calc(100vh - 150px);
			min-height: calc(100vh - 150px);

			@include flex;
			@include position;

			.tour-title
			{
				@include transition;

				.title-inner
				{
					padding: 10px;
					padding-right: 5px;

					@include inline-flex(center, space-between);
				}

				h3
				{
					@include font-p($font-lg, $weight-regular, 1.2, $color-01);
				}

				.title-icon
				{
					margin-right: 7px;

					i
					{
						@include font(rem-calc(30), $weight-regular, 1, $color-01);
					}
				}

				.title-contents
				{
					@include inline-flex;
				}

				.title-actions
				{
					@include opacity(0);
					@include transition;

					ul
					{
						li
						{
							a
							{
								display: block;
								
								.material-icons
								{
									font-size: rem-calc(22);

									@media (max-width: $screen-xs-max)
									{
										font-size: rem-calc(20);
									}
								}
							}
						}
					}
				}

				&.ctx-active, &:hover, &:active, &:focus
				{
					background: $brand-gray-01;
				}

				&:hover, &:active, &:focus
				{
					.title-actions
					{
						@include opacity(1);
					}
				}
			}

			&:before
			{
				// content: '';
				width: 100%;
				height: 100%;

				@include opacity(0);
				@include transition;
				@include pointer-events(none);
				@include transition-delay(0.2s);
				@include position(absolute, -1, 0, 0);
				@include faded-background($black-color, 0.3);
			}
		}

		.tour-lists
		{
			flex: 1;
		}

		.tour-list + .tour-list
		{
			border-top: 1px solid $brand-gray-03;
		}

		.tour-panel-outer
		{
			border: 10px solid transparent;

			@include pointer-events(none);
			@include position(absolute, 99, 0, 0, 0, 0);

			.tour-panel
			{
				padding: 0 !important;
			}
		}

		&:before
		{
			// content: '';
			width: 100%;
			height: 100%;

			@include opacity(0);
			@include transition;
			@include pointer-events(none);
			@include transition-delay(0.2s);
			@include position(absolute, -1, 0, 0);
			@include faded-background($black-color, 0.3);
		}

		&.panel-disabled, &.dnd-disabled
		{
			*
			{
				@include pointer-events(none !important);
			}

			.tour-content
			{
				&:before
				{
					@include opacity(1);
					@include transition-delay(0s);
				}
			}
		}

		&.panel-active
		{
			.tour-header, .tour-list
			{
				@include pointer-events(none !important);

				*
				{
					@include pointer-events(none !important);
				}
			}

			&.panel-active-sequence
			{
				.tour-panel.tour-sequence-panel
				{
					// @include opacity(1);
					// @include pointer-events(auto);
					// @include transition-delay(0.2s);
					// @include transform(translateY(0) scale(1));

					&.enabled-dragging
					{
						@include transition(none);
						@include transition-delay(0s);
					}

					&.ui-resizable-resizing
					{
						@include transition(none);
					}
				}
			}

			&:before
			{
				@include opacity(1);
				@include transition-delay(0s);
				@include pointer-events(none);
			}
		}

		&.filter-search-active
		{
			.tour-header 
			{
				.header-dropdown
				{
					@include opacity(0);
					@include pointer-events(none);
				}
			}
		}

		&:hover:not(.panel-active), &:active:not(.panel-active), &:focus:not(.panel-active)
		{
			.tour-header
			{
				.header-user
				{
					.user-action
					{
						@include opacity(1);
					}
				}

				.header-search
				{
					.search-btn
					{
						.btn-search
						{
							@include opacity(1);
						}
					}
				}
			}
		}
	}

	.tours-btn-container
	{
		text-align: center;
		padding-bottom: 20px;

		.md-btn
		{
			display: block;
			padding: 0;
			width: 80px;
			height: 80px;
			margin: auto;
			background: $white-color;

			@include position;
			@include border-radius(99px);

			.btn-icon
			{
				margin: 0;
				font-size: $font-xl;

				@include absolute-center;
			}
		}
	}

	&.disabled
	{
		cursor: not-allowed;

		// .md-btn-cancel, .md-overlay
		// {
		// 	// @include opacity(1);
		// }

		// .md-btn-cancel
		// {
		// 	// @include pointer-events(auto);
		// }

		.md-card
		{
			@include user-select(none !important);
			@include pointer-events(none !important);

			*
			{
				@include user-select(none !important);
				@include pointer-events(none !important);
			}
		}
	}

	&.ui-dragging
	{
		.tour-container
		{
			.tour-inner
			{
				overflow: visible;
			}
		}
	}
}

/* ----------  Sequence List  ---------- */

.tour-sequence-editor
{
	.editor-droppable-indicator
	{
		width: 100%;
		height: 100%;
		border: 2px dashed $brand-warning;

		@include opacity(0);
		@include transition;
		@include pointer-events(none);
		@include position(absolute, 99, 0, 0);
		@include faded-background($brand-warning, 0.2);

		span
		{
			letter-spacing: 1px;
			text-transform: uppercase;

			@include absolute-center;
			@include font-p($font-lg, $weight-bold, 1.2, $brand-warning);
		}
	}

	.editor-loader
	{
		width: 100%;
		height: 100%;
		background: $brand-gray-01;

		@include position(absolute, 111, 0, 0);

		.loader-icon
		{
			@include absolute-center;
			@include font-fa($font-xl, $weight-regular, 1, $brand-gray-02);
		}
	}

	&.ui-droppable-active
	{
		.editor-droppable-indicator
		{
			@include opacity(1);
		}
	}
}

.sequence-list
{
	display: block;
	list-style: none;

	@include position;

	.dd-empty
	{
		display: none;

		@include opacity(0);
		@include pointer-events(none);
		@include position(absolute, 0, 0, 0);
	}
}

.sq-list, .sq-dragable, .dd-dragel, .dd-list
{
	display: block;
	margin: 0;
	padding: 0;
	list-style: none;

	@include position;

	*
	{
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	.sq-placeholder, .dd-placeholder
	{
		display: block;
		min-height: 35px;
		border: 1px dashed $brand-warning;
		background: lighten($brand-warning, 40%);

		@include position;
	}

	.sq-item
	{
		display: block;
		border-bottom: 1px solid $brand-gray-03;
		
		@include position;
		@include font-p($font-sm, $weight-regular, 1.3, $color-01);

		i, span
		{
			display: block;

			@include font(inherit, inherit, 1, inherit);
		}

		button
		{
			@include opacity(0);
			@include position(absolute);
			@include pointer-events(none);
		}

		.item-inner
		{
			padding: 6px 10px;
			// padding-left: 35px;
		}

		.item-inner, .item-group
		{
			flex: 1;

			@include position;
			@include inline-flex();
		}

		.item-group
		{
			border-bottom: 1px solid $brand-gray-03;
		}

		.item-handle
		{
			border: 0;
			margin: 0;
			padding: 0;
			width: 35px;
			cursor: move;
			height: 100%;
			background: none;
			margin-right: 5px;
			line-height: auto;
			font-size: $font-sm;

			@include box-shadow(none);
			@include position(absolute, 5, 0, 0);

			i
			{
				@include absolute-center;
			}

			&:hover, &:active, &:focus
			{
				background: none;

				@include box-shadow(none);
			}
		}

		.item-icon
		{
			font-size: $font-lg;

			@include opacity(0.7);

			img
			{
				max-width: 20px;
				max-height: 20px;
			}
		}

		.item-upload
		{
			@include opacity(0);
			@include pointer-events(none);
			@include position(absolute, 0, 0, 0);
		}

		.item-title
		{
			flex: 1;
			border: 0;
			margin: 0;
			height: auto;
			padding: 0 7px;
			background: none;
			padding-top: 4px;
			word-break: break-all;
			word-wrap: break-word;
			overflow-wrap: break-word;

			@include text-truncate;

			.title-text
			{
				display: block;
				margin-bottom: 3px;
				
				&:last-child
				{
					margin: 0;
				}
			}
		}

		.item-actions
		{
			@include opacity(0);
			@include transition;
			@include inline-flex;

			.atn
			{
				padding: 3px;
				font-size: $font-base;

				@include link;
				@include opacity(0.7);
			}
		}

		.dd-list
		{
			.dd-item
			{
				margin-left: 15px;
			}

			.dd-placeholder
			{
				margin-left: 15px;
			}
		}

		&.sq-group-item
		{
			border-bottom: 0;
		}

		&.sq-music-item, &.sq-image-item, &.sq-group-item
		{
			> .item-inner
			{
				padding-left: 10px;
			}
		}
		
		&.selected
		{
			> .item-inner
			{
				@include faded-background($brand-warning, 0.2);
			}

			&:hover, &:active, &:focus
			{
				> .item-inner
				{
					@include faded-background($brand-warning, 0.2);
				}
			}
		}
		
		&.disabled
		{
			@include opacity(0.6);
			@include pointer-events(none);
		}

		&.keep-active, &.ctx-active, &:hover, &:active, &:focus
		{
			> .item-inner, > .item-group
			{
				background: $brand-gray-01;
				
				> .item-actions
				{
					@include opacity(1);
				}
			}

			> .item-group
			{
				> .item-inner
				{
					> .item-actions
					{
						@include opacity(1);
					}
				}
			}
		}
	}
}

.dd-dragel
{
	@include position(absolute, 999);

	.dd-item
	{
		background: $brand-gray-01
	}
}

/* ----------  MD Draggable  ---------- */

.md-jstree-draggable
{
	.jstree-node
	{
		.jstree-anchor
		{
			.node-icon, .node-actions, .node-loader, .node-notif
			{
				display: none;
			}

			.node-title
			{
				display: block;
			}
		}
	}
}

/* ----------  Notif  ---------- */

.notif-disable
{
	.md-tree.jstree .jstree-children .jstree-node.jstree-has-notif >.jstree-anchor .node-notif
	{
		display: none !important;
	}
}

/* ----------  Tour Panel  ---------- */

.tour-panel
{
	width: 100%;
	height: calc(100% - 10px);
	border: 1px solid $brand-gray-03;
	
	@include md-default-shadow;
	@include position(absolute, 99, $bottom: 0, $left: 0);

	.panel-inner
	{
		width: 100%;
		height: 100%;
		// padding-top: 40px;
		// padding-bottom: 40px;
		background: $white-color;

		@include flex;
		@include position;
		@include md-default-shadow;
	}

	h3
	{
		@include font-s($font-base, $weight-bold, 1.2, $brand-primary);
	}

	ul, ol
	{
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.mixer-media-player
	{
		width: 100%;

		@include position(absolute, 99, $bottom: 0, $left: 0);

		.btn-close
		{
			@include position(absolute, 5, $top: 10px, $right: 10px);
		}

		.media-player
		{
			.aplayer-body
			{
				.aplayer-info
				{
					.aplayer-music
					{
						.aplayer-author
						{
							display: none;
						}
					}
				}
			}
		}
	}

	.editor-history-panel
	{
		width: 100%;
		height: 100%;
		overflow: hidden;

		@include pointer-events(none);
		@include position(absolute, 22, 0, 0);
		// @include faded-background($white-color, 0.7);

		.history-panel-inner
		{
			height: 100%;
		}

		.history-panel-content
		{
			width: 100%;
			height: 100%;
			overflow: hidden;
			background: $white-color;

			@include pointer-events(all);
			@include flex(column, normal, normal);
		}

		.history-panel-list
		{
			flex: 1;
		}

		&.mixer-active
		{
			.history-panel-content
			{
				width: 300px;

				.history-panel-list
				{
					width: 300px;
				}
			}
		}
	}
	
	.editor-panel
	{
		width: 100%;
		min-height: 100%;
		background: $white-color;
		
		// @include position(absolute, 11, 0, 0);
		
		@include flex {
			flex-wrap: initial;
		};

		.editor-content
		{
			flex: 1;
		}
	}

	.mixer-media-snappings-container
	{
		height: 100%;
		width: calc(100% - 50px);

		@include pointer-events(none);
		@include transform(translateX(-50%));
		@include position(absolute, 9999, 0, 50%);

		.media-snap
		{
			width: 1px;
			height: 100%;

			@include position(absolute, 5, 0, 0);
			// @include transform(translateX(-50%));
		}
	}

	.mixer-reference-container
	{
		width: 100%;
		height: 1px;
		padding: 0 25px;

		@include opacity(0);
		@include pointer-events(none);
		@include position(absolute, -1, 0, 0);

		.mixer-reference
		{
			width: 100%;
			height: 1px;
		}
	}
	
	.editor-mixer-outer
	{
		flex: 1;
		// padding: 0 25px;
		max-width: 100%;
		min-height: 100%;
		overflow: hidden;

		@include position;

		@include flex {
			flex-wrap: initial;
		};
	}

	.editor-video
	{
		width: 20px;
		height: calc(100% - 10px);
		border: 2px solid $brand-gray-07;

		@include border-radius(3px);
		@include position(absolute, 2, 50%);
		@include transform(translateY(-50%));

		.atn
		{
			@include opacity(0);
			@include position(absolute, 5, 50%, 50%);
			@include transform(translate(-50%, -50%));
		}

		&:before
		{
			content: '';
			height: 30%;
			width: calc(100% - 4px);
			background: $brand-gray-07;

			@include transition;
			@include transform(translateX(-50%));
			@include position(absolute, 1, 2px, 50%);
		}

		&.video-header
		{
			left: 0;
		}

		&.video-footer
		{
			right: 0;

			&:before
			{
				top: auto;
				bottom: 2px;
			}
		}

		&:hover, &:active, &:focus
		{
			.atn
			{
				@include opacity(1);
			}

			&:before
			{
				@include opacity(0);
			}
		}
	}

	.editor-tree.jstree.jstree-default
	{
		// padding: 10px 0;

		.jstree-icon, .jstree-icon
		{
			display: none !important;
		}

		.jstree-node
		{
			display: block;
			margin: 0;
			
			.jstree-children
			{
				padding-left: 15px;
			}

			&:hover, &:active, &:focus, &[aria-selected="true"]
			{
				> .jstree-anchor
				{
					@include bg-color($brand-gray-01);

					.anchor-actions
					{
						@include opacity(1);
					}
				}
			}
		}

		.jstree-anchor
		{
			display: block;
			height: auto;
			padding: 0;
			margin: 0;

			@include font-p($font-base, $weight-regular, 1.3, $color-01);

			i, span
			{
				display: block;

				@include font(inherit, inherit, 1, inherit);
			}

			.anchor-inner
			{
				min-height: 35px;
				padding: 6px 10px;
				border-bottom: 1px solid $brand-gray-03;

				@include inline-flex;
			}

			.anchor-icon
			{
				font-size: $font-lg;

				@include opacity(0.7);

				img
				{
					max-width: 20px;
					max-height: 20px;
				}
			}

			.anchor-title
			{
				flex: 1;
				padding: 0 4px;
			}

			.anchor-actions
			{
				@include opacity(0);
				@include transition;
				@include inline-flex;

				.atn
				{
					padding: 3px;
					font-size: $font-base;

					@include link;
					@include opacity(0.7);
				}
			}

			&.jstree-hovered, &.jstree-clicked
			{
				background: none;
			}
		}
	}

	.editor-mixer
	{
		flex: 1;
		min-height: 100%;

		@include flex {
			flex-wrap: initial;
		};

		.mixer-inner
		{
			flex: 1;
			// padding-top: 44px;
			// padding-bottom: 35px;

			@include flex;
			@include position;
		}

		.mixer-history-actions
		{
			border-left: 1px solid $brand-gray-03;

			ul
			{
				li
				{
					a
					{
						display: block;
						height: 44px;
						padding: 5px 15px;
						text-align: center;
						letter-spacing: 0.7px;
						text-transform: uppercase;
						// background: $brand-gray-01;
						border-right: 1px solid $brand-gray-03;

						@include inline-flex;
						@include font-p($font-sm, $weight-bold, 1.1, $brand-gray-02);

						i, span
						{
							margin-right: 7px;

							@include font-inherit;
						}
					}

					&.atn-disabled
					{
						@include pointer-events(none);

						a
						{
							color: rgba($brand-gray-02, 0.6);
						}
					}

					&:hover, &:active, &:focus
					{
						> a
						{
							background: $brand-gray-01;
						}
					}
				}
			}
		}

		.mixer-geo-thumbnails
		{
			.geo-thumbnail
			{
				width: 80px;
				padding: 4px;
				height: 60px;
				font-style: italic;
				background: $brand-dark;

				@include border-radius(4px);
				@include position(fixed, 999, 0, 0);
				@include flex(column, center, center);
				@include font-p($font-xs, $weight-regular, 1.2, $white-color);

				img
				{
					display: block;
					height: auto;
					width: 100%;

					@include position;
					@include border-radius(4px);
				}

				&:before
				{
					display: none;
					content: 'Loading...';
					font-style: italic;
					
					@include absolute-center('xy', -1);
					@include font-p($font-xs, $weight-regular, 1.2, $white-color);
				}
			}
		}

		.media-snap-line
		{
			display: none;
			height: 100%;
			width: 1px;
			background-color: $brand-gray-09;

			@include pointer-events(none);
			@include position(absolute, 11, 0, 0);

			&.snapped
			{
				background-color: $brand-danger !important;
			}
		}

		.media-uploader
		{
			width: 100%;
			height: 45px;
			flex: 0 0 45px;
			padding: 0 25px;
			border-bottom: 1px solid $brand-gray-03;

			@include position(absolute, 2, 0, 0);

			.uploader-inner
			{
				width: 100%;
				height: 100%;

				@include position;
			}

			.uploader-input
			{
				width: 100%;
				height: 100%;
				cursor: pointer;

				@include opacity(0);
				@include position(absolute, 1, 0, 0);
			}

			&:before
			{
				content: '';
				width: 100%;
				height: 100%;

				@include transition;
				@include opacity(0.6);
				@include pattern('funky-lines');
				@include position(absolute, 1, 0, 0);
			}

			&:hover
			{
				&:before
				{
					@include opacity(1);
				}
			}
		}

		.mixer-media
		{
			@include position;

			.media-uploads
			{
				width: 100%;
				height: 100%;

				@include pointer-events(none);
				// @include position(absolute, 5, 0, 0);
			}

			.media-upload
			{
				width: 50px;
				height: 36px;
				cursor: grab;
				overflow: hidden;
				background-color: $brand-gray-07;

				@include user-select(none);
				@include border-radius(4px);
				@include pointer-events(all);
				// @include transform(translateY(-50%));
				@include position(absolute, 2, 4px, 0);

				span, a
				{
					display: block;
				}

				.upload-loops
				{
					width: 100%;
					height: 100%;
					padding: 7px 0;

					@include inline-flex;
					@include position(absolute, -1, 0, 0);

					span
					{
						height: 100%;
						background: none;
						border-right: 1px dotted $white-color;
					}
				}

				.upload-resize-handle, .ui-resizable-handle
				{
					width: 5px;
					height: 100%;
					cursor: e-resize;
					background: $white-color;

					@include opacity(0);
					@include position(absolute, 5, $top: 0, $right: 0);
				}

				.upload-drag-handle
				{
					@include opacity(0);
					@include transform(translateY(-50%));
					@include position(absolute, 7, 50%, 0);

					.handle-icon
					{
						@include font($font-base, $weight-regular, 1, $white-color);
					}
				}

				.upload-loop-count
				{
					@include inline-flex;
					@include transform(translateY(-50%));
					@include position(absolute, 6, $top: 50%, $right: 22px);
					@include font-p($font-sm, $weight-regular, 1, $white-color);

					i, span, .material-icons
					{
						@include font-inherit;
					}
				}

				.upload-ctx
				{
					@include opacity(0);
					@include transition(none);
					@include transform(translateY(-50%));
					@include position(absolute, 7, $top: 50%, $right: 6px);

					i, span, .material-icons, .ctx-icon
					{
						@include font($font-base, $weight-regular, 1, $white-color);
					}
				}

				&:before
				{
					content: '';
					height: 100%;
					width: 100%;
					background: linear-gradient(
						-45deg,
						transparent 35%,
						rgba($white-color, 0.3) 35%,
						rgba($white-color, 0.3) 70%,
						transparent 70%
					);

					background-size: 75px 100%;

					@include opacity(0);
					// @include transition;
					@include position(absolute, -1, 0, 0);
				}

				&.active
				{
					&:before
					{
						@include opacity(1);
					}

					&.playing
					{
						&:before
						{
							@include animation(0s, 2s, uploadBusy);
						}
					}
				}

				@keyframes uploadBusy {
					0% {
						background-position: 0 0;
					}
					100% {
						background-position: 75px 100%;
					}
				}

				&.media-light
				{
					.upload-loops
					{
						span
						{
							border-right-color: $black-color;
						}
					}

					.upload-resize-handle, .ui-resizable-handle
					{
						background: $black-color;
					}
					
					.upload-drag-handle
					{
						.handle-icon
						{
							color: $black-color;
						}
					}

					.upload-ctx
					{
						.ctx-icon
						{
							color: $black-color;
						}
					}

					&:before
					{
						background: linear-gradient(
							-45deg,
							transparent 35%,
							rgba($black-color, 0.3) 35%,
							rgba($black-color, 0.3) 70%,
							transparent 70%
						);

						background-size: 75px 100%;
					}
				}

				&.show-resize
				{
					.upload-resize-handle, .ui-resizable-handle
					{
						@include animation(0s, 1s, alert);
					}
				}

				&.media-locked
				{
					cursor: not-allowed;

					@include filter(grayscale(100%));

					.upload-resize-handle, .ui-resizable-handle
					{
						display: none;
					}
				}

				&:hover
				{
					.upload-ctx, .upload-drag-handle
					{
						@include opacity(1);
					}

					.upload-resize-handle, .ui-resizable-handle
					{
						@include opacity(0.3);
					}
				}
			}
		}

		.item-slab
		{
			width: 100%;
			height: 100%;

			@include position;
		}

		.mixer-media-timeline
		{
			min-height: 45px;

			@include position;
			@include flex {
				flex-wrap: initial;
			};

			.media-timeline-inline
			{
				width: 100%;
				height: 45px;
				flex: 0 0 45px;
				padding: 0 25px;
				border-bottom: 1px solid $brand-gray-03;

				@include pointer-events(none);
				@include position(relative, 5);
				// @include position(absolute, 5, 0, 0);

				.timeline-inner
				{
					width: 100%;
					height: 100%;

					@include pointer-events(none);
					@include position(relative, 5);
				}
			}

			.media-timeline-list
			{
				flex: 1;
				width: 100%;
				padding: 7px 0;
				overflow: hidden;

				.timeline-list-inner
				{
					overflow: hidden;
				}

				.timeline-slab
				{
					height: 35px;
					padding: 0 25px;
					border-bottom: 1px solid $brand-gray-03;

					@include position;

					.media-upload
					{
						top: 2px;
						height: 30px;
					}
				}
			}

			&.image-timeline
			{
				border-top: 1px solid $brand-gray-03;
			}
		}

		.mixer-leg-timeline
		{
			// flex: 1;

			@include flex;
			@include position;

			.timeline-slab
			{
				display: block;
				width: 150px;
				height: 30px;
				background: $brand-gray-07;

				@include position;
				@include border-radius(4px);

				.upload-ctx
				{
					@include opacity(0);
					@include transition(none);
					@include transform(translateY(-50%));
					@include position(absolute, 7, $top: 50%, $right: 6px);

					i, span, .material-icons, .ctx-icon
					{
						@include font($font-base, $weight-regular, 1, $white-color);
					}
				}

				&.timeline-outline
				{
					background: $white-color;
					border: 2px solid $brand-gray-07;

					.upload-ctx
					{
						i, span, .material-icons, .ctx-icon
						{
							color: $brand-gray-07;
						}
					}
				}

				&:hover, &:active, &:focus
				{
					.upload-ctx
					{
						@include opacity(1);
					}
				}
			}

			.leg-timeline-inline
			{
				height: 45px;
				flex: 0 0 45px;
				padding: 0 25px;
				border-bottom: 1px solid $brand-gray-03;

				@include position;
				@include inline-flex;
			}

			.leg-timeline-list
			{
				flex: 1;
				width: 100%;
				// height: 100%;
				overflow: hidden;
				padding: 7px 0;

				.timeline-list-inner
				{
					overflow: hidden;
				}

				.leg-timeline
				{
					height: 35px;
					padding: 0 25px;
					border-bottom: 1px solid $brand-gray-03;

					@include position;

					.timeline-slab
					{
						@include absolute-center(y);
					}
				}

				&:empty
				{
					display: none;
				}
			}
		}
	}

	.panel-ui-resizble-handle
	{
		width: 100%;
		height: 300px;

		@include position;
	}

	.panel-title
	{
		width: 100%;
		min-height: 45px;
		padding: 12px 10px;
		padding-right: 50px;
		// background: $white-color;
		border-bottom: 1px solid $brand-gray-03;

		@include position;
		@include inline-flex;
		// @include position(absolute, 5, 0, 0);

		h3
		{
			flex: 1;

			@include text-truncate;
		}

		ul
		{
			@include opacity(0);
			@include inline-flex;
			@include absolute-center(y, 11)
			{
				right: 7px;
				left: auto;
			}

			li
			{
				margin-right: 5px;

				a
				{
					display: block;
					
					@include font($font-base, $weight-regular, 1.2, $brand-primary);

					i
					{
						@include font(inherit, inherit, inherit, inherit);
					}
				}

				&:last-child
				{
					margin: 0;
					padding: 0;
				}
			}

			&.always-visible
			{
				@include opacity(1);
			}
		}

		.title-icon
		{
			margin-right: 10px;
		}

		&.keep-active, &.ctx-active, &:hover, &:active, &:focus
		{
			ul
			{
				@include opacity(1);
			}
		}
	}

	.panel-uploader
	{
		@include opacity(0);
		@include pointer-events(none);
		@include position(absolute, 0);
	}

	.panel-list
	{
		// height: 100%;
		// max-height: 100%;
		flex: 1;
		overflow: hidden;
		padding: 7px 10px;
		
		.list-inner
		{
			// height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
		}

		.form-group
		{
			margin-bottom: 15px;

			label
			{
				display: block;
				margin: 0;

				@include font-p($font-sm, $weight-regular, 1.2, $color-01);
			}
		}
	}

	.panel-actions
	{
		width: 100%;
		// background: $white-color;
		border-top: 1px solid $brand-gray-03;

		@include position;
		// @include position(absolute, 5, $bottom: 0, $left: 0);

		ul
		{
			@include inline-flex;

			li
			{
				flex: 1;

				@include transition;

				a
				{
					display: block;
					padding: 10px;
					text-align: center;
					letter-spacing: 0.7px;
					text-transform: uppercase;
					// background: $brand-gray-01;
					border-right: 1px solid $brand-gray-03;

					@include font-p($font-sm, $weight-bold, 1.1, $brand-gray-02);

					&:hover, &:active, &:focus
					{
						@include text-color($white-color);
					}
				}

				&:last-child
				{
					a
					{
						border: 0;
					}
				}

				&.atn-undo
				{
					a
					{
						&:hover, &:active, &:focus
						{
							@include bg-color($brand-warning);
						}
					}
				}

				&.atn-cancel
				{
					a
					{
						&:hover, &:active, &:focus
						{
							@include bg-color($brand-danger);
						}
					}
				}

				&.atn-save
				{
					a
					{
						&:hover, &:active, &:focus
						{
							@include bg-color($brand-info);
						}
					}
				}

				&.disabled
				{
					@include pointer-events(none);
					@include text-color($brand-gray-01);

					a
					{
						@include opacity(0.4);
					}
				}

				&:hover, &:active, &:focus
				{
					> a
					{
						background: darken($brand-gray-01, 5%);

						@include text-color($white-color);
					}
				}
			}
		}
	}

	&.tour-sequence-editor
	{
		right: 0;
		width: 50%;
		left: auto;

		&.panel-mixer-active
		{
			left: 0;
			width: 100%;
			right: auto;
			height: 100%;
			background: $brand-gray-06;
	
			.panel-inner
			{
				flex-direction: row;
				// padding-left: 300px;
			}
	
			.editor-panel
			{
				width: 300px;
				// border-right: 1px solid $brand-gray-03;
			}
		}
	}
}

/* ----------  Key Frames  ---------- */

@include keyframe(alert) {
	from {
		@include opacity(0);
	}
	50% {
		@include opacity(0.3);
	}
	to {
		@include opacity(0);
	}
}
