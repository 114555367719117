/* ----------  YouTube Video  ---------- */

.yt-video
{
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;

	@include position;

	iframe
	{
		width: 100%;
		height: 100%;

		@include position(absolute, 5, 0, 0);
	}
}