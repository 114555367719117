/* ----------  Page Tour Details  ---------- */

.page-tour-details
{
	.tour-details-container
	{
		@include position(relative, 11);

		.sidebar-detail
		{
			@include reset-position;

			.main-details-container
			{
				margin-bottom: 30px;

				@include position;

				.main-details-content
				{
					width: calc(100% + 40px);
					padding: 20px;
					background: $white-color;

					@include transition;
					@include absolute-center(x, 111)
					{
						top: -20px;
					}

					&.expanded
					{
						margin: 0;
						top: -100px;
						width: 90vw;
						min-height: 50vh;

						@include box-shadow(0 3px 6px rgba($black-color, 0.16), 0 3px 6px rgba($black-color, 0.23));

						.controls-more
						{
							
						}
					}

					@media (max-width: $screen-sm-max)
					{
						padding: 0;
						width: 100%;

						@include transition;
						@include reset-position;
					}
				}
			}

			.controls-more
			{
				top: 15px;
				left: auto;
				right: 15px;

				@media (max-width: $screen-sm-max)
				{
					top: 5px;
					right: 0;
					left: auto;
				}
			}
		}
	}
}

/* ----------  Google Streetview Section  ---------- */

.google-streetview-container
{
	margin-bottom: 40px;

	@include position;

	.streetview-inner
	{
		overflow: hidden;

		@include position;
	}

	.streetview
	{
		height: 800px;
	}

	.streetview-canvas
	{
		height: 800px;
		background: $brand-gray-01;

		*
		{
			-webkit-box-sizing: initial;
			-moz-box-sizing: initial;
			box-sizing: initial;
		}

		@media (max-width: $screen-sm-max)
		{
			height: 600px;
		}

		@media (max-width: $screen-xs-max)
		{
			height: 400px;
		}
	}

	.streetview-controls
	{
		width: 100%;

		@include opacity(0);
		@include transition;
		@include transform(translateY(30px));
		@include faded-background($black-color, 0.2);
		@include position(absolute, 11, $bottom: 0, $left: 0);

		.controls-inner
		{
			display: flex;
			padding: 10px 0;
			flex-wrap: wrap;
			align-items: center;
			flex-direction: row;
			justify-content: center;
		}

		ul
		{
			display: flex;
			margin: 0;
			padding: 0;
			flex-direction: row;
			align-items: center;
			list-style-type: none;

			li
			{
				margin-right: 15px;

				a
				{
					display: flex;
					width: 40px;
					height: 40px;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					background: lighten($brand-primary, 30%);

					@include position;
					@include border-radius(99px)
					@include font($font-md, $weight-regular, 1, $white-color);

					span
					{
						display: block;

						@include font(inherit, inherit, inherit, inherit);
					}

					&.control-lg
					{
						width: 60px;
						height: 60px;
						font-size: rem-calc(28);

						@media (max-width: $screen-xs-max)
						{
							width: 40px;
							height: 40px;
							font-size: rem-calc(22);
						}
					}

					&.control-xl
					{
						width: 80px;
						height: 80px;
						font-size: rem-calc(36);

						@media (max-width: $screen-xs-max)
						{
							width: 60px;
							height: 60px;
							font-size: rem-calc(28);
						}
					}

					&.control-to-rewind
					{
						span
						{
							margin-left: -4px;
						}
					}

					&.control-play
					{
						background: $brand-primary;

						span
						{
							margin-left: 4px;

							&:before
							{
								content: "\f04b";
							}
						}

						&.state-pause
						{
							background: $brand-warning;

							span
							{
								margin-left: 0;

								&:before
								{
									content: "\f04c";
								}
							}
						}

						&.state-stop
						{
							color: $brand-danger;
							background: $white-color;

							span
							{
								margin-left: 0;

								&:before
								{
									content: "\f04d";
								}
							}
						}
					}

					&.control-to-forward
					{
						span
						{
							margin-left: 4px;
						}
					}

					&.control-minimal
					{
						width: auto;
						height: auto;
						background: none;

						@include opacity(0.7);

						&:hover, &:active, &:focus
						{
							@include opacity(1);
						}
					}

					&.control-md-icon
					{
						font-size: rem-calc(28);

						&.control-lg
						{
							font-size: rem-calc(40);

							@media (max-width: $screen-xs-max)
							{
								font-size: rem-calc(28);
							}
						}

						&.control-xl
						{
							font-size: rem-calc(50);

							@media (max-width: $screen-xs-max)
							{
								font-size: rem-calc(38);
							}
						}

						@media (max-width: $screen-xs-max)
						{
							font-size: rem-calc(22);
						}
					}

					&:not(.control-minimal)
					{
						&:hover, &:active, &:focus
						{
							background: $brand-primary;
						}
					}

					@media (max-width: $screen-xs-max)
					{
						width: 30px;
						height: 30px;
						font-size: $font-sm;
					}
				}

				&:last-child
				{
					margin: 0;
				}

				@media (max-width: $screen-xs-max)
				{
					margin-right: 7px;
				}

				@media (max-width: $screen-xxs-max)
				{
					margin-right: 5px;
				}
			}
		}

		.controls
		{
			&.primary
			{
				flex: 1;
			}
		}
	}

	&.streetview-sm
	{
		margin-bottom: 0;

		.streetview-canvas, .streetview
		{
			height: 500px;

			@media (max-width: $screen-sm-max)
			{
				height: 400px;
			}

			@media (max-width: $screen-xs-max)
			{
				height: 300px;
			}
		}
	}

	&:hover, &:active, &:focus
	{
		.streetview-controls
		{
			@include opacity(1);
			@include transform(translateY(0));
		}
	}
}