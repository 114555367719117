/* ----------  Contextual Classes  ---------- */

@each $class, $brand in $contextuals
{
	.bg-#{$class}, .md-btn-#{$class}
	{
		@include bg-color($brand);
	}

	.bg-hvr-#{$class}
	{
		@include bg-color($brand);

		&:hover, &:active, &:focus
		{
			@include bg-color(darken($brand, 10%));
		}
	}

	.text-hvr-#{$class}
	{
		&:hover, &:active, &:focus
		{
			@include bg-color($brand);
		}
	}

	.text-#{$class}
	{
		@include text-color($brand);
	}

	.link-#{$class}
	{
		@include text-color($brand, true);
	}
}

/* ----------  Map Info Contextuals  ---------- */

@each $class, $primary, $secondary in $categories
{
	.label.label-#{$class}
	{
		@include bg-color($primary);
		@include text-color($secondary);
	}
}

/* ----------  Social Share  ---------- */

@each $class, $brand in $jsSocialShares
{
	.jssocials-share-#{$class}
	{
		a
		{
			@include bg-color($brand);

			&:hover, &:active, &:focus
			{
				@include bg-color(darken($brand, 5%));
			}
		}
	}
}


/* ----------  Gray Shades  ---------- */

@for $i from 1 through 10
{
	.bg-gray-shade-#{$i}
	{
		@include bg-color(darken($white-color, ($i * 2)));
	}
}