/* ----------  Modals  ---------- */

.uk-modal
{
	.uk-modal-header, .uk-modal-body, .uk-modal-footer
	{
		@include position;
	}

	.uk-modal-dialog
	{
		min-height: 150px;
	}

	.uk-modal-header
	{
		z-index: 11;
		overflow: initial;

		.uk-modal-title
		{
			@include text-color($color-01);

			span
			{
				display: inline-block;
				font-size: inherit;
			}
		}

		.header-action
		{
			@include absolute-center(y)
			{
				left: auto;
				right: 30px;
			}
		}
	}

	.uk-modal-body
	{
		z-index: 5;

		.text-address
		{
			@include text-truncate(100%);
		}

		.google-services
		{
			@include position;
			@include inline-flex;

			.manual-location-map, .manual-location-streetview
			{
				flex: 1;
				width: 100%;
				height: 250px;
				background: $brand-gray-01;

				@include position(relative, 11);
			}
		}

		.uk-file-upload
		{
			padding: 15px;
			min-height: 150px;
			margin-bottom: 20px;

			@include flex(column, center, center);
		}

		.md-gallery-container
		{
			padding: 5px;

			.md-gallery-list
			{
				padding: 7px;

				@include inline-flex;
				@include md-default-shadow;
				@include border-radius(4px);

				.md-gallery-item
				{
					padding: 7px;

					.md-gallery-item-inner
					{
						overflow: hidden;

						@include position;
						@include transition;
						@include border-radius(4px);
					}

					span, img
					{
						display: block;
					}

					.gallery-img
					{
						max-width: 150px;
					}

					.btn-set
					{
						padding: 5px 7px;

						@include link;
						@include border-radius(99px);
						@include transform(translateX(-5px));
						@include position(absolute, 5, 7px, 5px);
						@include faded-background($white-color, 0.7);
						@include font-p(rem-calc(10), $weight-regular, 1, $color-01);

						&:hover, &:active, &:focus
						{
							background: $white-color;
						}
					}

					.btn-remove
					{
						@include link;
						@include transform(translateX(5px) rotate(-45deg));
						@include position(absolute, 5, $top: 5px, $right: 5px);
						@include font($font-lg, $weight-regular, 1.2, $white-color);

						i
						{
							@include font(inherit, inherit, inherit, inherit);
						}
					}

					.btn-set, .btn-remove
					{
						@include opacity(0);
					}

					&:hover, &:active, &:focus
					{
						.md-gallery-item-inner
						{
							@include md-default-shadow;
						}

						.btn-set, .btn-remove
						{
							@include opacity(1);
						}

						.btn-set
						{
							@include transform(translateX(0));
						}

						.btn-remove
						{
							@include transform(translateX(0) rotate(-45deg));
						}
					}
				}
			}
		}

		.map-canvas
		{
			.map
			{
			}
		}
	}

	.uk-modal-footer
	{
		@include inline-flex(center, flex-end);
	}
}

/* ----------  Modal Hyperlink  ---------- */

.uk-modal.modal-tour-hyperlink
{
	.md-form-group.has-cc-bcc
	{
		@include position;

		.md-input-actions
		{
			@include position(absolute, 5, $top: 10px, $right: 0);

			ul
			{
				margin: 0;
				padding: 0;
				list-style-type: none;

				@include inline-flex;

				li
				{
					margin-right: 10px;

					a
					{
						display: block;

						@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-02);

						&:hover, &:active, &:focus
						{
							color: $color-01;
						}
					}

					&:last-child
					{
						margin-right: 0;
					}

					&.active
					{
						a
						{
							color: $brand-warning;
						}
					}
				}
			}
		}
	}
}