/* ----------  Profile Edit  ---------- */

.user-edit-form
{
	.md-fab-wrapper
	{
		.md-fab
		{
			@include position;
			
			.icon-loading
			{
				@include absolute-center;
				@include font-fa(rem-calc(26), $weight-regular, 1, $white-color);
			}
		}
	}
}