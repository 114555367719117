/* ----------  Shadows  ---------- */

@mixin box-shadow($value...)
{
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
	box-shadow: $value;
}

/* ----------  Text Shadow  ---------- */

@mixin text-shadow($value)
{
	-webkit-text-shadow: $value;
	-moz-text-shadow: $value;
	-ms-text-shadow: $value;
	-o-text-shadow: $value;
	text-shadow: $value;
}

/* ----------  Drop Shadow  ---------- */

@mixin drop-shadow($value)
{
	@include filter(drop-shadow($value));
}