/* ----------  Global Contextuals  ---------- */

$contextuals: (
	('white', $white-color),
	('black', $black-color),
	('primary', $brand-primary),
	('info', $brand-info),
	('success', $brand-success),
	('warning', $brand-warning),
	('dark', $brand-dark),
	('warning-light', $brand-warning-01),
	('grayish', $brand-gray-04),
	('grayish-light', $brand-gray-05),
	('danger', $brand-danger),
	('facebook', $brand-facebook),
	('twitter', $brand-twitter),
	('google-plus', $brand-google-plus),
);

/* ----------  Global Contextuals  ---------- */

$jsSocialShares: (
	('facebook', $brand-facebook),
	('twitter', $brand-twitter),
	('googleplus', $brand-google-plus),
	('linkedin', $brand-linkedin),
	('pinterest', $brand-pinterest),
);

/* ----------  Map Info Contextuals  ---------- */

$categories: (
	('black', $black-color, $white-color),
	('default', $brand-primary, $white-color),
	('danger', $brand-danger, $white-color),
	('info', $brand-info, $white-color),
	('success', $brand-success, $white-color),
	('warning', $brand-warning, $white-color),

	('retail', #0000ff, $white-color),
	('restaurant', #ffff00, $white-color),
	('real-estate', #5d0707, $white-color),
	('tourist-attraction', #0a0101, $white-color),
	('scenic', #1d1111, $white-color),
	('historic-site', #d4a4a4, $white-color),
	('entertainment', #272424, $white-color),
	('sports', #bf9ab9, $white-color),
	('recreation', #bf9ab9, $white-color),
	('office', #011a21, $white-color),
	('service', #dcdcdc, $white-color),
	('education', #007f66, $white-color),
	('culture', #465945, $white-color),
	('religion', #6eaea1, $white-color),
	('government', #a99a86, $white-color),
	('automobile', #f29cb7, $white-color),
	('transportation', #aa98a9, $white-color),
	('neighborhood', #006db0, $white-color),
	('personal', #eb6123, $white-color),
);