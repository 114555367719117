/* ----------  Fonts Mixins  ---------- */

@mixin font($font-size, $font-weight, $line-height, $color)
{
	color: $color;
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: $line-height;
}

@mixin font-fa($font-size, $font-weight, $line-height, $color) 
{
	font-family: 'Font Awesome 5 Free' !important;
	@include font($font-size, $font-weight, $line-height, $color);
}

@mixin font-p($font-size, $font-weight, $line-height, $color) 
{
	font-family: $font-primary;
	@include font($font-size, $font-weight, $line-height, $color);
}

@mixin font-s($font-size, $font-weight, $line-height, $color) 
{
	font-family: $font-secondary;
	@include font($font-size, $font-weight, $line-height, $color);
}

@mixin font-inherit 
{
	@include font(inherit, inherit, inherit, inherit);
}
