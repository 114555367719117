/* ----------  Buttons  ---------- */

.btn, .md-btn, .md-fab
{
	white-space: nowrap;

	span, i
	{
		color: inherit;
	}
}

.md-btn
{
	@include font-p($font-sm, $weight-medium, 2.2, $color-01);

	&:hover, &:active, &:focus
	{
		@include font-p($font-sm, $weight-medium, 2.2, $color-01);
	}
}

/* ----------  Btn Icon  ---------- */

.btn.btn-icon
{
	padding: 0;
	width: 30px;
	height: 30px;
	overflow: hidden;

	@include position;
	@include border-radius(99px);

	.fa
	{
		@include absolute-center;
	}

	&.btn-lg
	{
		width: 40px;
		height: 40px;
		font-size: $font-md;
	}

	&.btn-xl
	{
		width: 60px;
		height: 60px;
		font-size: $font-lg;
	}
}

/* ----------  Btn States  ---------- */

.btn, .md-btn, .md-fab
{
	&.touched
	{
		@include bg-color($brand-warning);
	}

	&.disabled, &[disabled]
	{
		@include pointer-events(none);
		@include bg-color($brand-gray-11);

		*
		{
			@include pointer-events(none !important);
		}
	}

	&.has-states
	{
		@include bg-color($brand-gray-11);

		.btn-icon
		{
			display: none;

			&.icon-static
			{
				display: block;
			}
		}

		&.state-loading
		{
			.btn-icon
			{
				display: none;

				&.icon-loading
				{
					display: block;
				}
			}
		}

		&.state-active
		{
			@include bg-color($brand-warning);
		}
	}
}