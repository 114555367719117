/*----------  React Table Reset  ----------*/

.react-table
{
	border: 0;

	.rt-table
	{
		.rt-thead
		{
			.rt-tr
			{
				text-align: left;

				.rt-th
				{
					border: 0;

					@include box-shadow(none);
				}
			}

			&.-header
			{
				@include box-shadow(none);
			}
		}
	}
}

/*----------  React Table  ----------*/

.react-table
{
	.rt-table
	{
		.rt-thead
		{
			.rt-tr
			{
				background: $brand-primary;

				.rt-th
				{
					padding: 0;

					.th-table-actions
					{
						padding-right: 40px;

						> ul
						{
							@include reset-list;
							@include inline-flex;

							> li
							{
								> a
								{
									height: 36px;
									min-width: 40px;

									@include position;
									@include inline-flex(center, center);
									@include font-p($font-sm, $weight-regular, 1, $white-color);

									i
									{
										display: block;

										@include font($font-md, $weight-regular, 1, $white-color);
									}
								}
							}
						}
					}
				}
			}
		}

		.rt-tbody
		{
			.rt-tr-group
			{
				border: 0;

				.rt-tr
				{
					border: 0;
					min-height: 36px;
					padding-left: 3px;
					align-items: center;
					border-bottom: 1px solid $brand-gray-11;

					@include position;

					.rt-td
					{
						border: 0;
						padding: 8px 10px;

						@include font-p($font-sm, $weight-regular, 1.2, $black-color);

						.td-publish
						{
							.atn-publish
							{
								display: block;
								cursor: pointer;

								@include font($font-md, $weight-regular, 1, $brand-gray-02);

								i
								{
									@include font-inherit;
								}

								&.published
								{
									color: $brand-success-01;
								}
							}
						}

						.td-actions
						{
							@include opacity(0);
							@include inline-flex;

							.atn
							{
								display: block;
								cursor: pointer;
								margin-right: 5px;

								@include font($font-base, $weight-regular, 1, $color-01);

								img
								{
									display: block;
									max-height: 16px;
								}

								i
								{
									display: block;

									@include font(inherit, inherit, inherit, inherit);
								}

								&:last-child
								{
									margin: 0;
								}
							}
						}
					}

					&.-padRow
					{
						@include pointer-events(none);
					}

					&:before
					{
						content: '';
						display: none;
						width: 3px;
						height: 100%;
						background: $brand-primary;

						@include position(absolute, 5, 0, 0);
					}

					&.rt-tr-active, &.rt-tr-hovered, &:hover
					{
						&:before
						{
							display: block;
						}
					}
					
					&.ctx-active, &:hover, &:active, &:focus
					{
						background: $brand-gray-11;

						.rt-td
						{
							.td-actions
							{
								@include opacity(1);
							}
						}
					}

					@each $category, $brand in $categories {
						&[data-category="#{$category}"]
						{
							&:before
							{
								@include bg-color($brand);
							}
						}
					}
				}
			}
		}
	}

	.rt-th-content
	{
		height: 36px;
		padding: 0 5px;

		@include position;
		@include flex($jc: center);

		span
		{
			display: block;
		}
		
		.th-content
		{
			width: 100%;
			height: 100%;

			@include inline-flex;

			.th-title
			{
				flex: 1;

				@include font-p($font-sm, $weight-regular, 1.2, $white-color);

				ul
				{
					@include reset-list;

					li
					{
						a
						{
							display: block;

							@include font-inherit;
						}
					}
				}
			}

			.th-sort
			{
				cursor: pointer;
				margin-right: 6px;

				@include position;

				&:after
				{
					content: '\e164';
					font-family: 'Material Icons';

					@include font($font-lg, $weight-regular, 1, $white-color);
				}
			}

			.th-publish
			{
				cursor: pointer;
				margin-right: 10px;

				@include font($font-lg, $weight-regular, 1, $white-color);

				i
				{
					@include font(inherit, inherit, inherit, inherit);
				}
			}

			&.th-publish-content
			{
				padding-left: 4px;
			}
		}

		.th-filter
		{
			width: 100%;
			height: 30px;
			background: $white-color;

			@include position;

			.irs.irs-input
			{
				width: auto;
				height: 25px;

				@include transform(translateY(-50%));
				@include position(absolute, 11, $top: 50%, $left: 10px, $right: 37px);
			}

			input
			{
				height: 100%;
				padding: 5px 8px;
				padding-right: 30px;

				@include box-shadow(none);
			}

			.atn-clear
			{
				cursor: pointer;

				@include position(absolute, 5, $top: 5px, $right: 7px);
			}
		}

		&.sorting-asc
		{
			.th-content
			{
				.th-sort
				{
					&:after
					{
						content: '\e316';
					}
				}
			}
		}

		&.sorting-desc
		{
			.th-content
			{
				.th-sort
				{
					&:after
					{
						content: '\e313';
					}
				}
			}
		}
	}
}

/*----------  React Table Pagination  ----------*/

.react-table
{
	.pagination-bottom
	{
		margin-top: 10px;
	}

	.-pagination
	{
		border: 0;
		padding: 0;
		align-items: center;
		justify-content: center;

		.-btn
		{
			padding: 5px 7px;

			@include font-p($font-sm, $weight-regular, 1.1, $color-01);

			&[disabled]
			{
				cursor: not-allowed;

				@include bg-color(transparent);
				@include text-color($brand-gray-02);
			}

			&:not([disabled])
			{
				background: none;

				&:hover, &:active, &:focus
				{
					color: $color-01;
					background: $brand-gray-01;
				}
			}
		}

		.-pageInfo
		{
			margin: 0 10px;
			margin-top: 1px;

			@include inline-flex;
			@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-02);

			.-pageJump
			{
				margin: 0 5px;
				display: block;

				input
				{
					border: 0;
					padding: 5px 0;
					border-bottom: 2px solid $brand-gray-02;
					
					@include box-shadow(none);
					@include border-radius(0);
					@include font-p($font-sm, $weight-regular, 1.2, $color-01);

					&:focus
					{
						border-bottom-color: $brand-primary;
					}
				}
			}

			.-totalPages
			{
				margin-left: 5px;
			}
		}

		.-previous, .-center, .-next
		{
			flex: none;
		}

		@include box-shadow(none);
	}
}