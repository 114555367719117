/* ----------  Google Map Address Autocomplete Dropdown  ---------- */

.pac-container
{
	border: 0;
	z-index: 2222;
	margin-top: 10px;
	overflow: hidden;
	background: $white-color;
	border: 1px solid $brand-gray-03;

	// @include md-default-shadow;
	@include box-shadow(none);
	@include border-radius(3px);
	@include font-p($font-base, $weight-regular, 1.2, $color-01);

	.pac-item
	{
		cursor: pointer;
		padding: 5px 10px;
		border-color: $brand-gray-03;

		&:first-child
		{
			border: 0;
		}
	}

	&:after
	{
		display: none;
	}
}