/* ----------  Rate  ---------- */

.rate
{
	label
	{
		margin-bottom: 0;
		bottom: auto !important;
	}

	.br-wrapper
	{
		.br-widget
		{
			display: flex;
			height: auto;
			flex-direction: row;

			a
			{
				display: block;
			}
		}
	}
}