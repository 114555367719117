/* ----------  Header  ---------- */

header
{
	@include position(relative, 999);
}

/* ----------  Main Nav Section  ---------- */

#page-header.main-navbar-container
{
	nav
	{
		display: block;
		width: 100%;
		padding: 0;
		white-space: initial;

		// @include position(fixed, 977, 0, 0);

		.navbar-inner
		{
			display: flex;
			width: 100%;
			flex-direction: row;
			align-items: stretch;

			@include clearfix;
			@include transition;

			@media (max-width: $screen-xs-max)
			{
			}
		}

		.navbar-header
		{
			float: none;
			clear: both;

			@include position;

			.nav-btn
			{
				@media (max-width: $screen-sm-max)
				{
					display: block;
				}
			}

			@media (max-width: $screen-sm-max)
			{
				padding-right: 50px;
			}

			@media (max-width: $screen-xs-max)
			{
			}
		}

		.navbar-contents
		{
			display: flex;
			flex: 1;
			padding: 0;
			float: none;
			clear: both;
			margin-right: 10px;
			flex-direction: row;
			padding-right: 20px;
			padding-bottom: 10px;
			align-items: flex-end;
			justify-content: flex-end;

			@include background-defaults;

			&.light
			{
				.navigation
				{
					li
					{
						a
						{
							@include text-color($white-color);

							&:before, &:after
							{
								color: inherit;
							}
						}

						&.active
						{
							> a
							{
								border-color: $white-color !important;
							}
						}
					}
				}
			}

			@media (max-width: $screen-sm-max)
			{
				margin: 0;
				padding: 0;
				padding-right: 15px;
				align-items: center;
				background: none !important;
			}
		}

		.brand
		{
			padding: 15px 20px;

			img
			{
				height: 80px;
				
				@include transition;

				@media (max-width: $screen-md-max)
				{
					height: 60px;
				}
				
				@media (max-width: $screen-sm-max)
				{
					height: 50px;
				}
			}

			@media (max-width: $screen-sm-max)
			{
				padding: 10px 15px;
				padding-right: 0;
			}
		}

		.primary-nav
		{
			margin: 0;
			border: 0;
			padding: 0;
			z-index: 111;
			padding-bottom: 7px;

			@include transition;
			@include inline-flex;

			.navigation
			{
				> li
				{
					&.has-child
					{
						> a
						{
							&:before, &:after
							{
								font-family: "Font Awesome 5 Free";
								font-weight: 900;
							}
						}

						.wrapper
						{
							display: none;

							@media (max-width: $screen-sm-max)
							{
								display: block;
							}
						}
					}
				}
			}

			@media (max-width: $screen-sm-max)
			{
				display: none;
				padding: 0;
				margin: 0;
				right: auto;
				left: 90px;
			}

			/* @media (max-width: $screen-xs-max)
			{
				left: 50%;
			
				@include transform(translateX(-50%));
			} */
		}

		.secondary-nav
		{
			display: block;
			padding: 0;
			margin-right: 0;
			padding-right: 15px;

			@include clearfix;
			
			a
			{
				display: block;
				float: left;
				border: 0;
				margin: 0;
				padding: 5px 0;
				margin-right: 10px;

				span
				{
					display: inline-block;
				}

				&:last-child
				{
					margin: 0;
				}
			}

			@media (max-width: $screen-xs-max)
			{
				border: 0;
			}
		}

		.social-nav
		{
			padding-left: 15px;

			ul
			{
				li
				{
					margin-right: 10px;

					&:last-child
					{
						margin: 0;
					}
				}
			}
		}

		.user-info
		{
			display: flex;
			margin-right: 15px;
			align-items: center;
			flex-direction: row;

			a
			{
				display: flex;
				align-items: center;
				flex-direction: row;
				
				@include font-p($font-base, $weight-regular, 1.2, $brand-gray-02);

				&:hover, &:active, &:focus
				{
					color: $brand-primary;
				}
			}

			span
			{
				display: block;
			}

			.user-avatar
			{
				width: 27px;
				height: 27px;
				margin-right: 7px;
			}
		}

		.language-dropdown
		{
			margin-right: 30px;

			@include position;

			.flag-icon
			{
				width: 25px;
			}

			.language-current
			{
				padding: 7px 5px;

				@include link;
				@include position;

				&:after
				{
					content: "\f0d7";

					@include transition;
					@include font-fa($font-lg, $weight-regular, 1.2, $brand-gray-02);
					@include absolute-center(y)
					{
						left: auto;
						right: -15px;
					}
				}
			}

			.language-list
			{
				width: 135px;
				background: $white-color;

				@include transition;
				@include opacity(0);
				@include pointer-events(none);
				@include box-shadow(0 3px 15px rgba($black-color, 0.2));
				@include absolute-center(x)
				{
					top: calc(100% + 5px);
				}

				ul
				{
					li
					{
						width: 33.33%;
						border-bottom: 1px solid $brand-gray-01;

						a
						{
							display: block;
							padding: 7px;

							span
							{
								display: block;
								width: 100%;
							}
						}

						&:nth-child(odd)
						{
							border-right: 1px solid $brand-gray-01;
						}

						&.active, &:hover, &:active, &:focus
						{
							> a
							{
								background: darken($brand-gray-01, 5%);
							}
						}
					}
				}

				@media (max-width: $screen-xs-max)
				{
					left: 0;

					@include transform(none);
				}
			}

			&:hover, &:active, &:focus
			{
				.language-current
				{
					&:after
					{
						color: $brand-primary;

						@include transform(translateY(-50%) rotate(-180deg));
					}
				}

				.language-list
				{
					top: 100%;

					@include opacity(1);
					@include pointer-events(auto);
				}
			}
		}

		.social-nav
		{
			@media (max-width: $screen-xs-max)
			{
				display: none;
			}
		}

		.sidebar-actions-container
		{
			@media (max-width: $screen-sm-max)
			{
				display: none;
			}
		}

		&.nav-fixed
		{
			.brand
			{
				img
				{
					height: 50px;
				}
			}

			.sidebar-actions-container
			{
				> ul
				{
					> li
					{
						> a
						{
							width: 45px;
							height: 45px;
							font-size: $font-lg !important;

							img
							{
								width: 38px;
								height: 38px;
							}
						}
					}
				}
			}
		}

		@media (max-width: $screen-sm-max)
		{
			@include transition;
			@include transform(translateY(-100%));
		}
	}

	.navbar-placeholder
	{
		width: 100%;
		height: 50px;
		background: none;

		@include position;
		@include opacity(0);
		@include pointer-events(none);

		@media (max-width: $screen-sm-max)
		{
			display: none !important;
		}
	}
}

/* ----------  Responsive  ---------- */

body.nav-btn-only
{
	#page-header
	{
		.primary-nav
		{
			ul.navigation
			{
				li
				{
					&:first-child
					{
						&:after
						{
							left: 8px;
							right: auto;
						}
					}

					@media (max-width: $screen-xs-max)
					{
						&:first-child
						{
							&:after
							{
								left: 55px;
							}
						}
					}
				}
			}

			&.show
			{
				@media (max-width: $screen-xs-max)
				{
					left: 40px;
				}
			}

			@media (max-width: $screen-xs-max)
			{
				top: 70px;
			}
		}
	}
}

/* ----------  Admin Header  ---------- */

#header_main
{
	z-index: 999;
	padding-left: 240px;

	@include transition;
	@include bg-color($brand-gray-07);

	.btn-toggle-fullscreen
	{
		.icon-static
		{
			display: inline-block;
		}

		.icon-active
		{
			display: none;
		}

		&.active
		{
			.icon-static
			{
				display: none;
			}

			.icon-active
			{
				display: inline-block;
			}
		}
	}

	.header_main_content
	{
		.uk-navbar
		{
			.btn-sidebar-toggle
			{
				display: none;

				@media (max-width: $screen-sm-max)
				{
					display: block;
				}
			}

			.shopping-cart
			{
				> a
				{
					&:after
					{
						display: none;
					}
				}

				.uk-dropdown-shopping-cart
				{
					width: 320px;
				}
			}

			.language-changer
			{
				margin-top: 13px;
				margin-right: 7px;

				.selectize-control
				{
					.selectize-input
					{
						&:after
						{
							color: $white-color;
						}
					}
				}
			}

			@media (max-width: $screen-sm-max)
			{
				padding: 0 15px;
			}
		}
	}

	@media (max-width: $screen-sm-max)
	{
		padding: 0;
	}
}

/* ----------  Header Actions  ---------- */

.header-actions-container
{
	display: none;

	a
	{
		display: block;
		width: 50px;
		height: 50px;

		@include border-radius(99px);
		@include faded-background($black-color, 0.5);
		@include position(absolute, 111, 20px);

		.btn-icon
		{
			@include absolute-center;
			@include font(rem-calc(30), $weight-regular, 1, $white-color);
		}
	}

	.btn-nav-toggler
	{
		left: 20px;

		.icon-close
		{
			display: none;
		}
	}

	.btn-results-toggler
	{
		right: 20px;
	}

	@media (max-width: $screen-sm-max)
	{
		display: block;
	}
}

body.header-open
{
	#page-header.main-navbar-container nav
	{
		@include transform(translateY(0));
	}

	.btn-nav-toggler
	{
		top: 80px;

		.icon-open
		{
			display: none;
		}

		.icon-close
		{
			display: block;
		}
	}

	.btn-results-toggler
	{
		@include opacity(0);
		@include pointer-events(none);
	}
}

body.results-open
{
	.btn-nav-toggler
	{
		@include opacity(0);
		@include pointer-events(none);
	}

	.btn-results-toggler
	{
		@include opacity(0);
		@include pointer-events(none);
	}
}

body.streetview-open
{
	.header-actions-container
	{
		a
		{
			@include opacity(0);
			@include pointer-events(none);
		}
	}
}

/* ----------  Sidebar Actions  ---------- */

.sidebar-actions-container
{
	display: flex;
	width: 400px;
	padding: 10px 20px;
	text-align: center;
	flex-direction: column;
	justify-content: flex-end;

	@include position;

	> ul
	{
		@include inline-flex;

		> li
		{
			flex: 1;
			margin-right: 15px;

			@include position;

			> a
			{
				width: 50px;
				height: 50px;
				overflow: hidden;
				border: 1px solid $brand-gray-02 !important;

				@include position;
				@include border-radius(99px);
				@include font-fa(rem-calc(26) !important, $weight-regular !important, 1 !important, $color-01 !important);

				span, img
				{
					display: block;
					
					@include absolute-center;
				}

				img
				{
					width: 40px;
					height: 40px;

					@include border-radius(99px);

					@media (max-width: $screen-sm-max)
					{
						width: 32px;
						height: 32px;
					}
				}

				@media (max-width: $screen-sm-max)
				{
					width: 45px;
					height: 45px;
					font-size: $font-md !important;
				}
			}

			> .atn-dropdown
			{
				display: none;
				width: 220px;
				text-align: left;
				background: $white-color;

				@include md-default-shadow;
				@include position(absolute, 99, $top: calc(100% + 10px), $right: 30px);

				&:not(.dropdown-auth)
				{
					padding: 5px 0;
				}

				li
				{
					display: block;
					
					label
					{
						display: block;
						margin: 0;
						padding: 10px 15px;
						letter-spacing: 0.7px;
						text-transform: uppercase;
						border-bottom: 1px solid $brand-gray-01;

						@include link;
						@include font-p($font-sm, $weight-bold, 1.2, $color-01);
						@include inline-flex;

						span
						{
							display: block;
						}

						.item-toggle
						{
							margin-right: 12px;
						}

						.item-flag
						{
							margin-right: 7px;
						}
					}

					&:last-child
					{
						label
						{
							border: 0;
						}
					}

					&:hover, &:active, &:focus
					{
						> label
						{
							background: $brand-gray-01;
						}
					}
				}

				&.dropdown-auth
				{
					left: auto;
					right: 10px;
					width: 300px;

					.auth-login, .auth-register
					{
						.form-wrapper
						{
							width: 100%;
							max-width: 100%;
						}
					}
				}

				&.dropdown-lang
				{
					.dropdown-inner
					{
						overflow: hidden;
						max-height: 300px;
					}

					ul
					{
						li
						{
							.item-text
							{
								flex: 1;
							}
						}
					}

					@media (max-width: $screen-sm-max)
					{
						right: -30px;
					}
				}
				
				&.dropdown-social
				{
					width: 80px;

					.jssocials-shares
					{
						.jssocials-share
						{
							display: block;
							margin: 0;
							padding: 4px 0;
							border: 0;
							border-bottom: 1px solid $brand-gray-01;

							a
							{
								display: block;
								border: 0;
								margin: 0;
								padding: 0;
								width: 50px;
								height: 50px;
								margin: auto;
								font-size: $font-md;
								color: $white-color;
								background: $brand-primary;

								@include position;
								@include transition;
								@include border-radius(99px);

								.fa
								{
									@include absolute-center;
								}
							}

							&:last-child
							{
								border: 0;
							}
						}
					}
				}
			}

			&.item-auth
			{
				@include position(static);
			}

			&:last-child
			{
				margin: 0;
			}

			@media (max-width: $screen-sm-max)
			{
				margin-right: 0;
			}
		}
	}

	&:before
	{
		content: '';
		width: 1px;
		height: 100%;
		background: $brand-gray-03;

		@include absolute-center(y);
	}

	@media (max-width: $screen-md-max)
	{
		width: 340px;
		padding: 10px 15px;
	}

	@media (max-width: $screen-sm-max)
	{
		width: 100%;
		padding: 10px 15px;
	}
}

/* ----------  Admin Header  ---------- */

#sidebar_main.main-sidebar
{
	@include transform(none);
	@include max-width(240px);
	@include transition(all 0.15s ease-in-out);

	.sidebar_main_header
	{
		margin: 0;
		height: 100px;

		@include background-defaults;

		ul
		{
			margin: 0;
			padding: 0;
			height: 100%;
			list-style-type: none;

			@include position;
			@include flex($jc: center);

			li
			{
				a
				{
					@include transition(all 0.15s ease-in-out);
				}

				&.sidebar_logo
				{
					margin: 0;
					height: auto;
					overflow: hidden;
					line-height: 1;

					a
					{
						margin: 0;
						padding-left: 20px;

						img
						{
							max-height: 70px;

							@include transition(all 0.15s ease-in-out, false);
						}
					}
				}

				&.sidebar_action
				{
					@include transition(all 0.15s ease-in-out);
					@include position(absolute, 11, $top: 0, $right: 0);

					a
					{
						width: 40px;
						height: 40px;

						@include position;
						@include border-radius(99px);
						@include font($font-lg, $weight-regular, 1, $color-01);

						i
						{
							@include absolute-center;
							@include font(inherit, inherit, inherit, inherit);
						}

						.icon-open
						{
							display: none;
						}

						.icon-close
						{
							display: block;
						}

						&:hover
						{
							background: $brand-gray-02;
						}
					}
				}
			}
		}
	}

	.menu_section
	{
		padding-top: 10px;

		ul
		{
			li
			{
				@include position;

				a
				{
					display: block;
					overflow: hidden;
					white-space: nowrap;

					@include position;

					@include transition(all 0.15s ease-in-out);
					@include inline-flex
					{
						flex-wrap: nowrap;
					}

					span
					{
						display: block;

						@include transition(all 0.15s ease-in-out);
					}

					.menu-tooltip
					{
						display: none;
						height: 100%;
						width: 100%;

						@include opacity(0);
						@include position(absolute, 11, 0, 0);
					}

					.menu_icon
					{
						width: auto;
						margin-right: 20px;
						white-space: nowrap;
					}
				}

				ul
				{
					li
					{
						a
						{
							padding-left: 65px;
						}
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm-max)
	{
		@include transform(translateX(-100%));
	}
}

body.sidebar-minimized
{
	#header_main, #page_content
	{
		padding-left: 80px;

		@media (max-width: $screen-sm-max)
		{
			padding: 0;
		}
	}

	#sidebar_main.main-sidebar
	{
		max-width: 80px;

		.sidebar_main_header
		{
			ul
			{
				li
				{
					&.sidebar_logo
					{
						overflow: hidden;

						a
						{
							padding-top: 25px;
							padding-left: 20px;

							img
							{
								max-height: 40px;
							}
						}
					}

					&.sidebar_action
					{
						right: 18px;

						a
						{
							.icon-open
							{
								display: block;
							}

							.icon-close
							{
								display: none;
							}
						}
					}
				}
			}
		}

		.menu_section
		{
			ul
			{
				li
				{
					a
					{
						.menu_icon
						{
							margin: 0;
							padding-left: 7px;
						}

						.menu_title
						{
							@include opacity(0);
						}

						.menu-tooltip
						{
							display: block;
						}
					}

					ul
					{
						width: 180px;
						padding: 3px 0;
						background: $white-color;

						@include md-default-shadow;
						@include position(absolute, 99, 0, 90px);

						li
						{
							a
							{
								padding: 8px 15px;
							}
						}
					}
				}
			}
		}
	}
}

body.sidebar_main_active
{
	#header_main
	{
		.header_main_content
		{
			.uk-navbar
			{
				.btn-sidebar-toggle
				{
					@include transform(translateX(90px));
				}

				#sidebar_secondary_toggle, .uk-navbar-flip
				{
					@media (max-width: $screen-xs-max)
					{
						@include opacity(0);
						@include pointer-events(none);
					}
				}
			}
		}
	}

	@media (max-width: $screen-sm-max)
	{
		#sidebar_main.main-sidebar
		{
			@include transform(translateX(0));
		}
	}
}
