/* ----------  Sidebar Details  ---------- */

.sidebar-detail
{
	padding: 0;

	.sidebar-wrapper
	{
		padding: 0;
		height: 100%;
	}

	.sidebar-content
	{
		height: 100%;
		overflow: hidden;
		padding-top: 56px;
		padding-bottom: 20px;

		@include position;
	}

	.content-sections
	{
		padding: 0 15px;
		padding-top: 15px;
	}

	.guide-name
	{
		p
		{
			letter-spacing: 0.5px;
			text-transform: uppercase;
			
			@include font-p($font-sm, $weight-extrabold, 1.2, $brand-primary);
		}
	}

	.actions-container
	{
		margin: 0;
		width: 100%;
		background: $brand-gray-03;

		@include inline-flex(center, center);
		@include position(absolute, 5, 0, 0);

		.atn-btn
		{
			display: block;
			flex: 1;
			border: 0;
			width: auto;
			height: auto;
			padding: 7px 10px;

			@include font-p($font-sm, $weight-regular, 1.2, $color-01);

			.atn-inner
			{
				width: 42px;
				height: 42px;
				margin: auto;
				padding: 10px 12px;
				background: $white-color;

				@include transition;
				@include border-radius(99px);
				@include inline-flex
				{
					flex-wrap: nowrap;
				}
			}

			span
			{
				display: block;
			}

			.atn-icon
			{
				font-size: $font-base;
			}

			.atn-text
			{
				white-space: nowrap;

				@include opacity(0);
				@include transform(translateX(-10px));
				@include transition(all 0.1s ease-in-out);
			}

			&:before, &:after
			{
				display: none;
			}

			@media (max-width: $screen-md-max)
			{
				font-size: $font-xs;
			}

			@media (min-width: 992px)
			{
				&.atn-related
				{
					.atn-icon
					{
						font-size: $font-md;
					}

					&:hover, &:active, &:focus
					{
						.atn-inner
						{
							width: 65%;
						}
					}
				}

				&:hover, &:active, &:focus
				{
					.atn-inner
					{
						width: 90%;
						padding-left: 17px;
						padding-right: 17px;
					}

					.atn-text
					{
						margin-left: 7px;
						transition-delay: 0.2s;

						@include opacity(1);
						@include transform(translateX(0));
					}
				}
			}
		}
	}

	.section-header
	{
		padding-right: 60px;

		@include position;

		.section-title
		{
			margin-bottom: 7px;
		}

		.section-user
		{
			margin-bottom: 7px;

			h4
			{
				text-transform: uppercase;
				
				@include font-p($font-sm, $weight-bold, 1.2, $color-01);
			}
		}

		.section-meta
		{
			
		}

		.section-atn-play
		{
			@include inline-flex;
			@include position(absolute, 11, $top: 10px, $right: 15px);

			a
			{
				display: block;
				margin-right: 5px;

				img
				{
					max-height: 18px;
				}

				i
				{
					@include font(rem-calc(22), $weight-regular, 1, $color-01);
				}
				
				&:last-child
				{
					margin: 0;
				}
			}

			.atn-marker
			{
				display: none;
			}
		}

		.section-lang
		{
			margin-bottom: 5px;

			p
			{
				@include font-p($font-sm, $weight-regular, 1.2, $brand-gray-02);
			}
		}

		.section-tag
		{
			@include inline-flex;

			.label
			{
				display: block;
				margin: 0;
				margin-right: 5px;
				margin-bottom: 3px;

				&:last-child
				{
					margin-right: 0;
				}
			}
		}

		.controls-more
		{
			top: 0;
			right: 0;

			@include opacity(0);
			@include transition;
		}

		&:hover, &:active, &:focus
		{
			.controls-more
			{
				@include opacity(1);
			}
		}
	}

	.gallery-wrapper
	{
		.gallery
		{
			.item-slick
			{
				width: 100%;

				@include position;
			}
		}

		.gallery-carousel
		{
			.slick-list
			{
				margin: 0 -5px;

				.slick-slide
				{
					padding: 0 5px;
				}
			}

			.item-slick
			{
				margin: 0;

				@include position;

				.video-player
				{
					height: 0;
					padding-top: 25px;
					padding-bottom: 56.25%;

					@include position;
					
					iframe
					{
						width: 100%;
						height: 100%;

						@include position(absolute, 99, 0, 0);
					}
				}

				.btn-lightbox
				{
					padding: 5px 8px;

					@include faded-background($black-color, 0.3);
					@include position(absolute, 11, $bottom: 0, $right: 0);
					@include font($font-lg, $weight-regular, 1, $white-color);

					i
					{
						@include font(inherit, inherit, inherit, inherit);
					}

					&:hover, &:active, &:focus
					{
						background: $black-color;
					}
				}

				&:before
				{
					content: 'Loading...';
					font-style: italic;

					@include absolute-center(xy, -1);
					@include font-p($font-sm, $weight-regular, 1, $brand-gray-04);
				}
			}

			.slick-list
			{
				margin-bottom: 7px;
			}

			.slick-dots
			{
				@include reset-list;
				@include inline-flex;

				li
				{
					margin-right: 5px;

					span
					{
						display: block;
						width: 6px;
						height: 6px;
						cursor: pointer;
						background: $brand-gray-03;

						@include transition;
						@include border-radius(99px);
					}

					&:last-child
					{
						margin: 0;
					}

					&.slick-active, &:hover, &:active, &:focus
					{
						> span
						{
							background: $brand-primary;
						}
					}
				}
			}
		}
	}

	.map-container
	{
		margin-bottom: 30px;

		.map-canvas
		{
			height: 400px;
			background: $brand-gray-01;
		}
	}

	.yt-video-gallery
	{
		.item-video
		{
			height: 300px;

			.owl-video-frame
			{
				height: 100%;

				iframe
				{
					height: 100%;
				}
			}

			.owl-video-play-icon
			{
				@include opacity(0);
				@include transition;

				&:before
				{
					content: "\f16a";

					@include absolute-center;
					@include text-shadow(0 0 15px rgba($black-color, 0.3));
					@include font-fa(rem-calc(70), $weight-regular, 1, $white-color);
				}
			}

			&:hover, &:active, &:focus
			{
				.owl-video-play-icon
				{
					@include opacity(1);
				}
			}
		}
	}

	&.related-result-detail
	{
		.sidebar-content
		{
			.section-header
			{
				.section-atn-play
				{
					.atn-marker
					{
						display: block;
						padding: 10px;
						margin: 0;

						@include position(absolute, 11, -70px, 0);
					}
				}

				&:hover, &:active, &:focus
				{
					.section-atn-play
					{
						.atn-marker
						{
							@include opacity(1);
							@include pointer-events(auto);
						}
					}
				}
			}

			.actions-container
			{
				padding-right: 30px;
				justify-content: space-between;

				.atn-back
				{
					max-width: 180px;

					&:hover, &:active, &:focus
					{
						.atn-inner
						{
							width: 100% !important;
						}
					}
				}
			}
		}
	}
}

.tse-scroll-content
{
	@media (max-width: $screen-xxs-max)
	{
		width: 100% !important;
	}
}