/* ----------  MD Autocomplete  ---------- */

.md-autocomplete
{
	.ui-autocomplete
	{
		border: 0;
		margin: 0;
		padding: 0;
		background: $white-color;

		@include md-default-shadow;
		@include border-radius(3px);

		.ui-menu-item
		{
			border-bottom: 1px solid $brand-gray-01;

			.ui-menu-item-wrapper
			{
				cursor: pointer;
				padding: 7px 12px;

				@include font-p($font-sm, $weight-regular, 1.2, $color-01);

				&.ui-state-hover, &.ui-state-active, &:hover, &:active, &:focus
				{
					border: 0;
					margin: 0;
					background: $brand-gray-01;
				}
			}

			&:last-child
			{
				border: 0;
			}
		}
	}
}