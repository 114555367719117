/*----------  Filters Dropdown  ----------*/

.filter-dropdown
{
	@include position;

	.filter-title
	{
		display: block;
		min-height: 43px;
		padding: 10px 5px;
		padding-right: 20px;
		border-bottom: 1px solid $white-color;

		@include position;
		@include text-truncate;
		@include flex(column, normal, center);
		@include font-p($font-sm, $weight-regular, 1.2, $white-color);

		.title-arrow
		{
			@include transform(translateY(-50%));
			@include position(absolute, 2, $top: 50%, $right: 0);
		}

		.preloader
		{
			left: auto;
			right: 15px;
		}
	}

	.filter-content
	{
		display: none;
		width: 100%;
		padding: 7px 0;
		overflow: hidden;
		background: $white-color;

		@include md-default-shadow;
		@include border-radius(3px);
		@include position(absolute, 11, calc(100% + 10px), 0);

		.content-inner
		{
			max-height: 300px;
		}

		.filter-item
		{
			@include transition(all 0.15s ease);

			label
			{
				display: block;
				margin: 0;
				cursor: pointer;
				padding: 8px 10px;

				@include inline-flex;
				@include font-p($font-sm, $weight-regular, 1.2, $color-01);
			}

			span
			{
				display: block;
			}

			.item-icon
			{
				i
				{
					font-size: rem-calc(22);
				}
			}

			.item-toggle, .item-icon
			{
				margin-right: 7px;
			}

			&.filter-item-licensed
			{
				label
				{
					color: $brand-warning;
				}

				.item-toggle
				{
					.icheck-input
					{
						border-color: $brand-warning;

						&.checked
						{
							border-color: $brand-warning !important;

							@include bg-color($brand-warning);
						}
					}
				}
			}

			&:hover, &:active, &:focus
			{
				background: $brand-gray-01;
			}
		}

		.item-divider
		{
			height: 1px;
			margin: 5px 15px;
			background: $brand-gray-14;
		}
	}

	&.filter-dropdown-theme-dark
	{
		.filter-title
		{
			color: $color-01;
			border-bottom-color: rgba($black-color, 0.12);
		}
	}

	&.disabled
	{
		@include pointer-events(none);

		.filter-title
		{
			border-bottom-style: dotted;
		}
	}
}