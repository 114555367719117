/* ----------  Default MD Shadow  ---------- */

@mixin md-default-shadow()
{
	@include box-shadow(0 1px 3px rgba($black-color, 0.12), 0 1px 2px rgba($black-color, 0.24));
}

/* ----------  Flex  ---------- */

@mixin flex($dir: column, $ai: normal, $jc: normal)
{
	display: flex;
	flex-wrap: wrap;
	align-items: $ai;
	flex-direction: $dir;
	justify-content: $jc;

	@content;
}

/* ----------  Inline Flex  ---------- */

@mixin inline-flex($ai: center, $jc: normal)
{
	@include flex(row, $ai, $jc);
	@content;
}

/* ----------  Key Frames  ---------- */

@mixin keyframe($name) {
	@-webkit-keyframes #{$name}
	{
		@content;
	}
	
	@-moz-keyframes #{$name}
	{
		@content;
	}
	
	@-ms-keyframes #{$name}
	{
		@content;
	}
	
	@-o-keyframes #{$name}
	{
		@content;
	}
	
	@keyframes #{$name}
	{
		@content;
	}
}

/* ----------  User Select  ---------- */

@mixin user-select($value)
{
	-webkit-user-select: $value;
	-moz-user-select: $value;
	-ms-user-select: $value;
	-o-user-select: $value;
	user-select: $value;
}

/* ----------  Font Inherit  ---------- */

@mixin font-inherit()
{
	@include font(inherit, inherit, inherit, inherit);
}