/* ----------  Payment Method  ---------- */

.payment-method-card
{
	padding: 5px;
}

.payment-method-card:not(.add-card)
{
	.card-inner
	{
		height: 170px;
		
		@include md-default-shadow;
	}

	.card-content
	{
		padding: 7px 12px;
		min-height: 120px;
		border-bottom: 1px solid $brand-gray-04;

		@include inline-flex;

		@media (max-width: $screen-xl-max)
		{
			flex-direction: column;
		}

		@media (max-width: $screen-xs-max)
		{
			min-height: 80px;
		}
	}

	.card-icon
	{
		margin-right: 15px;

		img
		{
			max-height: 80px;

			@media (max-width: $screen-xs-max)
			{
				max-height: 50px;
			}
		}
	}

	.card-number
	{
		h4
		{
			line-height: 1.2;
			color: $color-01;
			font-weight: $weight-bold;

			@media (max-width: $screen-xs-max)
			{
				font-size: $font-md;
			}
		}
	}

	.card-actions
	{
		height: 49px;
		padding: 0 12px;

		@include inline-flex(center, flex-end);
	}
}

.payment-method-card.add-card
{
	cursor: pointer;

	.card-inner
	{
		border: 1px dashed $brand-gray-04;
		
		@include transition;
		@include inline-flex(center, center)
		{
			flex-direction: column;
		}
	}

	span, i
	{
		display: block;
		height: auto;
		width: auto;
	}

	.card-content
	{
		width: 100%;
		height: 170px;
		padding: 7px 12px;
		text-align: center;
		text-transform: uppercase;
		
		@include inline-flex(center, center);
		@include font-p($font-md, $weight-regular, 1.2, $brand-primary);

		i, span
		{
			@include font(inherit, inherit, inherit, inherit);
		}

		.card-icon
		{
			margin-right: 7px;
			font-size: rem-calc(28);

			@media (max-width: $screen-xs-max)
			{
				margin: 0;
				font-size: $font-xl;
			}
		}

		.card-text
		{
			font-weight: $weight-bold;
		}

		@media (max-width: $screen-xs-max)
		{
			flex-direction: column;
		}
	}

	&:hover, &:active, &:focus
	{
		.card-inner
		{
			border-color: $brand-primary;
		}
	}
}
