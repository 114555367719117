/* ----------  MD Repeater  ---------- */

.md-repeater
{
	margin: 0;
	padding: 0;
	list-style-type: none;

	[data-repeater-template] {
		display: none;
	}

	.md-repeater-item
	{
		margin-bottom: 10px;

		.md-repeater-item-inner
		{
			@include inline-flex
			{
				flex-wrap: nowrap;
			}
		}

		.md-repeater-item-content
		{
			flex: 1;

			.uk-input-group
			{
				.uk-input-group-addon
				{
					padding-left: 0;
				}
			}
		}

		.md-repeater-item-actions
		{
			padding-left: 7px;

			@include inline-flex
			{
				flex-wrap: nowrap;
			}

			.atn
			{
				display: block;
				padding: 5px;

				@include link;
				@include font(rem-calc(28), $weight-regular, 1.2, $brand-gray-02);

				i
				{
					@include font(inherit, inherit, inherit, inherit);
				}

				&.atn-add
				{
					color: lighten($brand-primary, 15%);

					&:hover, &:active, &:focus
					{
						color: $brand-primary;
					}
				}

				&.atn-remove
				{
					color: lighten($brand-danger, 15%);

					&:hover, &:active, &:focus
					{
						color: $brand-danger;
					}
				}

				&:hover, &:active, &:focus
				{
					color: $color-01;
				}
			}
		}

		&:last-child
		{
			margin: 0;
		}

		&:only-child
		{
			.md-repeater-item-actions
			{
				.atn-remove
				{
					display: none;
				}
			}
		}
	}
}