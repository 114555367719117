/* ----------  Messages View  ---------- */

#top_bar
{
	.md-top-bar
	{
		.md-top-bar-search
		{
			float: right;

			@include max-width(300px);

			.uk-input-group
			{
				.md-input-wrapper
				{
					padding: 0;

					.md-input
					{
						padding-top: 10px;
					}
				}
			}

			@media (max-width: $screen-xs-max)
			{
				display: none;
			}
		}
	}
}

.md-card-list-wrapper
{
	.md-card-list
	{
		> ul
		{
			> li
			{
				@include link;

				.md-card-list-subscription-status
				{
					float: right;
					padding-top: 10px;
					margin-right: 10px;

					i
					{
						color: inherit;
					}
				}

				&:hover, &:active, &:focus
				{
					background: $brand-gray-01;
				}
			}
		}
	}
}

/* ----------  Messages Container  ---------- */

body.page-messages
{
	#page_content_inner
	{
		padding-bottom: 24px;
	}
}

.tpl-fuse
{
	.messages-container
	{
		.ps
		{
			@include position;

			> .ps__scrollbar-y-rail
			{
				z-index: 99;
			}
		}

		.material-icons
		{
			width: auto;
			height: auto;
			vertical-align: 0;

			@include font-inherit;
		}

		.btn
		{
			@include font-p($font-sm, $weight-regular, 1.2, $black-color);
		}

		.btn-icon
		{
			width: 40px;
			padding: 8px;
			height: 40px;
		}

		.avatar
		{
			width: 40px;
			height: 40px;
			line-height: 1;
			flex: 0 0 40px;
			min-width: 10px;
			min-height: 10px;
		}

		.status
		{
			display: block;
			top: 0;
			left: 0;
			width: 16px;
			height: 16px;
			background: $white-color;

			@include position;
			@include transition;
			@include border-radius(99px);
			@include font($font-xs, $weight-regular, 1, $white-color);

			.status-icon
			{
				display: block;

				@include absolute-center;
			}

			&.status-online
			{
				background: $brand-success;

				.status-icon
				{
					&:not(:only-child)
					{
						display: none;
					}

					&.status-icon-online
					{
						display: block;
					}
				}
			}

			&.status-offline
			{
				color: $brand-primary;
				background: $white-color;

				.status-icon
				{
					&:not(:only-child)
					{
						display: none;
					}

					&.status-icon-offline
					{
						display: block;
					}
				}
			}

			&.status-dnd
			{
				background: $brand-danger;
			}

			&.status-away
			{
				background: $brand-warning;
			}

			&.status-blocked
			{
				color: $brand-danger;
			}

			&.status-none
			{
				background: $brand-gray-12;
			}
		}

		textarea.form-control
		{
			border-bottom: 2px solid lighten($brand-gray-04, 10%);

			@include transition;
			@include border-radius(0);
			@include box-shadow(none);
			@include placeholder($opacity: 0.3);

			&.disabled, &[disabled]
			{
				border-bottom-style: dotted;

				@include pointer-events(none);
			}

			&:hover
			{
				border-bottom-color: $brand-gray-04;

				@include placeholder($opacity: 0.6);
			}

			&:focus
			{
				border-bottom-color: $brand-gray-12;

				@include placeholder($opacity: 1);
			}

			&:hover, &:active, &:focus
			{
				@include box-shadow(none !important);
			}
		}

		.form-group
		{
			&.md-focus
			{
				textarea.form-control
				{
					border-bottom-color: $brand-gray-12;

					@include box-shadow(none);
					@include placeholder($opacity: 1);
				}
			}
		}

		.list-loader
		{
			width: 100%;
			padding: 10px;
			background: $brand-gray-01;

			@include opacity(0);
			@include transition;
			@include pointer-events(none);
			@include inline-flex(center, center);
			@include position(absolute, 99, $bottom: 0, $left: 0);
			@include font-p($font-sm, $weight-regular, 1, $brand-gray-02);

			.loader-icon
			{
				margin-right: 5px;
			}

			&.loading
			{
				@include opacity(1);
			}
		}

		#chat
		{
			@include reset-position;

			.page-content
			{
				padding: 0;
				
				@include max-width(100%);

				> .page-content-card
				{
					background: $white-color url("../images/themes/fuse/patterns/rain-grey.png");

					.btn-contacts
					{
						z-index: 11;
						font-size: rem-calc(24);
					}

					.avatar-wrapper
					{
						.status
						{
							top: 28px;
							left: 28px;

							&.status-blocked
							{
								top: 0;
								left: 0;
							}
						}
					}

					> .left-sidebar
					{
						@include max-width(400px, false);

						#chat-left-sidebar-views
						{
							#chats-view, #contacts-view
							{
								.toolbar
								{
									.toolbar-top
									{
										height: 64px;
										padding: 0 16px;

										.btn
										{
											font-size: $font-lg;
										}

										.avatar-wrapper
										{
											flex: 1;
											cursor: default;

											.status
											{
												top: 28px;
												left: 28px;
											}
										}

										.btn-context
										{
											margin-right: -10px;
											
											@include opacity(0);
											@include transition;

											@media (max-width: $screen-sm-max)
											{
												@include opacity(1);
											}
										}

										&:hover, &:active, &:focus
										{
											.btn-context
											{
												@include opacity(1);
											}
										}
									}

									.toolbar-bottom
									{
										height: 64px;
										padding: 0 16px;
										line-height: 1.2;

										.search-wrapper
										{
											height: 36px;

											.search-icon
											{
												@include font($font-base, $weight-regular, 1, rgba($black-color, 0.54));
											}

											.search-input
											{
												@include box-shadow(none);
												@include font-p($font-sm, $weight-regular, 1.3, $black-color);

												&:hover, &:active, &:focus
												{
													@include box-shadow(none);
												}
											}
										}
									}
								}

								> .content
								{
									.contact
									{
										height: auto;
										min-height: 88px;
										align-items: center;
										text-decoration: none;
										padding-right: 0 !important;
										border-bottom: 1px solid rgba($black-color, 0.12);

										@include position;

										*
										{
											@include pointer-events(none);
										}

										.divider
										{
											display: none !important;
										}

										.status
										{
											&.status-blocked
											{
												// @include opacity(0);
											}
										}

										.contact-context
										{
											cursor: pointer;

											@include opacity(0);
											@include transition;
											@include pointer-events(auto);
											@include position(absolute, 22, $top: 5px, $right: 10px);

											span
											{
												display: block;

												@include pointer-events(auto);
											}

											@media (max-width: $screen-sm-max)
											{
												@include opacity(1);
											}
										}

										.last-message, .h6
										{
											font-size: $font-sm;
										}

										.last-message
										{
											max-width: 280px;
											font-size: $font-xs;

											@media (max-width: $screen-xxs-max)
											{
												max-width: 150px;
											}
										}

										.contact-message-time, .mood
										{
											font-size: $font-xs;

											span
											{
												margin-right: 10px;
												color: lighten($black-color, 40%);
											}
										}

										.unread-message-count
										{
											width: 20px;
											height: 20px;
											line-height: 1.8;
											font-size: $font-xs;
										}

										.contact-unread-badge
										{
											@include position(absolute, 11, $bottom: 20px, $right: 15px);

											@media (max-width: $screen-xxs-max)
											{
												top: 35px;
												bottom: auto;
											}
										}

										.contact-message-time
										{
											font-size: $font-xs;

											@include position(absolute, 11, $top: 20px, $right: 15px);

											@media (max-width: $screen-xxs-max)
											{
												display: block;
												width: 100%;
												flex: 0 0 100%;
												margin-top: 3px;
												padding-left: 57px;

												@include reset-position;
											}
										}

										&.blocked
										{
											.status
											{
												&.status-blocked
												{
													// @include opacity(1);
												}
											}
										}

										&.contact-blocked
										{
											cursor: not-allowed;
										}

										&:hover, &:active, &:focus
										{
											.contact-context
											{
												@include opacity(1);
											}
										}
									}

									.content-section
									{
										margin-top: 20px;

										.section-title
										{
											margin: 0;
											padding: 16px;
											min-height: 70px;

											@include flex(row, center, flex-start);

											h4
											{
												margin: 0;

												@include font-p($font-lg, $weight-regular, 1.2, $brand-primary);
											}
										}

										.section-content
										{
											min-height: 50px;
											
											@include position;

											.contact
											{
												padding-left: 14px !important;
											}

											&.contact-list
											{
												.contact
												{
													padding-right: 14px !important;
												}
											}
										}
									}
								}
							}

							#user-view
							{
								.toolbar
								{
									height: 300px;

									.toolbar-top
									{
										height: 64px;

										.btn
										{
											font-size: $font-lg;
										}
									}

									.toolbar-bottom
									{
										.avatar
										{
											width: 96px;
											height: 96px;
										}

										.h4
										{
											font-size: rem-calc(24);
										}
									}
								}

								.content
								{
									.card
									{
										form
										{
											.form-group
											{
												// margin-bottom: 0;
											}
										}
									}
								}
							}
						}

						@media (max-width: $screen-sm-max)
						{
							@include max-width(100%);
						}
					}

					> .content
					{
						#chat-content-views
						{
							#start-view
							{
								.big-circle
								{
									.big-circle-icon
									{
										@include font(rem-calc(120), $weight-regular, 1, $brand-primary);
									}
								}
							}

							#chat-view
							{
								.toolbar
								{
									height: 64px;
									max-height: 64px;

									.btn-sidebar-toggle
									{
										font-size: $font-lg;
										color: $brand-primary;
									}

									.btn-close
									{
										display: none;
										font-size: $font-lg;
										margin-right: -10px;

										@media (max-width: $screen-sm-max)
										{
											display: block;
										}
									}
								}

								.chat-content
								{
									.chat-messages
									{
										min-height: 100%;

										@include flex(column, normal, flex-end) {
											-webkit-flex-wrap: no-wrap;
											-moz-flex-wrap: no-wrap;
											-ms-flex-wrap: no-wrap;
											-o-flex-wrap: no-wrap;
											flex-wrap: no-wrap;
										}
										
										$chatThemes: (
											('gray-green', (#000000, #f5f5f5), (#000000, #e8f5e9)),
											('blue-darkblue', (#000000, #bbdefb), (#ffffff, #1e88e5)),
											('lightgray-orange', (#000000, #eeeeee), (#ffffff, #fb8c00)),
											('deepgray-lightpurple', (#ffffff, #616161), (#ffffff, #5e35b1)),
										);

										.message-row
										{
											.bubble
											{
												padding-top: 6px;
												padding-left: 9px;
												padding-right: 7px;
												padding-bottom: 8px;

												@include box-shadow(none);
												@include filter(drop-shadow(0 1px 0.5px rgba($black-color, 0.13)));

												.message
												{
													display: block;
													white-space: normal;
													font-size: $font-sm;
												}

												.time
												{
													margin-top: 8px;
													font-size: rem-calc(11);
												}

												&:before
												{
													display: none;
												}

												&:after
												{
													content: '';

													width: 0;
													height: 0;
													border-style: solid;
													border-width: 10px 17.3px 10px 0;
													border-color: transparent #ffffff transparent transparent;

													@include position(absolute, 2, $bottom: 7px);
												}
											}

											&.contact
											{
												.bubble
												{
													&:after
													{
														left: -15px;
													}
												}
											}

											&.user
											{
												.bubble
												{
													&:after
													{
														right: -15px;
														border-color: transparent #e8f5e9 transparent transparent;

														@include transform(scaleX(-1));
													}
												}
											}
										}

										@each $theme, $sender, $self in $chatThemes {
											&[data-theme="#{$theme}"] {
												$senderColor: nth($sender, 1);
												$senderBackground: nth($sender, 2);

												$selfColor: nth($self, 1);
												$selfBackground: nth($self, 2);

												.message-row.contact
												{
													.bubble
													{
														@include bg-color($senderBackground);

														.message, .time
														{
															@include text-color($senderColor);
														}

														&:after
														{
															border-color: transparent $senderBackground transparent transparent;
														}
													}
												}

												.message-row.user
												{
													.bubble
													{
														@include bg-color($selfBackground);

														.message, .time
														{
															@include text-color($selfColor);
														}

														&:after
														{
															border-color: transparent $selfBackground transparent transparent;
														}
													}
												}
											}
										}
									}
								}

								.chat-footer
								{
									min-height: 10px;
									max-height: 120px;

									.reply-form
									{
										.form-group
										{
											padding-top: 0;
											margin-bottom: 0;
										}

										.btn
										{
											font-size: rem-calc(24);
										}
									}
								}
							}
						}

						@media (max-width: $screen-sm-max)
						{
							width: 100%;
							height: 100%;
							background: $white-color url("../images/themes/fuse/patterns/rain-grey.png");

							@include opacity(0);
							@include transition;
							@include pointer-events(none);
							@include transform(translateX(30%));
							@include position(absolute, 111, 0, 0);
						}
					}
				}
			}
		}

		&.open-chat
		{
			#chat
			{
				.page-content
				{
					> .page-content-card
					{
						> .content
						{
							@include opacity(1);
							@include pointer-events(auto);
							@include transform(translatex(0));
						}
					}
				}
			}
		}
	}
}