/* ----------  Language List  ---------- */

.md-language-list
{
	@include reset-list;
	@include inline-flex;

	li
	{
		display: flex;
		padding: 5px 12px;
		margin-right: 7px;
		margin-bottom: 5px;
		flex-direction: row;
		align-items: center;
		background: $brand-gray-01;

		@include border-radius(4px);

		.md-list-icon
		{
			margin-right: 7px;

			i, span, .item-icon
			{
				display: block;
			}
		}

		.md-list-content
		{
			flex: 1;
		}

		.md-list-heading
		{
			font-size: $font-sm;
		}

		&:last-child
		{
			margin-right: 0;
		}
	}
}

/* ----------  Details List  ---------- */

.md-details-list
{
	> li
	{
		min-height: 10px;

		.md-list-content
		{
			@include inline-flex;

			.md-content-title
			{
				min-width: 120px;
				margin-right: 10px;
			}
		}
	}
}

/*----------  UK List  ----------*/

.uk-list
{
	li
	{
		margin-bottom: 7px;

		@include position;
		@include font-p($font-sm, $weight-regular, 1.4, $color-01);

		&:last-child
		{
			margin: 0;
		}
	}

	&.uk-list-bullet
	{
		li
		{
			padding-left: 15px;

			&:before
			{
				content: "";
				display: block;
				width: 6px;
				height: 6px;
				background: $color-01;

				@include border-radius(99px);
				@include position(absolute, 2, 6px, 0);
			}
		}

		&.uk-list-light
		{
			li
			{
				color: $white-color;

				&:before
				{
					background: $white-color;
				}
			}
		}
	}
}