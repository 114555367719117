/* ----------  Enjoy Hint  ---------- */

.body-admin
{
	.enjoyhint
	{
		&.enjoyhint-step-2
		{
			.enjoyhint_close_btn
			{
				right: 80px !important;
			}
		}
	}
}